import React, { Fragment, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListSubheader,
  IconButton,
  Tooltip,
  Hidden
} from "@material-ui/core";
import { StyledListLink } from "@austere-monorepo/components";

function SimpleDialog(props) {
  const { itemsList, onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle disableTypography style={{ textAlign: "center" }}>
        Add Item
      </DialogTitle>
      <List disablePadding dense>
        {itemsList}
      </List>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

function QuickAdd({ quickAddItems, role, isAuth }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const itemsList =
    quickAddItems &&
    quickAddItems.map((item, i) => {
      const itemRole = item.role ? item.role : 1;
      return (
        <Fragment key={i}>
          {role >= itemRole && (
            <StyledListLink icon={<AddIcon />} primary={item.title} to={item.path} />
          )}
        </Fragment>
      );
    });

  return (
    <Fragment>
      {isAuth && (
        <Fragment>
          <Hidden mdUp implementation="css">
            <ListSubheader>+ Quick Add</ListSubheader>
            {itemsList}
          </Hidden>

          <Hidden smDown implementation="css">
            <Tooltip title={"Add items"}>
              <IconButton color="inherit" onClick={handleClickOpen}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <SimpleDialog open={open} onClose={handleClose} role={role} itemsList={itemsList} />
          </Hidden>
        </Fragment>
      )}
    </Fragment>
  );
}

export default QuickAdd;
