import {
  FormWrapper,
  RenderFormSection,
  RenderRadioField,
  RenderTextField
} from "@austere-monorepo/components";
import { Field } from "redux-form";

function UpdateForm(props) {
  return (
    <FormWrapper title="Trails Update" {...props}>
      <RenderFormSection heading="Bay of Fires Trail">
        <Field
          required
          name="poimena_status"
          label="Status"
          component={RenderRadioField}
          items={[
            { label: "Open", val: "Open" },
            { label: "Partially Open", val: "Partially Open" },
            { label: "Closed", val: "Closed" }
          ]}
          md={4}
        />
        <Field
          name="poimena_message"
          label="Message"
          placeholder="Optional"
          type="text"
          multiline
          component={RenderTextField}
          md={8}
        />
      </RenderFormSection>
      <RenderFormSection heading="St Helens Network">
        <Field
          required
          name="stacked_loop_status"
          label="Status"
          component={RenderRadioField}
          items={[
            { label: "Open", val: "Open" },
            { label: "Partially Open", val: "Partially Open" },
            { label: "Closed", val: "Closed" }
          ]}
          md={4}
        />
        <Field
          name="stacked_loop_message"
          label="Message"
          placeholder="Optional"
          type="text"
          multiline
          component={RenderTextField}
          md={8}
        />
      </RenderFormSection>
      <RenderFormSection heading="Dreaming Pools">
        <Field
          required
          name="dreaming_pools_status"
          label="Status"
          component={RenderRadioField}
          items={[
            { label: "Open", val: "Open" },
            { label: "Partially Open", val: "Partially Open" },
            { label: "Closed", val: "Closed" }
          ]}
          md={4}
        />
        <Field
          name="dreaming_pools_message"
          label="Message"
          placeholder="Optional"
          type="text"
          multiline
          component={RenderTextField}
          md={8}
        />
      </RenderFormSection>
    </FormWrapper>
  );
}

export default UpdateForm;
