import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { Box, Card, CardHeader, CardActions, Button, IconButton } from "@material-ui/core";

// Icon
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PublicIcon from "@material-ui/icons/Public";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

const useStyles = makeStyles((theme) => ({
  iconBox: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: theme.spacing(1, 0)
  },
  action: {
    padding: 0,
    justifyContent: "flex-end"
  }
}));

function Connect() {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader title={"Connect with St Helens Trails"} avatar={<GroupAddIcon />} />

      <Box className={classes.iconBox}>
        <IconButton component="a" target="_blank" href="https://www.sthelensmtbtrails.com.au/">
          <PublicIcon color="primary" />
        </IconButton>
        <IconButton component="a" target="_blank" href="https://www.facebook.com/StHelensMTB">
          <FacebookIcon color="primary" />
        </IconButton>

        <IconButton component="a" target="_blank" href="https://www.instagram.com/sthelensmtb/">
          <InstagramIcon color="primary" />
        </IconButton>

        <IconButton
          component="a"
          target="_blank"
          href="https://www.youtube.com/channel/UCrHaRFwPwLjzgmJgKgrazFQ"
        >
          <YouTubeIcon color="primary" />
        </IconButton>

        <IconButton component="a" target="_blank" href="mailto:info@sthelensmtbtrails.com.au">
          <MailOutlineIcon color="primary" />
        </IconButton>
      </Box>
      <CardActions className={classes.action}>
        <Button
          size="small"
          component={Link}
          to={"/information/request-account"}
          endIcon={<RightIcon />}
        >
          Request an Account
        </Button>
      </CardActions>
    </Card>
  );
}

export default Connect;
