import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, Select, MenuItem } from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.background.paper
  },
  menuItem: {
    textTransform: "capitalize"
  }
}));

export function RenderSelectField({
  input,
  label,
  name,
  type,
  helper,
  md,
  xs,
  multiline,
  items,
  required,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  return (
    <FieldWrapper label={label} required={required} name={name} helper={helper} md={md} xs={xs}>
      <Select
        className={classes.field}
        required={required}
        variant="outlined"
        {...input}
        {...rest}
        name={name}
        inputProps={{
          name: `${name}`,
          id: `${name}`
        }}
      >
        {items &&
          items.map((item) => (
            <MenuItem className={classes.menuItem} key={item.val} value={item.val}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
