/** @format */

import React, {PureComponent} from 'react';
import {firestoreConnect} from 'react-redux-firebase';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
// import moment from 'moment';

// Styles
import Typography from '@material-ui/core/Typography';
import HazardIcon from '@material-ui/icons/Done'

const iconStyle = {
  marginBottom: '-7px',
  marginRight: '10px',
  fill: 'rgba(0, 0, 0, 0.54)'
};

class HazardsList extends PureComponent {
  render() {
    const {_hazards, _user} = this.props;
    const hazardsMy =
    _hazards &&
    _hazards.filter(hazard => hazard.hazard_assigned === `${_user.email}` && hazard.hazard_status === 'Unresolved');
    return (
      <div className="noteList_container">
        <ul className="formList_list">
          {hazardsMy &&
            hazardsMy.map(hazard => (
              <Link
                to={`hazards/edit/${hazard.id}`}
                key={hazard.id}
                className="noteList_note"
              >
                <div>
                  <Typography variant="body1"><HazardIcon style={iconStyle}/>{hazard.hazard_title}, {hazard.hazard_activity} (Rating: {hazard.hazard_rating})</Typography>
                </div>
              </Link>
            ))}
          {hazardsMy && hazardsMy.length === 0 && (
           <Typography variant="body2" style={{ color: '#3d3d3d' }} align="center">No outstanding actions assigned to you</Typography>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({firebase: {auth}, firestore: {ordered}}) => ({
  _hazards: ordered.hazards,
  _user: auth
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{collection: 'hazards'}])
)(HazardsList);
