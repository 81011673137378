import { createTheme } from "@material-ui/core/styles";

export const createMasterTheme = (config = {}) => {
  const globalTheme = createTheme({
    palette: {
      type: config.type,
      primary: {
        main: config.primaryColor
      },
      secondary: {
        // main: "#1976d2"
        main: "#1976d2"
      }
    },
    typography: {
      h1: {
        fontWeight: 500,
        fontSize: 24,
        letterSpacing: "-0.24px"
      },
      h2: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: "-0.24px"
      },
      h3: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: "-0.06px"
      },
      h4: {
        fontWeight: 500,
        fontSize: 17,
        letterSpacing: "-0.06px"
      },
      h5: {
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: "-0.05px"
      },
      h6: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: "-0.05px"
      },
      body1: {
        fontSize: 15
      }
    },
    props: {
      MuiButton: {
        label: {
          textDecoration: "none"
        }
      },
      MuiButtonBase: {
        disableRipple: true
      }
    }
  });

  return {
    ...globalTheme,
    shadows: globalTheme.shadows.map(() => "none"),
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            boxSizing: "border-box",
            margin: 0,
            padding: 0,
            scrollPadding: "130px 0 0 0",
            scrollBehavior: "smooth"
          },
          html: {
            // "-webkit-font-smoothing": "antialiased",
            // "-moz-osx-font-smoothing": "grayscale",
            height: "100%",
            width: "100%"
          },
          body: {
            height: "100%",
            width: "100%",
            overflow: "hidden",
            /* These are technically the same, but use both */
            overflowWrap: "break-word",
            wordWrap: "break-word"
          },
          "#root": {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: "auto"
          },
          "a, button": {
            textDecoration: "none"
          },
          a: {
            color: globalTheme.palette.secondary.main
          },
          "strong, b": {
            fontWeight: 500
          }
        }
      },
      MuiTypography: {
        paragraph: {
          marginBottom: 24 // was 18
        }
      },
      MuiCard: {
        root: {
          padding: globalTheme.spacing(3.5, 4),
          border: "1px solid",
          borderColor: globalTheme.palette.divider,
          flexGrow: 1,
          borderRadius: 0,
          [globalTheme.breakpoints.down("sm")]: {
            padding: globalTheme.spacing(2)
          }
        }
      },
      MuiCardHeader: {
        root: {
          padding: 0,
          paddingBottom: globalTheme.spacing(2)
        },
        title: {
          fontWeight: 500,
          color: globalTheme.palette.text.secondary
        },
        avatar: {
          marginTop: "4px",
          marginRight: "6px",
          marginLeft: "-4px",
          color: globalTheme.palette.text.secondary
        },
        subheader: {
          fontSize: "13px"
        }
      },
      MuiList: {
        subheader: {
          fontWeight: 500
        }
      },
      MuiListItemAvatar: {
        root: {
          color: globalTheme.palette.text.secondary
        }
      },
      MuiOutlinedInput: {
        input: {
          padding: globalTheme.spacing(1.75)
        },
        multiline: {
          padding: globalTheme.spacing(1.75)
        }
      },
      MuiInputBase: {
        root: {
          fontSize: globalTheme.typography.body1.fontSize
        },
        input: {
          padding: globalTheme.spacing(1.75)
        }
      },
      // MuiFormHelperText: {
      //   root: {
      //     color: "red"
      //   }
      // },
      MuiIconButton: {
        root: {
          padding: globalTheme.spacing(1)
        }
      },
      MuiTableRow: {
        root: {
          verticalAlign: "initial"
        }
      },
      MuiTabs: {
        root: {
          // fontSize: globalTheme.typography.body2.fontSize,
          minHeight: "40px"
        }
        // indicator: {
        //   height: "0px"
        // }
      },
      MuiTab: {
        root: {
          fontSize: "0.8rem",
          minHeight: "40px"
        }
      },
      MuiSwitch: {
        thumb: {
          border: `1px solid ${globalTheme.palette.divider}`
        }
      },
      MuiDialogTitle: {
        root: {
          fontWeight: 500,
          fontSize: globalTheme.typography.h4.fontSize,
          textAlign: "center"
        }
      },
      MuiDialogActions: {
        root: {
          padding: globalTheme.spacing(2, 2.5)
        }
      },
      MuiMenu: {
        paper: {
          border: `1px solid ${globalTheme.palette.divider}`
        }
      }
      // uncomment for testing grid layout
      // MuiGrid: {
      //   item: {
      //     border: "1px solid red"
      //   }
      // }
    }
  };
};
