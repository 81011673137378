import React, { Fragment, useEffect, useState } from "react";
import { Field } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Card, CardHeader, CardActions, Box, Grid, TextField, Typography } from "@material-ui/core";

import moment from "moment";
import Button from "@material-ui/core/Button";
import WeatherIcon from "@material-ui/icons/WbCloudy";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";

import {
  StyledProgress,
  StyledWrapper,
  StyledBasicTable,
  RenderTextField
} from "@austere-monorepo/components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    background: theme.palette.background.default
  },
  card: {
    padding: theme.spacing(1, 3),
    borderRadius: "0px",
    border: "1px solid",
    borderColor: theme.palette.divider,
    boxShadow: "none",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  item: {
    padding: theme.spacing(2, 0)
  },
  action: {
    padding: 0
  }
}));

function Weather() {
  const [location, setLocation] = useState(false);
  const [weather, setWeather] = useState(false);
  const [forecast, setForecast] = useState(false);
  const [city, setCity] = useState(null);

  const baseUrl = "https://api.openweathermap.org/data/2.5/";
  const classes = useStyles();

  const getWeather = async (lat, lon, city) => {
    const api_call_current = await fetch(
      // `${baseUrl}weather?lat=${lat}&lon=${lon}&appid=${process.env.REACT_APP_WEATHER_KEY}&units=metric`

      `${baseUrl}weather?${city ? "q=" + city : "lat=" + lat + "&lon=" + lon}&appid=${
        process.env.REACT_APP_WEATHER_KEY
      }&units=metric`
    );
    const api_call_forecast = await fetch(
      `${baseUrl}forecast?${city ? "q=" + city : "lat=" + lat + "&lon=" + lon}&appid=${
        process.env.REACT_APP_WEATHER_KEY
      }&units=metric`
    );
    console.log(api_call_current);
    console.log(api_call_forecast);
    const data1 = await api_call_current.json();
    const data2 = await api_call_forecast.json();
    const weather = data1;
    const forecast = data2.list.map((forecast) => ({
      condition: forecast.weather[0].description,
      date: new Date(forecast.dt * 1000),
      temperature: forecast.main.temp,
      humidity: forecast.main.humidity
    }));
    console.log(city);
    console.log(location);
    setWeather(weather);
    setForecast(forecast);
    console.log(weather);
    console.log(forecast);
  };

  // let getWeather = async (lat, long) => {
  //   let res = await axios.get(baseUrl + "weather", {
  //     params: {
  //       lat: lat,
  //       lon: long,
  //       appid: process.env.REACT_APP_WEATHER_KEY,
  //       lang: 'pt',
  //       units: 'metric'
  //     }
  //   });
  //   // let res2 = await axios.get(baseUrl + "forecast", {
  //   //   params: {
  //   //     lat: lat,
  //   //     lon: long,
  //   //     appid: process.env.REACT_APP_WEATHER_KEY,
  //   //     lang: 'pt',
  //   //     units: 'metric'
  //   //   }
  //   // });
  //   setWeather(res.data);
  //   // setForecast(res2.data);
  //   // console.log(forecast)
  // }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      getWeather(position.coords.latitude, position.coords.longitude);
      setLocation(true);
    });
  }, []);

  if (location === false) {
    return <StyledProgress />;
  } else if (weather === false) {
    return <StyledProgress />;
  } else {
    const table = [
      {
        heading: "Description",
        value: weather.weather[0].main + " (" + weather.weather[0].description + ")"
      },
      { heading: "Temperature", value: Number(weather.main.temp) + "°C" },
      {
        heading: "Temp. Min/Max",
        value: Number(weather.main.temp_min) + "°C / " + Number(weather.main.temp_max) + "°C"
      },
      { heading: "Humidity", value: Number(weather.main.humidity) + "%" },
      {
        heading: "Wind",
        value: Number(weather.wind.speed) + "km/h " + Number(weather.wind.deg) + "°"
      },
      { heading: "Pressure", value: Number(weather.main.pressure) + "hpa" },
      {
        heading: "Sunrise",
        value: moment(weather.sys.sunrise).format("HH:mm")
      },
      { heading: "Sunset", value: moment(weather.sys.sunset).format("HH:mm") }
    ];
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item md={5} xs={12}>
            <Card className={classes.card}>
              <CardHeader
                title={"Weather for " + weather.name + ", " + weather.sys.country}
                subheader={"Last updated " + moment(weather.dt).format("DD-MMM-YY HH:mm")}
              />
              <Grid
                className={classes.item}
                container
                direction="row"
                justify="space-evenly"
                spacing={2}
              >
                <Button
                  href={"https://openweathermap.org/city/" + weather.id}
                  size="small"
                  startIcon={<WeatherIcon />}
                >
                  Full report
                </Button>

                <Button startIcon={<LocationSearchingIcon />}>Current Location</Button>
                <Grid
                  className={classes.item}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <TextField
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                    label="Enter city"
                  />
                  <Button onClick={getWeather}>Search</Button>
                </Grid>
                <Grid className={classes.item} item>
                  <StyledBasicTable rows={table} />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item md={7} xs={12}>
            <Card className={classes.card}>
              <CardHeader title={"Forecast Weather"} subheader={"5-day / 3-hourly"} />
              <ResponsiveContainer width="100%" height={500}>
                <ComposedChart
                  // width={}
                  // height={440}
                  data={forecast}
                  margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    // dataKey={moment(forecast.date).format('DD-MMM-YY HH:mm')}
                    dataKey="newDate"
                    angle={-45}
                    textAnchor="end"
                    tickCount={2}
                    height={120}
                    stroke="#000000de"
                    // tickMargin={200}
                    // tickFormatter={formatXAxis}
                  />
                  <YAxis width={40} stroke="#000000de" />
                  <Tooltip />
                  <Legend stroke="#000000de" />
                  <Bar
                    type="linear"
                    name="Temperature"
                    dataKey="temperature"
                    fill="#413ea0"
                    unit="&deg;C"
                    // label={{ fontSize: 12 }}
                  />
                  <Line
                    type="monotone"
                    name="Humidity"
                    dataKey="humidity"
                    stroke="#0015ff"
                    unit="%"
                    // label={{ fontSize: 12 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Weather;
