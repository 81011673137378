import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25, 3),
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(5.5)
    }
  },
  text: {
    margin: theme.spacing(0),
    "& .MuiListItemText-secondary": {
      fontSize: "0.65rem"
    }
  }
}));

function StyledListLink({ to, primary, secondary, icon, onClick }) {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.root}
      button
      component={to ? Link : "li"}
      to={to ? to : null}
      key={primary}
      onClick={onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        className={classes.text}
        primary={primary}
        primaryTypographyProps={{ noWrap: true }}
        secondary={secondary && secondary}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  );
}

export default StyledListLink;
