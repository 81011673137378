import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMap, Tooltip, Circle, CircleMarker, useMapEvents } from "react-leaflet";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import MapButton from "./MapButton";

function Locate(props) {
  const map = useMap();

  const dispatch = useDispatch();

  const [locate, setLocate] = useState(0);

  function toggleLocate() {
    setLocate(locate < 2 ? locate + 1 : 0);
  }

  const [position, setPosition] = useState(null);

  const [accuracy, setAccuracy] = useState(null);

  const ringStyle = {
    weight: 1,
    color: "#1976d2",
    fillColor: "#1976d2",
    fillOpacity: 0.2
  };

  const markerStyle = {
    radius: 10,
    weight: 3,
    color: "white",
    fillColor: "#1976d2",
    fillOpacity: 1
  };

  const icon = {
    0: <LocationSearchingIcon fontSize="small" />,
    1: <LocationSearchingIcon color="error" fontSize="small" />,
    2: <DirectionsWalkIcon color="secondary" />
  };

  useEffect(() => {
    if (locate === 0) {
      map.stopLocate();
      setPosition(null);
      setAccuracy(null);
      dispatch({
        type: "UPDATE_MAP_ACCURACY",
        payload: {
          accuracy: null
        }
      });
    }
    if (locate === 1) {
      map
        .locate({
          watch: false,
          enableHighAccuracy: true,
          setView: true
        })
        .on("locationfound", function (e) {
          setPosition(e.latlng);
          setAccuracy(e.accuracy);
          dispatch({
            type: "UPDATE_MAP_ACCURACY",
            payload: {
              accuracy: e.accuracy
            }
          });
        })
        .on("locationerror", function (error) {
          dispatch({
            type: "ENQUEUE_SNACKBAR",
            notification: {
              key: new Date().getTime() + Math.random(),
              message: error.message,
              options: {
                variant: "error"
              }
            }
          });
          setLocate(false);
        });
    } else if (locate === 2) {
      map
        .locate({
          watch: true,
          enableHighAccuracy: true,
          setView: true
        })
        .on("locationfound", function (e) {
          setPosition(e.latlng);
          setAccuracy(e.accuracy);
          dispatch({
            type: "UPDATE_MAP_ACCURACY",
            payload: {
              accuracy: e.accuracy
            }
          });
        })
        .on("locationerror", function (error) {
          dispatch({
            type: "ENQUEUE_SNACKBAR",
            notification: {
              key: new Date().getTime() + Math.random(),
              message: error.message,
              options: {
                variant: "error"
              }
            }
          });
          setLocate(0);
        });
    }
  }, [locate]);

  useEffect(() => {
    if (!map.stopLocate()) {
      setLocate(0);
    }
  }, [map]);

  useEffect(() => {
    if (locate === 1) {
      const timer = setTimeout(() => {
        setLocate(0);
      }, 10000);
      return () => clearTimeout(timer);
    } else null;
  }, [locate]);

  return (
    <Fragment>
      <MapButton
        title={locate ? "Click to disable location" : "Click to locate"}
        onClick={toggleLocate}
        disabled={props.disabled}
      >
        {icon[locate]}
      </MapButton>
      {position && accuracy && (
        <Circle
          center={position}
          pathOptions={ringStyle}
          radius={accuracy === null ? 100 : accuracy * 2}
        >
          <CircleMarker center={position} pathOptions={markerStyle} radius={6}>
            <Tooltip
              style={{ color: "#757575" }}
              direction="bottom"
              offset={[0, 10]}
              opacity={1}
              permanent
            >
              Accuracy {accuracy === null ? "Searching..." : `${accuracy.toFixed(0)} m`}
            </Tooltip>
          </CircleMarker>
        </Circle>
      )}
    </Fragment>
  );
}

export default Locate;
