import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { DashboardLayout, CallEmergency } from "@austere-monorepo/components";
import { appTitle, appSubtitle, roles, version, prompts, storeItems } from "config";
import toolbarLogo from "assets/toolbar-logo.png";

// Icons
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { sidebarItems } from "routes";

function Info() {
  return <CallEmergency number={"000"} />;
}

function Dashboard({ _isAuth, _role, _user, children }) {
  const quickAddItems = [
    {
      title: "Add an Action",
      path: "/actions/add",
      icon: <LocalHospitalIcon />,
      role: 2
    }
  ];

  return (
    <DashboardLayout
      menuItems={sidebarItems}
      children={children}
      isAuth={_isAuth}
      user={_user}
      quickAddItems={quickAddItems}
      storeItems={storeItems}
      appTitle={appTitle}
      appSubtitle={appSubtitle}
      version={version}
      info={<Info />}
      role={_role && _role}
      roleTitle={roles[_role]}
      toolbarLogo={toolbarLogo}
      prompts={prompts}
    />
  );
}

const mapStateToProps = ({ firebase: { auth }, firestore: { data } }) => ({
  _user: auth,
  _profile: data && data.owner,
  _isAuth: auth.isLoaded && !auth.isEmpty,
  _role: data.owner && data.owner.role
});

export default compose(connect(mapStateToProps))(Dashboard);
