import { Typography, Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 64px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)"
    }
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    background: theme.palette.background.paper
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push("/");
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Typography gutterBottom align="center" variant="h1">
          Page Not Found
        </Typography>
        <Box className={classes.buttons}>
          <Button
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            onClick={goBack}
          >
            Go Back
          </Button>
          <Button
            className={classes.button}
            startIcon={<HomeIcon />}
            onClick={goHome}
          >
            Go Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotFound;
