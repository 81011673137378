import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Typography,
  Box,
  Grid
} from "@material-ui/core";
import { useMap } from "react-leaflet";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import MapButton from "./MapButton";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    margin: theme.spacing(0)
  },
  dialogContainer: {
    margin: theme.spacing(0),
    width: "calc(100vw + 240px)",
    // minHeight: "200px",
    height: "50vh",
    // border: "1px solid red",
    [theme.breakpoints.down("sm")]: {
      width: "100vw"
    }
  },
  dialogPaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    boxShadow: theme.shadows[1]
  },
  textField: {
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.25, 0)
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5)
  }
}));

function Search(props) {
  const { options } = props;
  // console.log(options);
  const map = useMap();
  const classes = useStyles();

  // Coordinates logic

  const initialLat = useSelector((state) => state.mapState && state.mapState.lat);

  const initialLng = useSelector((state) => state.mapState && state.mapState.lng);

  const [lat, setLat] = useState(null);

  const [lng, setLng] = useState(null);

  // Dialog logic
  const [open, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log("opened");
    map.stopLocate();
    setAnchorEl(event.currentTarget);
    setLat(initialLat);
    setLng(initialLng);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Tabs logic

  const [value, setValue] = useState(0);

  // console.log(value);

  const handleChange = (e) => {
    setValue(e.target.value);
    console.log(value);
  };

  const handleLatChange = (e) => {
    setLat(Number(e.target.value));
  };

  const handleLngChange = (e) => {
    setLng(Number(e.target.value));
  };

  const coords = [lat, lng];

  useEffect(() => {
    if (open) {
      // map.stopLocate();
      map.setView(coords);
    } else null;
  }, [coords]);

  // Kill geolocation if the user has it turned on
  // useEffect(() => {
  //   if (open) {
  //     map.stopLocate();
  //   } else null;
  // }, [open]);

  // console.log(coords);

  const helperText = {
    0: "The map center will move automatically to reflect the coordinates entered. Use negative (-) values for latitude South."
  };

  return (
    <Fragment>
      <MapButton title={"Search"} onClick={handleClick}>
        <SearchIcon />
      </MapButton>
      <Dialog
        open={Boolean(open)}
        onClose={handleClose}
        classes={{
          container: classes.dialogContainer,
          paper: classes.dialogPaper,
          backDrop: classes.dialogBackdrop
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="search-type"
              select
              label="Search Type"
              value={value}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              helperText={helperText[value]}
              className={classes.textField}
              fullWidth
            >
              <MenuItem value={0}>Coordinates (dd.ddddd)</MenuItem>
              {/* <MenuItem  value={1}>Place</MenuItem> */}
              {/* <MenuItem value={2}>Feature</MenuItem> */}
              {/* <MenuItem value={3}>what3words</MenuItem> */}
            </TextField>
          </Grid>

          {/* Coordinate */}
          {value === 0 && (
            <Fragment>
              <Grid item xs={6}>
                <TextField
                  value={coords[0]}
                  label="Latitude"
                  type="number"
                  fullWidth
                  notched
                  onInput={(e) => handleLatChange(e)}
                  variant="outlined"
                  inputProps={{
                    step: 0.00001,
                    min: -90,
                    max: 90
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={coords[1]}
                  label="Longitude"
                  type="number"
                  fullWidth
                  notched
                  onInput={(e) => handleLngChange(e)}
                  variant="outlined"
                  inputProps={{
                    step: 0.00001,
                    min: -180,
                    max: 180
                  }}
                />
              </Grid>
              {options && options.geoPointLink && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-around">
                    <Typography variant="caption" component={Link} to={"/geopoints/add"}>
                      Add a point here
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Fragment>
          )}

          {/* Place */}
          {value === 1 && <Fragment></Fragment>}

          {/* Feature */}
          {value === 2 && <Fragment></Fragment>}

          {/* what3words */}
          {value === 3 && <Fragment></Fragment>}
        </Grid>
      </Dialog>
    </Fragment>
  );
}

export default Search;
