import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import { StyledWrapper } from "@austere-monorepo/components";
function AccessDenied() {
  return (
    <StyledWrapper>
      <Alert style={{ margin: "16px" }} icon={<LockIcon fontSize="inherit" />} severity="warning">
        <AlertTitle>Access Denied</AlertTitle>
        You don't have access to this section. For access issues, please contact your admin or team
        leader. For technical issues, please email{" "}
        <Link to="mailto:contact@austererisk.com">contact@austererisk.com</Link>
      </Alert>
      <Box display="flex" justifyContent="center">
        <Button
          startIcon={<HomeIcon />}
          color="primary"
          variant="text"
          component={Link}
          to={"/"}
          align="center"
        >
          Back to Home
        </Button>
      </Box>
    </StyledWrapper>
  );
}

export default AccessDenied;
