import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import HazardForm from "./HazardForm";
import {
  StyledProgress,
  ViewButton,
  DeleteButton,
  SaveButton,
  handleFormDelete,
  handleFormEdit
} from "@austere-monorepo/components";

function EditHazard(props) {
  const { handleFormEdit, handleFormDelete, handleSubmit, _hazard, _auth, _role, _users } = props;
  const deleteHazard = () => {
    handleFormDelete({
      formCollection: "hazards",
      formData: _hazard,
      auth: _auth,
      redirect: "/actions"
    });
  };

  const onSubmit = ({ id, ...formProps }) => {
    handleFormEdit({
      formCollection: "hazards",
      formData: formProps,
      currentData: _hazard,
      auth: _auth,
      redirect: "/actions"
    });
  };

  return (
    <Fragment>
      {_hazard && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <HazardForm
            role={_role}
            users={_users}
            {...props}
            actionItems={
              <Fragment>
                <DeleteButton action={deleteHazard} />
                <SaveButton {...props} />
                <ViewButton to={`/actions/view/${_hazard.id}`} />
              </Fragment>
            }
          />
        </form>
      )}
      {!_hazard && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firebase: { auth }, firestore: { data, ordered } }, props) {
  const currentHazard =
    ordered.hazards && ordered.hazards.filter((task) => task.id === props.match.params.id)[0];
  return {
    _auth: auth,
    _hazard: currentHazard,
    _role: data.owner && data.owner.role,
    _users: ordered.users && ordered.users.filter((user) => user.role === 2),
    initialValues: currentHazard
  };
}

export default compose(
  connect(mapStateToProps, {
    handleFormDelete,
    handleFormEdit
  }),
  reduxForm({
    form: "hazardForm",
    enableReinitialize: true
  })
)(EditHazard);
