import React, { Fragment } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";

function RenderNotes({ notes }) {
  return (
    <Fragment>
      {notes
        ? notes.map((note, i) => (
            <Fragment key={i}>
              <Typography variant="caption" color="textSecondary">{`${
                note.created_by_name
              } ${moment(note.date).format("DD-MMM-YY HH:mm")}`}</Typography>
              <Typography gutterBottom>{note.note}</Typography>
            </Fragment>
          ))
        : "No notes"}
    </Fragment>
  );
}

export default RenderNotes;
