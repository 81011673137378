import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import UserForm from "../user/UserForm";
import {
  StyledProgress,
  DeleteButton,
  SaveButton,
  handleFormDelete,
  handleFormEdit
} from "@austere-monorepo/components";

function EditUser(props) {
  const { handleFormEdit, handleFormDelete, handleSubmit, _user, _auth, _role } = props;

  console.log(_user);
  console.log(_auth);

  const deleteUser = () => {
    handleFormDelete({
      formCollection: "users",
      formData: _user,
      auth: _auth,
      redirect: "/management/users"
    });
  };

  const onSubmit = ({ id, ...formProps }) => {
    handleFormEdit({
      formCollection: "users",
      formData: formProps,
      currentData: _user,
      auth: _auth,
      redirect: "/management/users"
    });
  };

  return (
    <Fragment>
      {_user && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm
            title={_user.displayName}
            role={_role}
            {...props}
            actionItems={
              <Fragment>
                {_auth.uid !== _user.id && <DeleteButton action={deleteUser} />}
                <SaveButton {...props} />
                {/* <ViewButton to={`/users/view/${_user.id}`} /> */}
              </Fragment>
            }
          />
        </form>
      )}
      {!_user && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firebase: { auth }, firestore: { ordered, data } }, props) {
  const currentUser =
    ordered.users && ordered.users.filter((user) => user.id === props.match.params.id)[0];
  return {
    _role: data.owner && data.owner.role,
    _auth: auth,
    _user: currentUser,
    initialValues: currentUser
  };
}

export default compose(
  connect(mapStateToProps, {
    handleFormDelete,
    handleFormEdit
  }),
  reduxForm({
    form: "userForm"
  })
)(EditUser);
