const initState = {
  serviceWorkerInitialized: false,
  // serviceWorkerUpdated: true, // testing
  serviceWorkerUpdated: false, // live
  serviceWorkerRegistration: null,
  appInstalled: false,
  key: 1
};

function sw(state = initState, action) {
  switch (action.type) {
    case "SW_INIT":
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized
      };
    case "SW_UPDATE":
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload
      };
    // case "APP_INSTALLED":
    //   return {
    //     ...state,
    //     appInstalled: !state.appInstalled
    //   };
    default:
      return state;
  }
}

export default sw;
