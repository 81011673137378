import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, OutlinedInput } from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.background.paper,
    color: "red"
  }
}));

export function RenderUrlField({
  input,
  label,
  name,
  helper,
  md,
  xs,
  actions,
  required,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();

  return (
    <FieldWrapper
      label={label}
      required={required}
      name={name}
      helper={helper}
      md={md}
      xs={xs}
      actions={actions}
    >
      <OutlinedInput
        className={classes.field}
        fullWidth
        {...input}
        id={name}
        type="url"
        {...rest}
      />
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
