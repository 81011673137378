import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { MapContainer, CircleMarker, TileLayer } from "react-leaflet";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import "./styles.css";
import { StyledProgress } from "@austere-monorepo/components";

function MiniMap({ lat, lng, accuracy }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > #mini-map": {
        height: "300px",
        position: "relative",
        border: `1px solid ${theme.palette.divider}`
      }
    },
    detailsBox: {
      display: "flex",
      gap: theme.spacing(2),
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    link: {
      padding: theme.spacing(1),
      width: "100%",
      textAlign: "center"
    }
  }));

  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  const baseURL = "https://api.mapbox.com/styles/v1/austere-rm/";
  const endURL = "/tiles/256/{z}/{x}/{y}?access_token=";
  const classes = useStyles();
  const position = [lat, lng];
  return (
    <Fragment>
      {Number(lat) && Number(lng) ? (
        <Fragment>
          <Box className={classes.root}>
            {position ? (
              <MapContainer
                id={"mini-map"}
                attributionControl={false}
                center={position && position}
                zoom={12}
                // scrollWheelZoom={false}
              >
                <TileLayer url={baseURL + "ck8jkyb5q0a0j1imlxiwyhgdb" + endURL + token} />
                <CircleMarker center={position} radius={10} />
              </MapContainer>
            ) : (
              <StyledProgress />
            )}
          </Box>
          <Box className={classes.detailsBox}>
            <Box>
              <Typography display="inline" variant="body2" style={{ fontWeight: 500 }}>
                {"Coordinates: "}
              </Typography>

              <Typography display="inline" variant="body2">
                {lat.toFixed(5)}, {lng.toFixed(5)}{" "}
              </Typography>
            </Box>
            {accuracy && (
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                GPS Accuracy: {`${accuracy.toFixed(0)}m`}
              </Typography>
            )}

            <Typography variant="body2" component={Link} to={`/map#${lat},${lng},12`}>
              View on map
            </Typography>

            <Typography
              variant="body2"
              component={"a"}
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
            >
              Open in Google Maps
            </Typography>
          </Box>
        </Fragment>
      ) : (
        `No location`
      )}
    </Fragment>
  );
}

MiniMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  accuracy: PropTypes.number
};

export default MiniMap;
