import { makeStyles } from "@material-ui/core/styles";
import FieldWrapper from "./FieldWrapper";

import ReactQuill from "react-quill";
import "./quill.css";

const useStyles = makeStyles((theme) => ({
  editor: {
    // backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    // '& :focus': `1px solid ${theme.palette.primary.main}`,
    "& .ql-toolbar .ql-stroke": {
      fill: "none",
      stroke: theme.palette.text.secondary
    },

    "& .ql-toolbar .ql-fill": {
      fill: theme.palette.text.secondary,
      stroke: "none"
    },

    "& .ql-toolbar .ql-picker": {
      color: theme.palette.text.secondary
    },
    "& .ql-toolbar": {
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.divider}`,

      "& .ql-picker-label:hover": {
        color: theme.palette.secondary.main
      },
      "& .ql-picker-label.ql-active": {
        color: theme.palette.secondary.main
      },
      "& .ql-picker-item:hover": {
        color: theme.palette.secondary.main
      },
      "& .ql-picker-item.ql-selected": {
        color: theme.palette.secondary.main
      },
      "& button:hover": {
        color: theme.palette.secondary.main,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main
        }
      },
      "& button:focus": {
        color: theme.palette.secondary.main,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main
        }
      },
      "& button.ql-active": {
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main
        }
      },

      // "& .ql-stroke, ql-fill": {
      //   stroke: theme.palette.text.secondary
      //   // fill: theme.palette.text.secondary
      // },
      "& .ql-picker": {
        color: theme.palette.text.secondary
      },
      "& .ql-picker-options": {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: "none",
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius
      }
    },
    "& .ql-container": {
      borderRadius: theme.shape.borderRadius,
      // minHeight: "100px",
      // Placeholder
      "& .ql-editor.ql-blank::before": {
        color: theme.palette.text.hint,
        fontStyle: "inherit",
        left: "10px",
        fontSize: theme.typography.body2.fontSize
      },
      "& .ql-editor p:first-child": {
        marginTop: 0
      },
      "& .ql-editor": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        padding: theme.spacing(1.5),
        minHeight: "100px",
        maxHeight: "400px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxSizing: "border-box",
        lineHeight: "1.42",
        height: "100%",
        outline: "none",
        overflowY: "auto"
        // padding: 12px 15px;
        // tab-size: 4;
        // -moz-tab-size: 4;
        // text-align: left;
        // white-space: pre-wrap;
        // word-wrap: break-word;
      },
      // "& .ql-editor h1": {
      //   fontSize: theme.typography.h1.fontSize,
      //   fontWeight: theme.typography.h1.fontWeight
      // },
      // "& .ql-editor h2": {
      //   fontSize: theme.typography.h2.fontSize,
      //   fontWeight: theme.typography.h2.fontWeight,
      //   marginBottom: "0.35rem"
      // },
      "& .ql-editor h3": {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.h3.fontWeight,
        marginBottom: "0.35rem"
      },
      "& .ql-editor h4": {
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.h4.fontWeight,
        marginBottom: "0.35rem"
      },
      "& .ql-editor h5": {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.h5.fontWeight,
        marginBottom: "0.35rem"
      },
      "& .ql-editor strong": {
        fontWeight: 500
      },
      "& .ql-editor p, .ql-editor ol, .ql-editor ul": {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
        marginBottom: theme.spacing(3)
      }
    }
  }
}));

export function RenderRichText({
  input,
  label,
  name,
  placeholder,
  helper,
  required
  // className
  // ...rest,
  // ...props
}) {
  const classes = useStyles();
  const modules = {
    toolbar: [
      { header: [4, 5, false] },
      "bold",
      "underline",
      // "blockquote",
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
      "strike",
      { color: ["white"] },
      { background: ["yellow"] },
      "clean",
      "link",
      "image",
      "video"
    ]
    // imageResize: {
    //   handleStyles: {
    //     backgroundColor: 'black',
    //     border: 'none',
    //     color: 'white'
    //   },
    //   modules: ['Resize', 'DisplaySize', 'Toolbar']
    // },
    // imageCompress: {
    //   quality: 0.7, // default
    //   maxWidth: 1000, // default
    //   maxHeight: 1000, // default
    //   imageType: 'image/jpeg', // default
    //   debug: true // default
    // }
  };

  return (
    <FieldWrapper label={label} required={required} name={name} helper={helper}>
      <ReactQuill
        required={required}
        className={classes.editor}
        {...input}
        placeholder={placeholder}
        modules={modules}
        onChange={(newValue, delta, source) => {
          if (source === "user") {
            input.onChange(newValue);
          }
        }}
        onBlur={(range, source, quill) => {
          input.onBlur(quill.getHTML());
        }}
      />
    </FieldWrapper>
  );
}
