import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Tooltip, IconButton, Hidden } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import { Link } from "react-router-dom";
import { StyledListLink } from "@austere-monorepo/components";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: 0,
      top: 2
      // border: `1px solid ${theme.palette.common.white}`
      // padding: "0 4px"
    },
    "& .MuiBadge-dot": {
      height: "10px",
      padding: 5,
      minWidth: "10px",
      borderRadius: "5px"
    }
  },
  tab: {
    minWidth: "0px",
    padding: theme.spacing(0, 2),
    fontWeight: 400
  },
  heading: {
    fontWeight: 500
  },
  headingHidden: {
    color: theme.palette.text.secondary
  },
  details: {
    display: "inherit",
    padding: theme.spacing(0, 2)
  }
}));

function DownloadMapPrompt() {
  const classes = useStyles();
  const downloadMap = useSelector((state) => state.prompt && state.prompt.downloadMap);
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("downloadMap") === null) {
      localStorage.setItem("downloadMap", true);
    }
    if (localStorage.getItem("downloadMap") === "true") {
      dispatch({
        type: "UPDATE_DOWNLOAD_MAP",
        payload: {
          downloadMap: true
        }
      });
    }
    if (localStorage.getItem("downloadMap") === "false") {
      dispatch({
        type: "UPDATE_DOWNLOAD_MAP",
        payload: {
          downloadMap: false
        }
      });
    }
  }, [downloadMap]);

  const handleClick = () => {
    localStorage.setItem("downloadMap", false);
    // history.push("/account/settings");
  };

  return (
    <Fragment>
      {downloadMap === true && (
        <Fragment>
          <Hidden mdUp implementation="css">
            <StyledListLink
              icon={
                <Badge variant="dot" color="error" className={classes.badge}>
                  <MapIcon />
                </Badge>
              }
              primary={"Download Maps"}
              to={"/account/settings"}
              onClick={handleClick}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Tooltip title="Download maps">
              <IconButton
                color="inherit"
                component={Link}
                to={"/account/settings"}
                onClick={handleClick}
              >
                <Badge variant="dot" color="error" className={classes.badge}>
                  <MapIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Hidden>
        </Fragment>
      )}
    </Fragment>
  );
}

export default DownloadMapPrompt;

// https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen
