import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import notifications from "./notifier";
import theme from "./theme.js";
import sw from "./sw.js";
import prompt from "./prompt";
import mapState from "./map.js";

export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer,
  notifications,
  mapState,
  theme,
  prompt,
  sw
});
