import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    padding: theme.spacing(0, 0, 0.5, 0)
  },
  link: {
    width: '100%'
  }
}));

function ListLink({ to, href, title, display, color }) {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      {title && title + ' '}
      {href && (
        <a className={classes.link} href={href}>
          {display}
        </a>
      )}
      {to && (
        <Link className={classes.link} to={to}>
          {display}
        </Link>
      )}
    </Box>
  );
}

ListLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  display: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default ListLink;
