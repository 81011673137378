import React, { Fragment, useEffect, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Badge,
  Tooltip,
  IconButton,
  Hidden
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { StyledListLink } from "@austere-monorepo/components";

const useStyles = makeStyles(() => ({
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: 6,
      top: 2
      // border: `1px solid ${theme.palette.common.white}`
      // padding: "0 4px"
    },
    "& .MuiBadge-dot": {
      height: "10px",
      padding: 5,
      minWidth: "10px",
      borderRadius: "5px"
    }
  }
}));

function UpdateButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isServiceWorkerUpdated = useSelector((state) => state.sw.serviceWorkerUpdated);

  const serviceWorkerRegistration = useSelector((state) => state.sw.serviceWorkerRegistration);

  useEffect(() => {
    if (isServiceWorkerUpdated) {
      dispatch({
        type: "UPDATE_UPDATE_AVAILABLE",
        payload: {
          update: true
        }
      });
    } else null;
  }, [isServiceWorkerUpdated]);

  const updateServiceWorker = () => {
    setOpen(false);

    const registrationWaiting = serviceWorkerRegistration
      ? serviceWorkerRegistration.waiting
      : null;

    if (registrationWaiting) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Updating...",
          options: {
            variant: "info"
          }
        }
      });
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          dispatch({
            type: "ENQUEUE_SNACKBAR",
            notification: {
              key: new Date().getTime() + Math.random(),
              message: "Updated successfully",
              options: {
                variant: "success"
              }
            }
          });
          window.location.reload();
        }
      });
    }
    if (registrationWaiting === null) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Error. Please try again later",
          options: {
            variant: "error"
          }
        }
      });
    }
  };

  return (
    <Fragment>
      {isServiceWorkerUpdated && (
        <Fragment>
          <Hidden mdUp implementation="css">
            <StyledListLink
              icon={
                <Badge variant="dot" color="error" className={classes.badge}>
                  <GetAppIcon />
                </Badge>
              }
              primary={"Update App"}
              onClick={handleOpen}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Tooltip title="Update App">
              <IconButton color="inherit" onClick={handleOpen}>
                <Badge variant="dot" color="error" className={classes.badge}>
                  <GetAppIcon style={{ marginTop: "4px" }} />
                </Badge>
              </IconButton>
            </Tooltip>
          </Hidden>
        </Fragment>
      )}
      <Dialog style={{ padding: "20px" }} open={open} onClose={handleClose}>
        <DialogTitle disableTypography style={{ textAlign: "center" }}>
          {`Update Available`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This application has been updated. Click 'Update' to proceed, or 'Cancel' to update at a
            later stage.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Dismiss</Button>
          <Button
            variant="outlined"
            onClick={updateServiceWorker}
            startIcon={<GetAppIcon />}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default UpdateButton;
