// /** @format */

// import React, {PureComponent, Fragment} from 'react';
// import {firestoreConnect} from 'react-redux-firebase';
// import {connect} from 'react-redux';
// import {compose} from 'redux';
// import {reduxForm} from 'redux-form';
// import SettingsForm from './SettingsForm';
// import {withSnackbar} from 'notistack';
// import { SaveButton } from '../styledComponents/StyledButtons';

// class EditSettings extends PureComponent {
//   onSubmit = async ({...formProps}) => {
//     const {firestore, enqueueSnackbar} = this.props;
//     try {
//       await firestore.update(
//         {collection: 'settings', doc: 'settings'},
//         formProps
//       );
//       await enqueueSnackbar('Settings updated', {
//         variant: 'success',
//       });
//     } catch (e) {
//       enqueueSnackbar('Settings update failed ' + e, {
//         variant: 'error',
//       });
//     }
//   };

//   render() {
//     const {handleSubmit} = this.props;
//     return (
//       <Fragment>
//         <form onSubmit={handleSubmit(this.onSubmit)}>
//           <SettingsForm {...this.props} />
//           <SaveButton doc="settings" />
//         </form>
//       </Fragment>
//     );
//   }
// }

// const mapStateToProps = ({firebase: {auth}, firestore: {ordered}, role}) => ({
//   _userUID: auth.uid,
//   _users: ordered.users && ordered.users,
//   _authorName: auth.displayName,
//   _hazardReviewers:
//     ordered.users &&
//     ordered.users.filter(
//       user => user.role === 'Management' || 'Trail Maintenance'
//     ),
//     _dutyManagers:
//     ordered.users &&
//     ordered.users.filter(
//       user => user.role === 'Management'
//     ),
//   _level: role && role.level,
//   initialValues: ordered.settings && ordered.settings[0],
// });

// export default compose(
//   connect(mapStateToProps),
//   reduxForm({
//     form: `settingsForm`,
//     enableReinitialize: false,
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: false,
//   }),
//   firestoreConnect(props => [
//     {collection: 'settings', storeAs: 'settings', doc: 'settings'},
//     {collection: 'users'},
//   ])
// )(withSnackbar(EditSettings));

import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import { compose } from "redux";
import {
  StyledProgress,
  SaveButton,
  handleFormEdit,
  FormWrapper,
  RenderSelectUserField,
  RenderFormSection,
  RenderHiddenField
} from "@austere-monorepo/components";

function EditTask(props) {
  const { handleFormEdit, handleSubmit, _settings, _auth, _hazardReviewers } = props;

  const onSubmit = ({ id, ...formProps }) => {
    handleFormEdit({
      formCollection: "settings",
      formData: formProps,
      currentData: _settings,
      auth: _auth,
      redirect: "/management"
    });
  };

  const dispatch = useDispatch();

  const formName = props.form;

  return (
    <Fragment>
      {_settings && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper
            title={"Manager Settings"}
            actionItems={
              <Fragment>
                <SaveButton {...props} />
              </Fragment>
            }
            {...props}
          >
            <RenderFormSection heading="Responsible Persons">
              <Fragment>
                <Field
                  required
                  label="Hazard Reviewer"
                  helper="Initial person assigned hazards by default"
                  name="hazard_reviewer"
                  type="text"
                  component={RenderSelectUserField}
                  items={_hazardReviewers}
                  onChange={(e, newVal) => {
                    // console.log(e);
                    // console.log(newVal);
                    const user = _hazardReviewers.find((user) => newVal === user.id);
                    dispatch(change(formName, "hazard_reviewer_name", user.displayName));
                    dispatch(change(formName, "hazard_reviewer_email", user.email));
                  }}
                  md={6}
                />
                <Field name="assigned_name" type="text" component={RenderHiddenField} />
                <Field name="assigned_email" type="text" component={RenderHiddenField} />
              </Fragment>
            </RenderFormSection>
          </FormWrapper>
        </form>
      )}
      {!_settings && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firebase: { auth }, firestore: { ordered } }, props) {
  const currentSettings = ordered.settings && ordered.settings[0];
  return {
    _auth: auth,
    _settings: currentSettings,
    _hazardReviewers: ordered.users && ordered.users.filter((user) => user.role === 2),
    initialValues: currentSettings
  };
}

export default compose(
  connect(mapStateToProps, {
    handleFormEdit
  }),
  reduxForm({
    form: "settingsForm",
    enableReinitialize: true
  })
)(EditTask);
