import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import HazardForm from "./HazardForm";
import { handleFormAdd, SaveButton } from "@austere-monorepo/components";

function AddHazard(props) {
  const { handleFormAdd, handleSubmit, _auth, _role, _users } = props;
  const onSubmit = (formProps) => {
    handleFormAdd({
      formCollection: "hazards",
      formData: formProps,
      auth: _auth,
      redirect: "/actions"
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HazardForm
        role={_role}
        users={_users}
        {...props}
        actionItems={
          <Fragment>
            <SaveButton {...props} />
          </Fragment>
        }
      />
    </form>
  );
}

const mapStateToProps = ({ firebase: { auth }, firestore: { data, ordered } }) => ({
  _role: data.owner && data.owner.role,
  _users: ordered.users && ordered.users.filter((user) => user.role === 2),
  _auth: auth,
  initialValues: {
    status: "Unresolved",
    hazard_rating: "Unrated",
    assigned: ordered.settings && ordered.settings[0].hazard_reviewer,
    assigned_name: ordered.settings && ordered.settings[0].hazard_reviewer_name,
    assigned_email: ordered.settings && ordered.settings[0].hazard_reviewer_email,
    hazard_id: ordered.hazards && ("0000" + (ordered.hazards.length + 1)).substr(-4, 4)
  }
});

export default compose(
  connect(mapStateToProps, { handleFormAdd }),
  reduxForm({
    form: "hazardForm",
    enableReinitialize: true
  })
)(AddHazard);
