import { StyledTable } from "@austere-monorepo/components";
import { useSelector } from "react-redux";

function UsersListTable() {
  const _users = useSelector((state) => state.firestore.ordered.users);

  const columns = [
    { title: "Edit", name: "edit" },
    { title: "Name", name: "displayName" },
    { title: "Role", name: "role" },
    { title: "Email", name: "email" },
    { title: "Phone", name: "phone" }
    // { title: "id", name: "id" },
    // { title: "created_at", name: "created_at" }
  ];

  const tableColumns = [];

  const dateColumns = [];

  const emailColumns = ["email"];

  const sortingColumns = [{ columnName: "displayName", direction: "asc" }];

  // const serverDateColumns = ["created_at"];

  return (
    <StyledTable
      title={"Users"}
      rows={_users}
      columns={columns && columns}
      collection={"users"}
      doc={"user"}
      addButton
      tableColumns={tableColumns}
      dateColumns={dateColumns}
      emailColumns={emailColumns}
      sortingColumns={sortingColumns}
      // serverTimeColumns={serverDateColumns}
    />
  );
}

export default UsersListTable;
