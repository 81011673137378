// Version
export const version = "2.09";

// Details
export const appTitle = "St Helens Trails";
export const appSubtitle = "Mountain Bike Network Safety App";
export const appUrl = "https://st-helens-trails.austererisk.com";

// Colors
export const primaryColor = "#006B87";

// Map
export const defaultLat = -41.27899;
export const defaultLng = 148.13573;
export const defaultZoom = 12;
export const defaultMapBase = "Topographic";
export const defaultMaxZoom = 20; //same as default map base above
export const defaultMinZoom = 3; //same as default map base above
export const defaultMapBounds = [
  [-90, -180],
  [90, 180]
];
export const defaultMapData = {
  "My Actions": true,
  Trails: true,
  Features: true
};

// Role Titles
// export const level_5 = { title: 'Emergency Services', level: 5 };
// export const level_4 = { title: 'Management', level: 4 };
// export const level_3 = { title: 'Commercial Operator', level: 3 };
// export const level_2 = { title: 'Trail Maintenance', level: 2 };
// export const level_1 = { title: 'Rider', level: 1 };

export const roles = {
  1: "Level 1",
  2: "Level 2"
};

// Store items and collections
export const storeItems = [
  { collection: "updates" },
  { collection: "settings" },
  { collection: "hazards" },
  { collection: "users" },
  { collection: "settings" }
];

// Comms Ratings
// export const commsColor = (rating) => {
//   if (!rating) {
//     return ""; // Null
//   }
//   if (rating <= 110) {
//     return "#29ad01"; // Green: Good connection
//   }
//   if (rating < 125 && rating > 110) {
//     return "#ffaa00"; // Orange: Fair connection
//   }
//   if (rating >= 125) {
//     return "#FF0000"; // Red: No connection
//   }
//   return "";
// };

// export const commsMessage = (rating) => {
//   if (!rating) {
//     return ""; // Null
//   }
//   if (rating <= 110) {
//     return "Good"; // Green: Good connection
//   }
//   if (rating < 125 && rating > 110) {
//     return "Fair/Poor"; // Orange: Fair connection
//   }
//   if (rating >= 125) {
//     return "No connection"; // Red: No connection
//   }
//   return "";
// };

// Hazard Color

// export const hazardColor = {
//   Extreme: "#b530aa",
//   High: "#ff0000",
//   Medium: "#f18834",
//   Low: "#6ca94f"
// };

// Add a registration link to the login page?
// export const allowRegister = true;

// Initial prompts
export const prompts = {
  install: true,
  downloadMap: true
};
