import React, { Fragment } from "react";
import { Field, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { RenderSelectUserField, RenderHiddenField } from "@austere-monorepo/components";

function FormUser({ users, ...props }) {
  const dispatch = useDispatch();
  const items = users
    ? users
    : useSelector((state) => state.firestore.ordered.users && state.firestore.ordered.users);
  const formName = props.form;
  // console.log(formName && formName);
  // console.log(props);
  return (
    <Fragment>
      <Field
        required
        name="assigned"
        label="Assigned To"
        type="text"
        component={RenderSelectUserField}
        items={items}
        onChange={(e, newVal) => {
          // console.log(e);
          // console.log(newVal);
          const user = items.find((user) => newVal === user.id);
          // return;
          dispatch(change(formName, "assigned_name", user.displayName));
          dispatch(change(formName, "assigned_email", user.email));
        }}
        md={6}
      />
      <Field name="assigned_name" type="text" component={RenderHiddenField} />
      <Field name="assigned_email" type="text" component={RenderHiddenField} />
    </Fragment>
  );
}

export default FormUser;
