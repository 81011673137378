import React, { Fragment, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import CropFreeIcon from "@material-ui/icons/CropFree";
import useClipboard from "react-use-clipboard";
import { RenderFormSection } from "@austere-monorepo/components";
// import { useSelector } from "react-redux";

function ShareThisApp(props) {
  const { qrCode, appUrl, appTitle } = props;
  const [isCopied, setCopied] = useClipboard(appUrl, {
    successDuration: 3000
  });
  const [open, setOpen] = useState(false);

  const [share, setShare] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const theme = useSelector((state) => state.theme && state.theme.theme);

  useEffect(() => {
    if (navigator.share) {
      setShare(true);
    } else setShare(false);
  }, []);

  const shareApp = () => {
    navigator
      .share({
        title: appTitle,
        text: `Sharing ${appTitle}`,
        url: appUrl
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  return (
    <Fragment>
      <RenderFormSection heading="Share This App">
        <Grid item xs={12} md={6}>
          <Typography gutterBottom paragraph>
            Scan this QR code to open this app on another device.
          </Typography>
          <Button
            startIcon={<CropFreeIcon />}
            fullWidth
            variant="outlined"
            color="primary"
            // disabled={theme === "dark"}
            onClick={handleClickOpen}
          >
            {/* {theme === "light" ? "Open QR Code" : "Disabled in Dark Mode"} */}
            Open QR Code
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography gutterBottom paragraph>
            {share
              ? `Click to share this app`
              : `Click to copy this app's URL for sharing via text or email`}
          </Typography>
          <Button
            startIcon={<ShareIcon />}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={share ? shareApp : setCopied}
          >
            {share ? `Share This App` : isCopied ? "URL Copied to Clipboard" : "Copy URL"}
          </Button>
        </Grid>
      </RenderFormSection>

      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        {/* <DialogTitle disableTypography
          Ask your friend to scan this QR code which will open this app on their
          device
        </DialogTitle> */}
        <DialogContent>
          <Container maxWidth="xs" component={"img"} src={qrCode} alt="qr-code" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ShareThisApp;
