import { Field } from "redux-form";
import {
  FormWrapper,
  RenderTextField,
  RenderSelectRoleField,
  RenderFormSection
} from "@austere-monorepo/components";
import { useSelector } from "react-redux";
import { roles } from "config";

function UserForm(props) {
  // console.log(props);
  const _role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );

  console.log("my role", _role);
  console.log("my uid", props._auth.uid);
  console.log("users id", props._user.id);

  return (
    <FormWrapper {...props}>
      <RenderFormSection heading="Details">
        <Field name="phone" label="Mobile Number" type="tel" component={RenderTextField} md={6} />
        <Field
          name="role"
          label="Role"
          type="text"
          component={RenderSelectRoleField}
          disabled={_role !== 2 || props._auth.uid === props._user.id}
          items={roles}
          md={6}
          helper={_role === 2 ? "Change the user's access " : "You don't have access to this field"}
        />
      </RenderFormSection>
    </FormWrapper>
  );
}

export default UserForm;
