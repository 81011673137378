const initState = {
  theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
};

function theme(state = initState, action) {
  switch (action.type) {
    case "UPDATE_THEME":
      return {
        ...state,
        theme: action.payload.theme
      };
    default:
      return state;
  }
}

export default theme;
