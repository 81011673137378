import React from 'react'
// import { props.client } from '../../config'
import moment from 'moment/moment'
import { makeStyles } from '@material-ui/core/styles'

// Styles
import { Box, Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    position: 'absolute',
    bottom: '18px',
    width: '785px',
    height: '40px'
  },
  footer: {
    margin: theme.spacing(1, 0)
  }
}))

function DocumentTemplate(props) {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Divider />
      <Box
        className={classes.footer}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          Printed {moment().format('DD/MM/YY HH:mm')}
        </Typography>
        <Typography variant="caption">
          {props.client}: {props.collection} - {props.doctitle}
        </Typography>
        <Typography variant="caption">
          Page {props.pageNumber} of {props.totalPageNumber}
        </Typography>
      </Box>
    </Box>
  )
}

export default DocumentTemplate
