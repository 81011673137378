import ReactDOM from "react-dom";
import { App, store, rrfProps, serviceWorkerRegistration } from "@austere-monorepo/components";
import Dashboard from "client/dashboard/Dashboard";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Provider } from "react-redux";

import { items } from "./routes";

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App dashboard={Dashboard} routes={items} />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: "SW_INIT" }),
  onUpdate: (registration) => store.dispatch({ type: "SW_UPDATE", payload: registration })
});
