import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { FormLabel, FormControl, Tooltip, Grid, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0)
  },
  formControl: {
    minWidth: "100%"
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0.65),
    fontSize: "0.85rem",
    color: theme.palette.text.primary
  },
  required: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },
  actions: {
    margin: theme.spacing(0),
    fontSize: "0.8rem"
  },
  helperIcon: {
    margin: theme.spacing(0, 0, 0, 1),
    fontSize: "1rem",
    cursor: "pointer",
    marginTop: "-3px"
  }
}));

function FieldWrapper(props) {
  const { label, actions, helper, children, required, md, xs } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.root} item key={label} xs={xs ? xs : 12} md={md ? md : 12}>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.label}>
          {label}
          {required && <Box className={classes.required}>*</Box>}
          <div className={classes.spacer} />
          {actions && <Box className={classes.actions}>{actions}</Box>}
          {helper && (
            <Tooltip title={helper} placement="top-end">
              <HelpOutlineIcon className={classes.helperIcon} />
            </Tooltip>
          )}
        </FormLabel>
        {children}
      </FormControl>
    </Grid>
  );
}

export default FieldWrapper;
