import React, { Fragment, useEffect, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useDispatch } from "react-redux";
import useClipboard from "react-use-clipboard";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Button } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "180px",
    minWidth: "180px",
    maxWidth: "180px",
    left: "10px",
    bottom: "10px",
    zIndex: 999,
    background: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.paper
    }
  },
  icon: {
    fontSize: "15px"
  },
  green: {
    color: "green"
  },
  label: {
    justifyContent: "flex-start",
    fontSize: "0.75rem",
    letterSpacing: "0.05em",
    color: theme.palette.text.secondary
  }
}));

function Coordinates({ id }) {
  const map = useMap();

  const dispatch = useDispatch();

  const classes = useStyles();

  const [position, setPosition] = useState(map.getCenter());
  const [finalPosition, setFinalPosition] = useState(position);
  const [zoom, setZoom] = useState(map.getZoom());

  const lat = Number(position.lat.toFixed(5));
  const lng = Number(position.lng.toFixed(5));

  const finalLat = Number(finalPosition.lat.toFixed(5));
  const finalLng = Number(finalPosition.lng.toFixed(5));

  useMapEvents({
    move: () => {
      setPosition(map.getCenter());
    },
    moveend: () => {
      setFinalPosition(position);
      // alert(JSON.stringify(map.getBounds())); //uncomment to get map bounds for creating offline layers
    },
    zoom: () => {
      setZoom(map.getZoom());
    },
    zoomend: () => {
      dispatch({
        type: "UPDATE_MAP_ZOOM",
        payload: {
          zoom: zoom
        }
      });
    }
  });

  // Update the URL bar only for the main map
  useEffect(() => {
    if (id === "main-map") {
      window.history.replaceState({}, "", `#${finalLat},${finalLng},${zoom}`);
      dispatch({
        type: "UPDATE_MAP_CENTER",
        payload: {
          lat: finalLat,
          lng: finalLng
        }
      });
    } else {
      dispatch({
        type: "UPDATE_MAP_CENTER",
        payload: {
          lat: finalLat,
          lng: finalLng
        }
      });
    }
  }, [finalLat, finalLng, zoom]);

  const mapCenter = lat + "," + lng;

  const [isCopied, setCopied] = useClipboard(mapCenter, {
    successDuration: 3000
  });

  return (
    <Fragment>
      <Tooltip title={isCopied ? "Coordinates copied to clipboard" : "Click to copy coordinates"}>
        <Button
          variant="outlined"
          size="small"
          onClick={setCopied}
          startIcon={
            isCopied ? (
              <CheckCircleOutlineIcon className={`${classes.icon} ${classes.green}`} />
            ) : (
              <ShareIcon className={classes.icon} />
            )
          }
          classes={{
            root: classes.root,
            label: classes.label
          }}
        >
          {isCopied ? "Copied" : mapCenter}
        </Button>
      </Tooltip>
    </Fragment>
  );
}

export default Coordinates;
