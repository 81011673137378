import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { getFirebase } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";
import FileUploader from "react-firebase-file-uploader";
import { Detector } from "react-detect-offline";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RenderFormSection, StyledProgress } from "@austere-monorepo/components";

// Icons
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "250px",
    width: "250px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    border: `1px solid ${theme.palette.divider}`
  },
  boxOuter: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  leftBox: {
    display: "flex",
    width: "250px",
    margin: theme.spacing(0, 4, 0, 0),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
      margin: theme.spacing(0, 0, 2, 0)
    }
  },
  rightBox: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    // margin: theme.spacing(2, 0)
    // maxWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  saveButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    // margin: theme.spacing(2, 0)
    // maxWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

function FormAvatar(props) {
  const classes = useStyles();
  const { heading, subHeading } = props;
  const formName = props.form;
  const currentImage = useSelector((state) => state.form[formName].values && state.form[formName].values.avatar);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();

  const firebase = getFirebase();

  // Get the UID of the user uploading
  const uid = useSelector((state) => state.firebase && state.firebase.auth.uid);
  const storageRef = firebase.storage().ref(uid);

  const handleUploadStart = () => {
    setUploading(true);
    setProgress(0);
  };

  const handleProgress = (progress) => setProgress(progress);

  const handleUploadError = (error) => {
    setUploading(false);
    console.error(error);
  };

  async function handleImageDelete(currentImage) {
    if (currentImage) {
      setDeleting(true);
      var storageRef = firebase.storage();
      const fileRef = storageRef.refFromURL(currentImage);
      try {
        await fileRef.getDownloadURL();
        fileRef.delete().then(function () {
          setDeleting(false);
        });
      } catch (error) {
        setDeleting(false);
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: error,
            options: {
              variant: "error"
            }
          }
        });
      }
    }
  }

  return (
    <Fragment>
      <RenderFormSection
        heading={heading ? heading : "Profile images"}
        id={heading ? heading : "profile-image"}
        // subHeading={subHeading ? subHeading : "Upload a profile image"}
      >
        <Detector
          render={({ online }) => (
            <Fragment>
              {online ? (
                <Fragment>
                  {!currentImage && (
                    <Grid item xs={12}>
                      <FileUploader
                        // hidden
                        accept="image/jpg"
                        randomizeFilename
                        maxWidth={300}
                        maxHeight={300}
                        storageRef={storageRef}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={(filename) => {
                          setProgress(100);
                          setUploading(false);
                          if (firebase) {
                            firebase
                              .storage()
                              .ref(uid)
                              .child(filename)
                              .getDownloadURL()
                              .then((url) => dispatch(change(formName, "avatar", url)))
                              // .then(() => dispatch(submit("userForm")))
                              .catch(function (error) {
                                console.log(error);
                              });
                          }
                        }}
                        onProgress={handleProgress}
                        // metadata={{ cacheControl: "max-age=3600" }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box className={classes.boxOuter}>
                      {currentImage && (
                        <Box className={classes.leftBox}>
                          <Box style={{ backgroundImage: `url(${currentImage})` }} className={classes.image} />
                        </Box>
                      )}

                      <Box className={classes.rightBox}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                              {subHeading}
                            </Typography>
                          </Grid>

                          <Grid item md={12} xs={6}>
                            <Button
                              // fullWidth
                              className={classes.saveButton}
                              // variant="outlined"
                              startIcon={<SaveIcon />}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Grid>

                          {currentImage && (
                            <Grid item md={12} xs={6}>
                              <Button
                                // fullWidth
                                // variant="outlined"
                                className={classes.deleteButton}
                                startIcon={<DeleteIcon />}
                                onClick={async () => {
                                  await handleImageDelete(currentImage).then(() =>
                                    dispatch(change(formName, "avatar", null))
                                  );
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  {deleting && <StyledProgress message={"Deleting image"} />}
                  {uploading && <StyledProgress message={"Uploading image " + progress + "%"} />}
                </Fragment>
              ) : (
                <Grid item xs={12}>
                  Image upload is disabled offline. Connect to a network, or take photos with your device camera and add
                  to this form when you're back online.
                </Grid>
              )}
            </Fragment>
          )}
        />
      </RenderFormSection>
    </Fragment>
  );
}

FormAvatar.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string
};

export default FormAvatar;
