import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { IconButton, Tooltip, Box, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Create";
import DownloadIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortIcon from "@material-ui/icons/Sort";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useTheme } from "@material-ui/core/styles";
import {
  SortingState,
  SelectionState,
  FilteringState,
  GroupingState,
  SearchState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  DataTypeProvider
  // IntegratedSummary,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  // TableSummaryRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  TableColumnReordering,
  Toolbar,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility
} from "@devexpress/dx-react-grid-material-ui";
import { isLoaded, isEmpty } from "react-redux-firebase";

import { Plugin, Template, TemplatePlaceholder } from "@devexpress/dx-react-core";

import { AddButton, StyledProgress } from "@austere-monorepo/components";

const cellHeight = 46;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper
  },
  table: {
    padding: theme.spacing(0),
    margin: theme.spacing(0)
  },
  toolbar: {
    minHeight: cellHeight
    // "& .MuiButtonBase-root.Mui-disabled": {}
  },
  searchPanel: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.5, 1)
    },
    "&&&:before": {
      borderBottom: "none"
    },
    "&&&:after": {
      borderBottom: "none"
    }
  },
  icon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.75),
    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem"
    }
  },
  viewEditIcon: {
    margin: theme.spacing(0),
    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem"
    }
  },
  cell: {
    verticalAlign: "middle",
    padding: theme.spacing(0, 0.5),
    height: cellHeight
  },
  headerCell: {
    // hide the sort arrow
    "& .MuiButtonBase-root.Mui-disabled > svg": {
      display: "none"
    }
  },
  avatar: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    margin: theme.spacing(1),
    verticalAlign: "middle",
    border: "1px solid",
    borderColor: theme.palette.divider
  },
  avatarIcon: {
    height: "60px",
    width: "60px",
    margin: theme.spacing(1),
    verticalAlign: "middle",
    color: theme.palette.warning
  },
  link: {
    color: theme.palette.secondary.main
  }
}));

function StyledTable({
  rows,
  columns,
  sortingColumns,
  priorityWeights, // columns sorted but a virtual value
  tableColumns,
  message,
  htmlColumns,
  emailColumns,
  whatsAppColumns,
  dateTimeColumns,
  dateColumns,
  ageColumns,
  imageColumns,
  hiddenColumns,
  // serverDateColumns,
  // rowStyles,
  columnStyles,
  columnColors,
  groupedBy,
  expandedGroups,
  addButton,
  collection,
  doc
}) {
  const classes = useStyles();

  const theme = useTheme();

  const tableColumnExtensionsDefault = [
    { columnName: "edit", width: 75 },
    { columnName: "view", width: 75 }
  ];

  // const tableColumnExtensions = tableColumns
  //   ? tableColumns.concat(tableColumnExtensionsDefault)
  //   : tableColumnExtensionsDefault;

  const [tableColumnExtensions] = useState(
    tableColumns ? tableColumns.concat(tableColumnExtensionsDefault) : tableColumnExtensionsDefault
  );

  function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  function export_table_to_csv(html, filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(","));
    }
    download_csv(csv.join("\n"), filename);
  }

  // delete code

  const ExportCsvPlugin = () => (
    <Plugin name="exportCsv">
      <Template name="toolbarContent">
        <Tooltip title="Export as CSV">
          <IconButton
            className={classes.icon}
            onClick={function () {
              var html = document.querySelector("table").outerHTML;
              export_table_to_csv(html, collection + "_" + moment().format("YYYY_MM_DD") + ".csv");
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  const MessagePlugin = () => (
    <Plugin name="message">
      <Template name="toolbarContent">
        <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
          <Typography align="center" variant="button" color="secondary" noWrap>
            {message}
          </Typography>
        </Box>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  const [showFilter, changeFilter] = useState(false);
  const filterSwitch = () => changeFilter((showFilter) => !showFilter);
  const ExportFilterPlugin = () => (
    <Plugin name="showFilter">
      <Template name="toolbarContent">
        <Tooltip title="Show/hide filter">
          <IconButton
            className={classes.icon}
            color={showFilter ? "secondary" : "default"}
            onClick={filterSwitch}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  const [showGrouping, changeGrouping] = useState(false);
  const groupingSwitch = () => changeGrouping((showGrouping) => !showGrouping);
  const ExportGroupingPlugin = () => (
    <Plugin name="showGrouping">
      <Template name="toolbarContent">
        <Tooltip title="Show/hide grouping">
          <IconButton
            className={classes.icon}
            color={showGrouping ? "secondary" : "default"}
            onClick={groupingSwitch}
          >
            <SortIcon />
          </IconButton>
        </Tooltip>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  // const TitlePlugin = ({ title }) => (
  //   <Plugin name='title'>
  //     <Template name='toolbarContent'>
  //       <Box display='flex' justifyContent='center' flexGrow='1'>
  //         <Typography variant='button'>{title}</Typography>
  //       </Box>
  //       <TemplatePlaceholder />
  //     </Template>
  //   </Plugin>
  // );

  const ToolbarRoot = ({ ...restProps }) => (
    <Toolbar.Root className={classes.toolbar} {...restProps} />
  );

  const SearchPanelRoot = ({ ...restProps }) => (
    <SearchPanel.Input className={classes.searchPanel} {...restProps} />
  );

  const TableHeaderRoot = ({ ...restProps }) => (
    <TableHeaderRow.Cell className={`${classes.cell} ${classes.headerCell}`} {...restProps} />
  );

  const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

  const getRowId = (row) => row.id;

  const Cell = (props) => {
    if (props.column.name === "edit") {
      return (
        <VirtualTable.Cell className={classes.cell}>
          <IconButton
            className={classes.viewEditIcon}
            component={Link}
            to={`/${collection}/edit/${props.row.id}`}
            key={props.row.id}
          >
            <EditIcon />
          </IconButton>
        </VirtualTable.Cell>
      );
    }
    if (props.column.name === "view") {
      return (
        <VirtualTable.Cell className={classes.cell}>
          <IconButton
            className={classes.viewEditIcon}
            component={Link}
            to={`/${collection}/view/${props.row.id}`}
            key={props.row.id}
          >
            <SearchIcon />
          </IconButton>
        </VirtualTable.Cell>
      );
    }
    return <VirtualTable.Cell className={classes.cell} {...props} />;
  };

  // const ColorFormatter = ({ value }) => moment(value).format("DD-MMM-YY");
  // const ColorTypeProvider = (props) => (
  //   <ColorTypeProvider formatterComponent={DateFormatter} {...props} />
  // );

  const DateFormatter = ({ value }) => (value ? moment(value).format("DD-MMM-YY") : "-");
  const DateTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  const DateTimeFormatter = ({ value }) => (value ? moment(value).format("DD-MMM-YY hh:mm") : "-");
  const DateTimeTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateTimeFormatter} {...props} />
  );

  // const ServerDateFormatter = ({ value }) => new Date(value.toDate()).toDateString();
  // const ServerDateTypeProvider = (props) => (
  //   <DataTypeProvider formatterComponent={ServerDateFormatter} {...props} />
  // );

  const AgeFormatter = ({ value }) => `${moment().diff(value, "y")}yo`;
  const AgeTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={AgeFormatter} {...props} />
  );

  const EmailFormatter = ({ value }) => (
    <a className={classes.link} href={"mailto:" + value}>
      {value}
    </a>
  );
  const EmailTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={EmailFormatter} {...props} />
  );

  // Decorate WhatsApp columns with URL
  const WhatsappFormatter = ({ value }) => (
    <a className={classes.link} target="_blank" href={"https://wa.me/" + value}>
      {value}
    </a>
  );
  const WhatsappTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={WhatsappFormatter} {...props} />
  );

  const ImageFormatter = ({ value }) =>
    value ? (
      <img className={classes.avatar} src={value && value} />
    ) : (
      <AccountCircleIcon className={classes.avatarIcon} color="action" />
    );
  const ImageTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={ImageFormatter} {...props} />
  );

  const HtmlFormatter = ({ value }) => (value ? value.replace(new RegExp("<[^>]*>", "g"), "") : "");
  const HtmlTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={HtmlFormatter} {...props} />
  );

  // Decorate WhatsApp columns with URL
  const ColumnStyleFormatter = ({ value }) => (
    <span style={{ color: columnColors[value] }}>{value}</span>
  );
  const ColumnStyleTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={ColumnStyleFormatter} {...props} />
  );

  // https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/sorting/#custom-sorting-algorithm
  const comparePriority = (a, b) => {
    const priorityA = priorityWeights[a];
    const priorityB = priorityWeights[b];
    if (priorityA === priorityB) {
      return 0;
    }
    return priorityA < priorityB ? -1 : 1;
  };

  const [integratedSortingColumnExtensions] = useState(
    sortingColumns ? [{ columnName: sortingColumns[0].columnName, compare: comparePriority }] : []
  );

  // console.log(sortingColumns[0].columnName);

  const [defaultSorting] = useState(
    sortingColumns ? sortingColumns : [{ columnName: "created_at", direction: "desc" }]
  );

  const [sortingStateColumnExtensions] = useState([
    { columnName: "edit", sortingEnabled: false },
    { columnName: "view", sortingEnabled: false }
  ]);

  const [tableRowsData, setTableRowsData] = useState([]);
  const [tableColumnsData] = useState(columns);
  useEffect(() => {
    setTableRowsData(rows);
  }, [rows]);

  // console.log(rows);

  // console.log(tableRowsData);
  // console.log(tableRows);

  // const [tableRows] = useState(rows);

  const displayTable = (
    <Fragment>
      <Box className={classes.root}>
        <Grid
          className={classes.table}
          rows={tableRowsData}
          columns={tableColumnsData}
          getRowId={getRowId}
          rootComponent={Root}
        >
          {showGrouping && <DragDropProvider />}

          {dateColumns && <DateTypeProvider for={dateColumns} />}

          {dateTimeColumns && <DateTimeTypeProvider for={dateTimeColumns} />}

          {ageColumns && <AgeTypeProvider for={ageColumns} />}

          {emailColumns && <EmailTypeProvider for={emailColumns} />}

          {whatsAppColumns && <WhatsappTypeProvider for={whatsAppColumns} />}

          {htmlColumns && <HtmlTypeProvider for={htmlColumns} />}

          {columnStyles && <ColumnStyleTypeProvider for={columnStyles} />}

          {/* {serverDateColumns && <ServerDateTypeProvider for={serverDateColumns} />} */}

          {imageColumns && <ImageTypeProvider for={imageColumns} />}
          <FilteringState
            columnExtensions={[
              { columnName: "edit", filteringEnabled: false },
              { columnName: "view", filteringEnabled: false }
            ]}
          />
          <SearchState />
          <SortingState
            defaultSorting={defaultSorting}
            columnExtensions={sortingStateColumnExtensions}
            // defaultSorting={[{ columnName: "hazard_rating" }]}
          />
          <GroupingState grouping={groupedBy && groupedBy} defaultExpandedGroups={expandedGroups} />
          <SelectionState />

          <IntegratedFiltering />

          <IntegratedSorting
            columnExtensions={priorityWeights ? integratedSortingColumnExtensions : []}
          />

          <IntegratedGrouping />
          {/* <IntegratedSummary /> */}

          <VirtualTable
            height={`calc(100vh - (56px + ${cellHeight * 2}px)`}
            estimatedRowHeight={cellHeight}
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
            // rowComponent={TableRow}
          />
          {hiddenColumns && <TableColumnVisibility defaultHiddenColumnNames={hiddenColumns} />}
          <TableHeaderRow showSortingControls cellComponent={TableHeaderRoot} />
          <TableColumnReordering defaultOrder={columns.map((column) => column.name)} />
          {showFilter && <TableFilterRow showFilterSelector />}
          {/* <TableSelection showSelectAll /> */}
          <TableGroupRow />
          <Toolbar rootComponent={ToolbarRoot} />

          <SearchPanel inputComponent={SearchPanelRoot} />
          {message && <MessagePlugin />}
          {/* <Hidden only="sm"> */}
          {theme.breakpoints.up("md") && showGrouping && <GroupingPanel showSortingControls />}
          {/* {props.summaryMessages && <TableSummaryRow messages={props.summaryMessages} />} */}
          {/* {theme.breakpoints.up("md") && props.title && <TitlePlugin title={props.title} />} */}
          <ExportCsvPlugin />
          <ExportFilterPlugin />
          <ExportGroupingPlugin />

          {hiddenColumns && <ColumnChooser />}
        </Grid>
      </Box>

      {addButton && <AddButton collection={collection} doc={doc} />}
    </Fragment>
  );

  return tableRowsData ? displayTable : <StyledProgress />;
}

export default StyledTable;
