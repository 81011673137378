import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import { StyledWrapper, RenderTextField, validate, history } from "@austere-monorepo/components";
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";

function handleChangeName({ newName }) {
  const firebase = getFirebase();
  const firestore = getFirestore();
  return async (dispatch) => {
    try {
      await firebase.auth().currentUser.getIdToken(true);

      await firebase.auth().currentUser.updateProfile({
        displayName: newName
      });
      await firestore.update(
        { collection: "users", doc: firebase.auth().currentUser.uid },
        {
          displayName: newName
        }
      );
      await dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Name changed successfully`,
          options: {
            variant: "success"
          }
        }
      });
      await history.goBack();
    } catch (error) {
      console.log(error);
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Error changing name ` + error,
          options: {
            variant: "error"
          }
        }
      });
    }
  };
}

function EmailChangeForm({ handleSubmit, handleChangeName, pristine, submitting, error }) {
  const firebase = getFirebase();
  var user = firebase.auth().currentUser;
  return (
    <form onSubmit={handleSubmit(handleChangeName)}>
      <StyledWrapper maxWidth={"sm"}>
        <Card>
          <Typography style={{ marginBottom: "20px" }} variant="h2" align="center">
            Change Name
          </Typography>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography gutterBottom>Your current name is {user.displayName}</Typography>
            </Grid>

            <Field
              name="newName"
              label="New Name"
              type="text"
              placeholder="New display name"
              component={RenderTextField}
            />

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                label="register"
                type="submit"
                disabled={submitting || error || pristine}
              >
                Change Name
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                For security reasons, you may be directed to logout and log back in to
                re-authenticate before changing your name.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" component={Link} to={"/account"}>
                  Back to My Account
                </Typography>

                <Typography variant="body2" align="center">
                  <Link to={"/account"}>Log out</Link>
                </Typography>

                <Typography align="center" variant="body2">
                  <Link to="/help#report-issue">Report an Issue</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </StyledWrapper>
    </form>
  );
}

export default compose(
  connect(null, { handleChangeName }),
  reduxForm({
    form: "changeEmailForm",
    validate
  })
)(EmailChangeForm);
