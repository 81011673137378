import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import UpdateForm from "./UpdateForm";
import { handleFormAdd, SendButton, fastSort } from "@austere-monorepo/components";

function AddUpdate(props) {
  const { handleFormAdd, handleSubmit, _auth } = props;
  const onSubmit = (formProps) => {
    handleFormAdd({
      formCollection: "updates",
      formData: formProps,
      auth: _auth,
      redirect: "/management"
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={props.form}>
      <UpdateForm
        {...props}
        actionItems={
          <Fragment>
            <SendButton {...props} />
          </Fragment>
        }
      />
    </form>
  );
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => {
  return {
    _auth: auth,
    initialValues: {
      poimena_status:
        ordered.updates && fastSort(ordered.updates || [], "created_at", "desc")[0].poimena_status,
      stacked_loop_status:
        ordered.updates &&
        fastSort(ordered.updates || [], "created_at", "desc")[0].stacked_loop_status,
      dreaming_pools_status:
        ordered.updates &&
        fastSort(ordered.updates || [], "created_at", "desc")[0].dreaming_pools_status
    }
  };
};

export default compose(
  connect(mapStateToProps, { handleFormAdd }),
  reduxForm({
    form: "updateForm",
    enableReinitialize: true
  })
)(AddUpdate);
