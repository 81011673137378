import React, { useEffect, useState, Fragment } from "react";
import {
  StyledSideView,
  StyledWrapper,
  FormBlocker,
  getHeading
} from "@austere-monorepo/components";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { isDirty } from "redux-form";
import { connect } from "react-redux";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2, 3)
  }
}));

function FormWrapper(props) {
  const { headings, children, title, actionItems, _dirty, _submitted } = props;

  const classes = useStyles();

  const [activeHeading, setActiveHeading] = useState(null);

  useEffect(() => {
    if (activeHeading !== null) {
      const heading = document.getElementById(activeHeading);
      if (heading) {
        heading.scrollIntoView();
      } else null;
    }
  }, [activeHeading]);

  const [isBlocked, setIsBlocked] = useState(false);

  // console.log("submitted=", _submitted);
  // console.log("dirty=", _dirty);
  // console.log("blocked", isBlocked);

  // const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (_dirty && !_submitted) {
      setIsBlocked(true);
    } else setIsBlocked(false);
  }, [_dirty, _submitted]);

  // Prevent user from navigating away from a dirty form
  // https://reactrouter.com/web/example/preventing-transitions

  // let [isDirty, setIsDirty] = useState(false);

  // const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   console.log("fired");
  //   e.preventDefault();
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const history = useHistory();

  // useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (_dirty) {
  //       return window.confirm("Navigate Back?");
  //     }
  //     return true;
  //   });

  //   return () => {
  //     unblock();
  //   };
  // }, []);

  return (
    <Fragment>
      {!_submitted && <FormBlocker isBlocked={isBlocked} />}
      <StyledSideView
        actionItems={actionItems}
        title={title}
        sidebar={
          headings ? (
            <List dense disablePadding>
              {headings.map((heading, i) => (
                <ListItem
                  key={i}
                  button
                  className={classes.doc}
                  selected={getHeading(heading) === activeHeading}
                  onClick={() => setActiveHeading(getHeading(heading))}
                >
                  <ListItemText primary={heading} />
                </ListItem>
              ))}
            </List>
          ) : null
        }
        content={
          <StyledWrapper>
            <Grid container spacing={2}>
              {children}
            </Grid>
          </StyledWrapper>
        }
        // {...props}
      />
    </Fragment>
  );
}

FormWrapper.propTypes = {
  title: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  // console.log(ownProps);
  return {
    _dirty: isDirty(ownProps.form)(state),
    // _submitting: ownProps.submitting,
    _submitted: ownProps.submitSucceeded
  };
};

export default connect(mapStateToProps, null)(FormWrapper);
