const initState = {
  update: false,
  install: false,
  downloadMap: false
};

function prompt(state = initState, action) {
  switch (action.type) {
    case "UPDATE_UPDATE_AVAILABLE":
      return {
        ...state,
        update: action.payload.update
      };
    case "UPDATE_INSTALL_APP":
      return {
        ...state,
        install: action.payload.install
      };
    case "UPDATE_DOWNLOAD_MAP":
      return {
        ...state,
        downloadMap: action.payload.downloadMap
      };
    default:
      return state;
  }
}

export default prompt;
