import React, { Fragment } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";

function VersionBlock({ createdByName, createdAt, updatedByName, updatedAt }) {
  return (
    <Fragment>
      <Typography variant="caption" color="textSecondary">
        Created By:
      </Typography>
      <Typography gutterBottom>
        {createdByName} {moment(createdAt).format("DD-MMM-YYYY HH:mm")}
      </Typography>
      {updatedByName && (
        <Fragment>
          <Typography variant="caption" color="textSecondary">
            Updated By:
          </Typography>
          <Typography>
            {updatedByName} {moment(updatedAt).format("DD-MMM-YYYY HH:mm")}
          </Typography>
        </Fragment>
      )}
    </Fragment>
  );
}

export default VersionBlock;
