import { useSelector } from "react-redux";
import HazardListTable from "./HazardListTable";
import { StyledTabs } from "@austere-monorepo/components";

function HazardsPage() {
  const auth = useSelector((state) => state.firebase.auth);

  const _hazards = useSelector(
    (state) => state.firestore.ordered.hazards && state.firestore.ordered.hazards
  );

  const hazardNotComplete = _hazards && _hazards.filter((hazard) => hazard.status === "Unresolved");

  const hazardsMy = _hazards && _hazards.filter((hazard) => hazard.assigned === `${auth.uid}`);

  // console.log(_hazards);

  return (
    <StyledTabs
      base={"actions"}
      tabs={[
        {
          label: "Outstanding Actions",
          path: "outstanding-actions",
          content: <HazardListTable _hazards={hazardNotComplete} />,
          role: 2
        },
        {
          label: "All Actions",
          path: "all-actions",
          content: <HazardListTable _hazards={_hazards} />,
          role: 2
        },
        {
          label: "My Actions",
          path: "my-actions",
          content: <HazardListTable _hazards={hazardsMy} />
        }
      ]}
    />
  );
}

export default HazardsPage;
