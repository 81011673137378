import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StyledProgress } from "@austere-monorepo/components";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    border: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(1.5),
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cell: {
    verticalAlign: "top",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  leftCell: {
    flex: "0 0 150px",
    // borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      borderBottom: "none",
    },
  },
  rightCell: {
    flex: 1,
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  heading: {
    fontWeight: 500,
    // fontSize: "1rem",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "1.1rem",
    // },
  },
}));

function StyledBasicTable(props) {
  const { rows } = props;
  const classes = useStyles();
  return (
    <Fragment>
      {rows &&
        rows.map((row, i) => (
          <Box className={classes.row} key={i}>
            <Box id={row.heading.toLowerCase()} className={`${classes.cell} ${classes.leftCell}`}>
              <Typography className={classes.heading} variant='body1'>
                {row.heading}
              </Typography>
            </Box>
            <Box className={`${classes.cell} ${classes.rightCell}`}>
              <Typography style={{ color: row.color }}>{row.value}</Typography>
            </Box>
          </Box>
        ))}
      {!rows && <StyledProgress />}
    </Fragment>
  );
}

export default StyledBasicTable;
