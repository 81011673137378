export function Access(feature, layer) {
  layer.bindPopup(
    "<div>" +
      "<div>" +
      feature.properties.category +
      "</div>" +
      "<div>" +
      "<div>Type: " +
      feature.properties.type +
      "</div>" +
      "<div>Description: " +
      feature.properties.description +
      "</div>" +
      "</div>"
  );
}
