export function General(feature, layer) {
  layer.bindPopup(
    "<div>" +
      "<div>" +
      feature.properties.category +
      "</div>" +
      "<div>" +
      "<div>" +
      feature.properties.description +
      "</div>" +
      "</div>"
  );
}
