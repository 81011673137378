import React from "react";
import { Redirect } from "react-router-dom";
import {
  PublicRoute,
  PrivateRoute,
  LoginForm,
  RegisterForm,
  NotFound,
  AccessDenied,
  Help,
  EmailChangeForm,
  PasswordResetForm,
  NameChangeForm,
  DeleteOwnAccount,
  AddUserForm
} from "@austere-monorepo/components";

import Home from "client/home/Home";
import Map from "client/map/Map";
import { Link } from "react-router-dom";

// Icons
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import InfoIcon from "@material-ui/icons/Info";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

// Handbook
import Handbook from "client/handbook/Handbook";

// User
import MyAccount from "client/user/MyAccount";

// Hazards
import AddHazard from "client/hazards/AddHazard";
import EditHazard from "client/hazards/EditHazard";
import ViewHazard from "client/hazards/ViewHazard";
import HazardsPage from "client/hazards/HazardsPage";

// Updates
import AddUpdate from "client/updates/AddUpdate";
import ViewUpdate from "client/updates/ViewUpdate";

// Management
import Management from "client/management/Management";
import EditUser from "./client/management/EditUser";

export const items = [
  {
    type: PublicRoute,
    path: "/404",
    component: NotFound
  },
  {
    type: PublicRoute,
    path: "/access-denied",
    component: AccessDenied
  },
  {
    type: PublicRoute,
    path: "/login",
    component: LoginForm,
    options: {
      message: <Link to={"/information/request-account"}>Click here to request an account</Link>
    }
  },
  {
    type: PublicRoute,
    path: "/register",
    component: RegisterForm
  },
  {
    type: PublicRoute,
    path: "/account/:tab?",
    component: MyAccount
  },
  {
    type: PublicRoute,
    path: "/help",
    component: Help
  },
  {
    type: PrivateRoute,
    path: "/change-email",
    component: EmailChangeForm
  },
  {
    type: PrivateRoute,
    path: "/change-name",
    component: NameChangeForm
  },
  {
    type: PublicRoute,
    path: "/reset-password",
    component: PasswordResetForm
  },
  {
    type: PrivateRoute,
    role: 2,
    path: "/users/add",
    component: AddUserForm
  },
  {
    type: PrivateRoute,
    path: "/delete-account",
    component: DeleteOwnAccount
  },
  {
    type: PublicRoute,
    path: "/",
    component: Home,
    name: "Home",
    icon: <HomeIcon />
  },
  {
    type: PublicRoute,
    path: "/map",
    component: Map,
    name: "Map",
    icon: <MapIcon />,
    options: {
      id: "main-map"
    }
  },
  { type: PublicRoute, path: "/information/:path", component: Handbook },
  {
    type: PublicRoute,
    name: "Information",
    path: "/information",
    component: () => <Redirect from="/information" to="/information/about" />,
    icon: <InfoIcon />
  },

  {
    type: PrivateRoute,
    role: 1,
    path: "/actions/add",
    component: AddHazard
  },
  {
    type: PrivateRoute,
    role: 1,
    path: "/actions/edit/:id",
    component: EditHazard
  },
  {
    type: PrivateRoute,
    role: 1,
    path: "/actions/view/:id",
    component: ViewHazard
  },
  {
    type: PrivateRoute,
    path: "/actions/:tab?",
    component: HazardsPage,
    name: "Actions",
    icon: <ReportProblemOutlinedIcon />,
    role: 1
  },
  {
    type: PrivateRoute,
    role: 2,
    path: "/updates/add",
    component: AddUpdate
  },
  {
    type: PrivateRoute,
    role: 2,
    path: "/updates/view/:id",
    component: ViewUpdate
  },
  {
    type: PrivateRoute,
    role: 2,
    path: "/users/edit/:id",
    component: EditUser
  },
  {
    type: PrivateRoute,
    name: "Management",
    role: 2,
    path: "/management/:tab?",
    component: Management,
    icon: <SupervisedUserCircleIcon />
  },
  { component: () => <Redirect to="/404" /> }
];

// Filter routes to only include items with name for the sidebar
const filteredSidebarItems = items && items.filter((route) => route.name);

// Export routes to sidebar, remove unused items
export const sidebarItems =
  filteredSidebarItems &&
  filteredSidebarItems.map(({ component, type, ...keepAttrs }) => keepAttrs);
