import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, Select, MenuItem } from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    // backgroundColor: theme.palette.background.default,
  }
}));

export function RenderSelectPatientField({
  input,
  label,
  name,
  type,
  helper,
  md,
  xs,
  multiline,
  items,
  required,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  return (
    <FieldWrapper
      label={label}
      required={required}
      name={name}
      helper={helper}
      md={md}
      xs={xs}
    >
      <Select
        className={classes.field}
        variant="outlined"
        {...input}
        {...rest}
        name={name}
        inputProps={{
          name: `${name}`,
          id: `${name}`
        }}
      >
        {items &&
          items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description + " (" + item.assigned_name + ")"}
            </MenuItem>
          ))}
      </Select>

      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
