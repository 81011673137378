import React, { Fragment } from "react";
import { Tooltip, IconButton, Hidden } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import { useDispatch } from "react-redux";
import { StyledListLink } from "@austere-monorepo/components";

function CopyClipboard() {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "",
          text: "",
          url: window.location.hash
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard.writeText(window.location.href).then(
        function () {
          dispatch({
            type: "ENQUEUE_SNACKBAR",
            notification: {
              key: new Date().getTime() + Math.random(),
              message: `Current page URL copied to clipboard`,
              options: {
                variant: "success"
              }
            }
          });
        },
        function (error) {
          dispatch({
            type: "ENQUEUE_SNACKBAR",
            notification: {
              key: new Date().getTime() + Math.random(),
              message: `Error copying current page URL: ${error}`,
              options: {
                variant: "error"
              }
            }
          });
        }
      );
    }
  };

  return (
    <Fragment>
      <Hidden mdUp implementation="css">
        <StyledListLink icon={<ShareIcon />} primary={"Share View"} onClick={handleClick} />
      </Hidden>
      <Hidden smDown implementation="css">
        <Tooltip title={"Click to share current view"}>
          <IconButton color="inherit" onClick={handleClick}>
            <ShareIcon />
          </IconButton>
        </Tooltip>
      </Hidden>
    </Fragment>
  );
}

export default CopyClipboard;
