import React from "react";
import { Link } from "react-router-dom";
import HazardList from "../dashboard/HazardList";
import TodayList from "./TodayList";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import ClientLogo from "../../assets/client-logo.png";
import HazardIcon from "@material-ui/icons/ReportProblem";
import QuickButtons from "./QuickButtons";
import StayingSafe from "./StayingSafe";
import Connect from "./Connect";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundImage: `url(${backgroundImage})`,
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    minHeight: "calc(100vh - 56px)"
  }
}));

function Home() {
  const classes = useStyles();
  const role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center", padding: "10px 10px 0 10px" }}>
                <img width="250px" src={ClientLogo} alt="logo" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <QuickButtons />
            </Grid>

            <Grid item xs={12}>
              <TodayList />
            </Grid>
          </Grid>
        </Grid>

        {/* Right column */}

        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            {role && role.role === 4 && (
              <Grid item xs={12}>
                <Link to="/tasks">
                  <Typography variant="h6" color="primary" gutterBottom>
                    <HazardIcon />
                    MY ACTIONS
                  </Typography>
                </Link>
                <HazardList />
              </Grid>
            )}

            <Grid item xs={12}>
              <StayingSafe />
            </Grid>
            <Grid item xs={12}>
              <Connect />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
