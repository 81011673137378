export const fastSort = (list, sortBy = 'id', orderBy = 'asc') => {
    function mergeSort(arr) {
      if (arr.length <= 1) {
        return arr
      }
  
      const center = Math.floor(arr.length / 2)
      const left = arr.slice(0, center)
      const right = arr.slice(center)
  
      return merge(mergeSort(left), mergeSort(right))
    }
  
    function merge(left, right) {
      const results = []
  
      while (left.length && right.length) {
        if (left[0][sortBy] < right[0][sortBy]) {
          if (orderBy === 'desc') {
            results.push(right.shift())
          } else {
            results.push(left.shift())
          }
        } else {
          if (orderBy === 'desc') {
            results.push(left.shift())
          } else {
            results.push(right.shift())
          }
        }
      }
      return [...results, ...left, ...right]
    }
    return mergeSort(list)
  }
  