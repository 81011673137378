import React, { useMemo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { MapComponent } from "@austere-monorepo/components";

// Offline tiles
import bayOfFiresTiles from "./tiles/bay-of-fires.json";
import stHelensTiles from "./tiles/st-helens";

// Layers
import trailsJSON from "./geojson/trails.json";
import accessJSON from "./geojson/access.json";
import featuresJSON from "./geojson/features.json";
import hazardsJSON from "./geojson/hazards.json";

// Popups
import { EmergencyMarker } from "./popups/EmergencyMarker";
import { Trails } from "./popups/Trails";
import { Access } from "./popups/Access";
import { General } from "./popups/General";

export const offlineLayers = [
  {
    title: "Bay of Fires",
    url: "/bay-of-fires/{z}/{x}/{y}.png",
    cacheName: "bay-of-fires",
    tiles: bayOfFiresTiles,
    cacheLength: 843,
    maxZoom: 15,
    minZoom: 12,
    bounds: [
      [-41.31866375942859, 147.80481583904478],
      [-41.10429512513787, 148.39533097576353]
    ]
  },
  // {"_southWest":{"lat":-41.31866375942859,"lng":147.80481583904478},"_northEast":{"lat":-41.10429512513787,"lng":148.39533097576353}}
  {
    title: "St Helens",
    url: "/st-helens/{z}/{x}/{y}.png",
    cacheName: "st-helens",
    tiles: stHelensTiles,
    cacheLength: 137,
    maxZoom: 15,
    minZoom: 12,
    bounds: [
      [-41.384509443742374, 148.15728142100855],
      [-41.33103722617348, 148.2706637604861]
    ]
  }
];

function Map({ _actions, _auth, id }) {
  // console.log(bayOfFires.length);
  // console.log(stHelens.length);

  const actionsFiltered = useMemo(
    () => _actions && _actions.filter((action) => action.latitude && action.longitude),
    [_actions]
  );

  // console.log(_actions);
  // console.log(actionsFiltered);

  const actionsMy =
    actionsFiltered && actionsFiltered.filter((action) => action.assigned === _auth.uid);

  const actionsUnresolved =
    actionsFiltered && actionsFiltered.filter((action) => action.status === "Unresolved");

  const actionsResolved =
    actionsFiltered && actionsFiltered.filter((action) => action.status === "Resolved");

  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  const baseURL = "https://api.mapbox.com/styles/v1/austere-rm/";
  const endURL = "/tiles/256/{z}/{x}/{y}?access_token=";
  const baseLayers = [
    {
      title: "Topographic",
      url: baseURL + "ck8jl2q820ww01imhc46advjb" + endURL + token,
      maxZoom: 20,
      minZoom: 3,
      bounds: [
        [-90, -180],
        [90, 180]
      ]
    },
    {
      title: "Satellite",
      url: baseURL + "ck8jl922y08nn1ioc2f5g22m0" + endURL + token,
      maxZoom: 20,
      minZoom: 3,
      bounds: [
        [-90, -180],
        [90, 180]
      ]
    }
  ];

  const dataLayers = [
    {
      title: "My Actions",
      data: actionsMy,
      collection: "actions",
      category: "Action",
      role: 1
    },
    {
      title: "Actions",
      data: actionsUnresolved,
      collection: "actions",
      category: "Action",
      role: 2
    },
    {
      title: "Actions (resolved)",
      data: actionsResolved,
      collection: "actions",
      category: "Action Complete",
      role: 2
    }
  ];
  const geoJsonLayers = [
    {
      title: "Trails",
      data: trailsJSON,
      popup: Trails
    },
    {
      title: "Features",
      data: featuresJSON,
      popup: EmergencyMarker
    },
    {
      title: "Hazards",
      data: hazardsJSON,
      popup: General
    },
    {
      title: "Access",
      data: accessJSON,
      popup: Access
    }
  ];

  return (
    <MapComponent
      id={id}
      baseLayers={baseLayers}
      dataLayers={dataLayers}
      geoJsonLayers={geoJsonLayers}
      offlineLayers={offlineLayers}
      options={[]}
    />
  );
}

const mapStateToProps = ({ firestore: { ordered }, firebase: { auth } }) => ({
  _auth: auth,
  _actions: ordered.hazards && ordered.hazards
});

export default compose(connect(mapStateToProps))(Map);
