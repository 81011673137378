// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
// import "firebase/app-check";
import "firebase/functions";

// Store
import { rootReducer } from "../reducers";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
// import { actionTypes } from "redux-firestore";
import { reduxFirestore, getFirestore, createFirestoreInstance } from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"; // live
// import { composeWithDevTools } from "redux-devtools-extension"; // testing
import { getFirebase } from "react-redux-firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Object.defineProperty(window.navigator, "onLine", {
//   configurable: true,
//   value: false,
//   writable: true // defaults to false
// });

// Init firebase instance
firebase.initializeApp(firebaseConfig);

// Object.defineProperty(window.navigator, "onLine", {
//   configurable: true,
//   value: true,
//   writable: true // defaults to false
// });

// Init firebase auth
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// Init firestore
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence({ synchronizeTabs: true });

// Init functions
firebase.functions();

// Storage Ref
export const storageRef = firebase.storage().ref();

// Create initial state
const initialState = {};

// Create middleware
const middleware = [
  thunk.withExtraArgument({ getFirebase, getFirestore })
  // This is where you add other middleware like redux-observable
];

// Create the store
function createReduxStore() {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware), reduxFirestore(firebase, firebaseConfig))
  );
}

const store = createReduxStore();
export default store;

// react-redux-firebase config
// https://react-redux-firebase.com/docs/recipes/profile.html#profile-in-firestore
const rrfConfig = {
  userProfile: null
  // userProfile: "users",
  // useFirestoreForProfile: true,
  // onAuthStateChanged: (authData, firebase, dispatch) => {
  //   // Clear redux-firestore state if auth does not exist (i.e logout)
  //   if (!auth) {
  //     dispatch({ type: actionTypes.CLEAR_DATA });
  //   }
  // }
};

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

// Init app check https://firebase.google.com/docs/app-check/web/recaptcha-provider?authuser=0#web-version-9
// const appCheck = firebase.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//   process.env.REACT_APP_APPCHECK_SITE_KEY,
//   // Optional argument. If true, the SDK automatically refreshes App Check tokens as needed.
//   true
// );

// Init analytics last
firebase.analytics();

// https://developers.google.com/analytics/devguides/collection/ga4/display-features
// gtag("set", "allow_google_signals", false);
// gtag("set", "allow_ad_personalization_signals", false);
// gtag("set", "send_page_view", false);
