import {
  defaultLat,
  defaultLng,
  defaultZoom,
  defaultMapBase,
  defaultMapBounds,
  defaultMapData,
  defaultMaxZoom,
  defaultMinZoom
} from "config";

const initState = {
  lat: defaultLat,
  lng: defaultLng,
  accuracy: null,
  zoom: defaultZoom,
  base: defaultMapBase,
  data: defaultMapData,
  bounds: defaultMapBounds,
  maxZoom: defaultMaxZoom,
  minZoom: defaultMinZoom
};

function mapState(state = initState, action) {
  switch (action.type) {
    case "UPDATE_MAP_CENTER":
      return {
        ...state,
        lat: action.payload.lat,
        lng: action.payload.lng
      };
    case "UPDATE_MAP_ZOOM":
      return {
        ...state,
        zoom: action.payload.zoom
      };
    case "UPDATE_MAP_BASE":
      return {
        ...state,
        base: action.payload.base
      };
    case "UPDATE_DATA_LAYERS":
      return {
        ...state,
        data: action.payload.data
      };
    case "UPDATE_MAP_ACCURACY":
      return {
        ...state,
        accuracy: action.payload.accuracy
      };
    case "UPDATE_MAP_BOUNDS":
      return {
        ...state,
        bounds: action.payload.bounds
      };
    case "UPDATE_MAX_ZOOM":
      return {
        ...state,
        maxZoom: action.payload.maxZoom
      };
    case "UPDATE_MIN_ZOOM":
      return {
        ...state,
        minZoom: action.payload.minZoom
      };
    default:
      return state;
  }
}

export default mapState;
