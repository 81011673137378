import React, { Fragment } from "react";
import UpdateIcon from "@material-ui/icons/SyncSharp";
import { Tooltip, IconButton, Hidden } from "@material-ui/core";
import { StyledListLink } from "@austere-monorepo/components";
import { useDispatch } from "react-redux";

function RefreshButton() {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Reloading...",
        options: {
          variant: "info"
        }
      }
    });
    window.location.reload();
  };
  return (
    <Fragment>
      <Hidden mdUp implementation="css">
        <StyledListLink icon={<UpdateIcon />} primary={"Reload App"} onClick={handleClick} />
      </Hidden>
      <Hidden smDown implementation="css">
        <Tooltip title="Reload page">
          <IconButton color="inherit" onClick={handleClick}>
            <UpdateIcon />
          </IconButton>
        </Tooltip>
      </Hidden>
    </Fragment>
  );
}

export default RefreshButton;
