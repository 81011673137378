import React from "react";
import { StyledTabs, UserAccount } from "@austere-monorepo/components";
import UserSettings from "./UserSettings";
import UserProfile from "./MyProfile";

function MyAccount() {
  return (
    <StyledTabs
      base={"account"}
      tabs={[
        {
          label: "details",
          path: "details",
          content: <UserAccount deleteAccount />,
          role: 1
        },
        {
          label: "profile",
          path: "profile",
          content: <UserProfile />,
          role: 1
        },
        {
          label: "Settings",
          path: "settings",
          content: <UserSettings />
        }
      ]}
    />
  );
}

export default MyAccount;
