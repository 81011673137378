import PropTypes from "prop-types";
import { Grid, Card, CardHeader, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { getHeading } from "@austere-monorepo/components";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3)
    }
  },
  action: {
    padding: theme.spacing(2, 0, 0, 0),
    justifyContent: "flex-end"
  }
}));

function RenderFormSection({ heading, subHeading, actions, children }) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        {/* {heading && ( */}
        <CardHeader
          id={getHeading(heading)}
          title={heading}
          subheader={subHeading}
          titleTypographyProps={{ component: "h2" }}
        />
        {/* )} */}
        <Grid container spacing={3} alignItems="stretch" height="100%">
          {children}
        </Grid>
        <CardActions className={classes.action}>{actions && actions}</CardActions>
      </Card>
    </Grid>
  );
}

RenderFormSection.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  divider: PropTypes.string
};

export default RenderFormSection;
