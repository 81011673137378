import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";

export const getRoleStatus = () => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  firebase.auth().onAuthStateChanged(function (user) {
    // console.log(user);
    if (user) {
      firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then((idTokenResult) => {
          // console.log(idTokenResult);
          if (idTokenResult) {
            if (idTokenResult.claims.stripeRole) {
              firestore.update(
                { collection: "users", doc: user.uid },
                {
                  role: parseInt(idTokenResult.claims.stripeRole)
                }
              );
            } else {
              firestore.update(
                { collection: "users", doc: user.uid },
                {
                  role: 1
                }
              );
            }
          } else {
            console.log("No claims");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("no user");
    }
  });
};
