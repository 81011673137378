import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment/moment";

// Styled
import {
  Input,
  FormLabel,
  FormHelperText,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Radio,
  Select,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%"
  },
  label: {
    marginBottom: "4px"
  }
}));

export function RenderTextArrayField({
  author,
  date,
  input,
  label,
  name,
  type,
  multiline,
  md,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={md ? md : 12}>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.label} htmlFor={name}>
          {author + " " + moment(date).format("DD-MMM-YYYY HH:mm")}
        </FormLabel>
        <Input
          style={{ marginTop: "0px" }}
          {...input}
          id={name}
          type={type}
          {...rest}
          multiline={multiline}
          rows={3}
        />
      </FormControl>
    </Grid>
  );
}

export function RenderRadioField({
  md,
  input,
  label,
  name,
  helper,
  items,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={md ? md : 12}>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <RadioGroup
          {...input}
          {...rest}
          aria-label={name}
          name={name}
          value={input.value}
          onChange={(event, value) => input.onChange(value)}
          row
        >
          {items &&
            items.map((item) => (
              <FormControlLabel
                key={item.val}
                value={item.val}
                control={<Radio />}
                label={item.label}
              />
            ))}
        </RadioGroup>
        <FormHelperText children={helper} />
      </FormControl>
    </Grid>
  );
}

export function RenderCheckbox({ input, label, md }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={md ? md : 12}>
      <FormControl className={classes.formControl}>
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              onChange={input.onChange}
            />
          }
          label={label}
        />
      </FormControl>
    </Grid>
  );
}

export function RenderCurrencySelectField({
  input,
  label,
  name,
  helper,
  items,
  md,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={md ? md : 12}>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.label} htmlFor={name}>
          {label}
        </FormLabel>
        <Select
          variant="outlined"
          {...input}
          {...rest}
          name={name}
          inputProps={{
            name: `${name}`,
            id: `${name}`
          }}
        >
          {items &&
            items.map((item) => (
              <MenuItem key={item.op_currency} value={item.op_currency}>
                {item.op_currency}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText children={helper} />
      </FormControl>
    </Grid>
  );
}

export function RenderTrainingSelectField({
  input,
  label,
  helper,
  name,
  items,
  md,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={md ? md : 12}>
      <FormControl variant="outlined" className={classes.formControl}>
        <FormLabel className={classes.label} htmlFor={name}>
          {label}
        </FormLabel>
        <Select
          {...input}
          {...rest}
          name={name}
          inputProps={{
            name: `${name}`,
            id: `${name}`
          }}
        >
          {items &&
            items.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText children={helper} />
      </FormControl>
    </Grid>
  );
}

export function RenderExpandingField(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
}
