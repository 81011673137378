// https://codepen.io/vgrem/pen/KKVbyJe

import React, { useEffect, useMemo } from "react";
import { MapContainer } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import Locate from "./Locate";
import Center from "./Center";
import Zoom from "./Zoom";
import Coordinates from "./Coordinates";
import Search from "./Search";
import Layers from "./Layers";
import ButtonBox from "./ButtonBox";
// import "./styles.css";
import { StyledProgress } from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";
import { mapStyles } from "./MapStyles";

function MapComponent({
  id,
  _bounds,
  _lat,
  _lng,
  _zoom,
  _initBaseLayer,
  _initDataLayers,
  _maxZoom,
  _minZoom,
  baseLayers,
  dataLayers,
  offlineLayers,
  imageBaseLayers,
  geoJsonLayers,
  options,
  ...mapProps
}) {
  const useStyles = makeStyles((theme) => mapStyles(theme));

  const classes = useStyles();
  // const theme = useTheme();
  // Remove unused nodes from DOM
  useEffect(() => {
    const removeElements = (elms) => elms.forEach((el) => el.remove());
    // console.log("rerender");
    return removeElements(
      document.querySelectorAll([
        ".leaflet-control-container",
        ".leaflet-pane .leaflet-shadow-pane"
      ])
    );
  });

  // const allLayers = [...baseLayers, ...offlineLayers];

  // console.log(allLayers);
  // console.log(_maxZoom);

  // console.log(dataLayers);

  const displayMap = useMemo(() => {
    // console.log("re-rendered map");
    // console.log(dataLayers);
    return (
      <MapContainer
        id={id}
        preferCanvas={true}
        renderer={L.canvas()}
        minZoom={_minZoom}
        maxZoom={_maxZoom}
        zoomControl={false}
        attributionControl={false}
        center={
          window.location.hash !== ""
            ? [
                window.location.hash.replace("#", "").split(",")[0],
                window.location.hash.replace("#", "").split(",")[1]
              ]
            : _lat && _lng
            ? [_lat, _lng]
            : [51.505, -0.09]
        }
        zoom={
          window.location.hash !== ""
            ? window.location.hash.replace("#", "").split(",")[2]
            : _zoom
            ? _zoom
            : 12
        }
        maxBoundsViscosity={1}
        maxBounds={_bounds}
        placeholder={<StyledProgress />}
        {...mapProps}
      >
        <Coordinates id={id} />
        <ButtonBox id={id}>
          <Layers
            baseLayers={baseLayers}
            offlineLayers={offlineLayers}
            imageBaseLayers={imageBaseLayers}
            dataLayers={dataLayers}
            geoJsonLayers={geoJsonLayers}
            // items={items}
            initBaseLayer={_initBaseLayer}
            initDataLayers={_initDataLayers}
            initBaseBounds={_bounds}
            initMaxZoom={_maxZoom}
            initMinZoom={_minZoom}
          />
          <Zoom />
          <Search options={options} />
          <Locate />
        </ButtonBox>

        <Center />

        {/* {props.children} */}
      </MapContainer>
    );
  }, [dataLayers, _maxZoom, _initBaseLayer, _initDataLayers]);

  return <div className={classes.root}>{displayMap}</div>;
}

function mapStateToProps(state) {
  return {
    _lat: state.mapState.lat,
    _lng: state.mapState.lng,
    _zoom: state.mapState.zoom,
    _bounds: state.mapState.bounds,
    _initBaseLayer: state.mapState.base,
    _initDataLayers: state.mapState.data,
    _maxZoom: state.mapState.maxZoom,
    _minZoom: state.mapState.minZoom
  };
}

export default connect(mapStateToProps)(MapComponent);
