import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { FieldArray } from "redux-form";
import { RenderTextField, RenderFormSection } from "@austere-monorepo/components";
import { Field } from "redux-form";
import AddIcon from "@material-ui/icons/Add";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

const renderNotes = (props) => {
  const classes = useStyles();
  const { fields, created_by_name } = props;
  return (
    <Fragment>
      {fields.map((note, index) => {
        const currentNote = fields.get(index);
        return (
          <Fragment key={index}>
            <Field
              name={`${note}.note`}
              type="text"
              component={RenderTextField}
              label={`${currentNote.created_by_name} at ${moment(currentNote.date).format(
                "DD-MMM-YYYY HH:mm"
              )}`}
              multiline
              actions={
                <Typography color="error" variant="caption" onClick={() => fields.remove(index)}>
                  Delete
                </Typography>
              }
            />
            {/* <Grid item>
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <DeleteButton onClick={() => fields.remove(index)} />
              </Box>
            </Grid> */}
          </Fragment>
        );
      })}
      <Grid item>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() =>
            fields.push({
              date: new Date().getTime(),
              created_by_name
            })
          }
        >
          Add Note
        </Button>
      </Grid>
    </Fragment>
  );
};

const FormNotes = (props) => {
  const _created_by_name = useSelector((state) => state.firebase.auth.displayName);
  return (
    <RenderFormSection
      heading={"Notes"}
      subHeading={props.info ? props.info : "Comments, changes and progress notes."}
    >
      <FieldArray name="notes" created_by_name={_created_by_name} component={renderNotes} />
    </RenderFormSection>
  );
};

export default FormNotes;
