import React, { Fragment } from "react";
import moment from "moment";
import { fastSort } from "@austere-monorepo/components";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, CardHeader, List, ListItemAvatar, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import CancelIcon from "@material-ui/icons/Cancel";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";

const useStyles = makeStyles((theme) => ({
  orange: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main
  },
  green: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main
  },
  red: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  }
}));

function TodayList(props) {
  const classes = useStyles();
  const { _updates } = props;
  const latestUpdate = fastSort((_updates && _updates) || [], "created_at", "desc")[0];

  const statusIcon = {
    Open: <CheckCircleIcon className={classes.green} />,
    "Partially Open": <WarningIcon className={classes.orange} />,
    Closed: <CancelIcon className={classes.red} />,
    Unknown: <HelpIcon />
  };

  return (
    <Card>
      <CardHeader avatar={<InfoIcon />} title={"Trails Information"} />

      <List
        subheader={
          latestUpdate
            ? `Trails status (updated ${
                latestUpdate && moment(latestUpdate.created_at).format("DD-MMM-YY HH:mm")
              })`
            : "Trail information unavailable"
        }
      >
        {latestUpdate && (
          <Fragment>
            <ListItem disableGutters>
              <ListItemAvatar>
                {statusIcon[latestUpdate.poimena_status ? latestUpdate.poimena_status : "Unknown"]}
              </ListItemAvatar>
              <ListItemText
                primary={`Bay of Fires`}
                secondary={`${
                  latestUpdate.poimena_status ? latestUpdate.poimena_status : "Unknown"
                } - ${latestUpdate.poimena_message ? latestUpdate.poimena_message : "No message"}`}
              />
            </ListItem>

            <ListItem disableGutters>
              <ListItemAvatar>
                {
                  statusIcon[
                    latestUpdate.stacked_loop_status ? latestUpdate.stacked_loop_status : "Unknown"
                  ]
                }
              </ListItemAvatar>
              <ListItemText
                primary={`St Helens`}
                secondary={`${
                  latestUpdate.stacked_loop_status ? latestUpdate.stacked_loop_status : "Unknown"
                } - ${
                  latestUpdate.stacked_loop_message
                    ? latestUpdate.stacked_loop_message
                    : "No message"
                }`}
              />
            </ListItem>

            <ListItem disableGutters>
              <ListItemAvatar>
                {
                  statusIcon[
                    latestUpdate.dreaming_pools_status
                      ? latestUpdate.dreaming_pools_status
                      : "Unknown"
                  ]
                }
              </ListItemAvatar>
              <ListItemText
                primary={`Dreaming Pools`}
                secondary={`${
                  latestUpdate.dreaming_pools_status
                    ? latestUpdate.dreaming_pools_status
                    : "Unknown"
                } - ${
                  latestUpdate.dreaming_pools_message
                    ? latestUpdate.dreaming_pools_message
                    : "No message"
                }`}
              />
            </ListItem>
          </Fragment>
        )}
      </List>

      <List subheader={"Contact information"}>
        <ListItem disableGutters>
          <ListItemAvatar>
            <ContactPhoneIcon />
          </ListItemAvatar>
          <ListItemText
            primary={"Break O'Day Council"}
            secondary={
              <Fragment>
                <div>Email: admin@bodc.tas.gov.au</div>
                <div>Phone: 63796 7900 (A/H: 6398 2281)</div>
              </Fragment>
            }
          />
        </ListItem>
      </List>
    </Card>
  );
}

const mapStateToProps = ({ firestore: { ordered } }) => ({
  _updates: ordered.updates && ordered.updates
});

export default compose(connect(mapStateToProps))(TodayList);
