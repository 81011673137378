import React, { useState, useEffect } from "react";
import { FieldWrapper } from "@austere-monorepo/components";
import { Avatar, FormHelperText, Box, OutlinedInput } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius
  },
  avatar: {
    height: "165px",
    width: "165px"
  },
  field: {
    // backgroundColor: theme.palette.background.default,
  },
  infoBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    height: "100%"
  },
  button: {
    display: "block",
    padding: theme.spacing(0),
    margin: theme.spacing(0.5, 0)
  },
  red: {
    color: "#FF0000"
  }
}));

export function RenderImageURLField({
  input,
  label,
  name,
  helper,
  required,
  meta: { touched, error, initial }
}) {
  const classes = useStyles();

  const [image, setImage] = useState(initial);

  useEffect(() => {
    setImage(input.value);
  }, [input.value]);

  // console.log(input.value);

  return (
    <FieldWrapper label={label} xs={12}>
      <Box className={classes.root}>
        <Avatar
          variant="square"
          className={classes.avatar}
          src={image}
          alt=""
        />
        <Box className={classes.infoBox}>
          <FormHelperText>{helper}</FormHelperText>
          <div style={{ height: "20px" }} />

          <FieldWrapper label={"URL"} name={name} xs={12}>
            <OutlinedInput
              className={classes.field}
              fullWidth
              {...input}
              id={name}
              type={"url"}
              required={required}
            />
            {touched && error && <FormHelperText error>{error}</FormHelperText>}
          </FieldWrapper>
        </Box>
      </Box>
    </FieldWrapper>
  );
}
