import React, { Suspense, Fragment, useEffect, useState, useMemo } from "react";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { DeleteButton, DownloadButton, RenderFormSection } from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";
import { Offline, Online } from "react-detect-offline";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  item: {
    margin: theme.spacing(1, 1.5)
  },
  itemInner: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1)
  },
  green: {
    color: theme.palette.success.main
  },
  message: {
    color: theme.palette.info.main
  }
}));

function GetMaps({ offlineLayers }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Set initial state
  const [state, setState] = useState(null);
  const [downloading, setDownloading] = useState(null);
  const [deleting, setDeleting] = useState(null);

  // console.log(downloading);
  // console.log(deleting);
  // console.log(state);

  // Remove unused items
  // const filteredOfflineLayers = offlineLayers.map(
  //   ({ bounds, tiles, maxNativeZoom, maxZoom, title, url, ...keepAttrs }) => keepAttrs
  // );

  const filteredOfflineLayers = useMemo(
    () =>
      offlineLayers.map(
        ({ bounds, tiles, maxNativeZoom, maxZoom, title, url, ...keepAttrs }) => keepAttrs
      ),
    []
  );

  const getCacheInitialLength = () => {
    filteredOfflineLayers.map(function (obj) {
      caches &&
        caches.open(obj.cacheName).then(function (cache) {
          cache
            .keys()
            .then(function (keys) {
              return Object.assign(obj, {
                downloadedLength: keys.length
              });
            })
            .then(() => setState([...filteredOfflineLayers]));
        });
    });
  };

  const updateCacheLength = (cacheName, i) => {
    caches &&
      caches.open(cacheName).then(function (cache) {
        cache.keys().then(function (keys) {
          return setState({
            ...state,
            [i]: {
              ...state[i],
              downloadedLength: keys.length
            }
          });
        });
      });
  };

  useEffect(() => getCacheInitialLength(), []);

  async function downloadCache(cacheName, tiles, index) {
    setDownloading(cacheName);
    const myCache = await caches.open(cacheName);
    try {
      for (var i = 0, len = tiles.length; i < len; i++) {
        // var a = 0;
        await myCache.add(tiles[i]).then(() => {
          if (i % 10 == 0) {
            // update every 10th download
            updateCacheLength(cacheName, index);
          } else null;
          // if ((a = 10)) {
          //   updateCacheLength(cacheName, index);
          //   a = 0;
          // } else null;
        });
      }

      // if (tiles.length < 1000) {
      //   await myCache.addAll(tiles);
      // } else {
      //   for (var i = 0, len = tiles.length; i < len; i++) {
      //     await myCache.add(tiles[i]);
      //   }
      // }
    } catch (err) {
      console.log(err);
      setDownloading(null);
    } finally {
      getCacheInitialLength();
      setDownloading(null);
    }
  }

  async function deleteCache(cacheName) {
    setDeleting(cacheName);
    await caches.delete(cacheName).catch((e) => {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Error: ${e}`,
          options: {
            variant: "error"
          }
        }
      });
    });
    setDeleting(null);
    getCacheInitialLength();
  }

  // useEffect(() => {
  //   if (downloading === {} || deleting === {}) {
  //     getCacheLength();
  //   }
  //   if (downloading !== {} || deleting !== {}) {
  //     const timer = setInterval(() => {
  //       getCacheLength();
  //       // console.log("log");
  //     }, 3000);
  //     return () => {
  //       clearInterval(timer);
  //       // getCacheLength();
  //     };
  //   } else getCacheLength();
  // }, [downloading, deleting]);

  if (offlineLayers.length === 0) {
    return (
      <Grid item xs={12}>
        <Typography gutterBottom>No offline maps available</Typography>
      </Grid>
    );
  }
  if (!state) {
    return (
      <Grid item xs={12}>
        <Typography gutterBottom>Loading available maps...</Typography>
      </Grid>
    );
  } else if (state) {
    // console.log(state);
    return (
      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom>
            The following offline maps are available for download.
          </Typography>
        </Grid>
        <Fragment>
          {state &&
            offlineLayers.map((item, i) => (
              <Grid key={i} item xs={12} className={classes.item}>
                <Grid
                  container
                  className={classes.itemInner}
                  // key={i}
                  spacing={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography style={{ fontWeight: 500 }}>{item.title}</Typography>
                    {/* {downloading && <LinearProgress variant="determinate" value={progress} />} */}
                  </Grid>
                  <Grid item component={Typography}>
                    {`Max. zoom ${item.maxZoom}`}
                  </Grid>
                  <Grid
                    item
                    component={Typography}
                    className={
                      state[i].downloadedLength === item.cacheLength ? classes.green : null
                    }
                  >
                    {`${
                      state[i].downloadedLength !== undefined ? state[i].downloadedLength : "?"
                    } / ${item.cacheLength} `}
                    tiles downloaded
                  </Grid>

                  <DownloadButton
                    disabled={
                      downloading !== null ||
                      deleting !== null ||
                      state[i].downloadedLength === item.cacheLength
                    }
                    onClick={() => downloadCache(item.cacheName, item.tiles, i)}
                  />

                  <DeleteButton
                    disabled={
                      downloading !== null ||
                      deleting !== null ||
                      state[i].downloadedLength === 0 ||
                      state[i].downloadedLength === undefined
                    }
                    action={() => deleteCache(item.cacheName)}
                  />
                  {downloading === item.cacheName && (
                    <Grid item xs={12}>
                      <Typography align="center" variant="body2" className={classes.message}>
                        <CircularProgress style={{ margin: "0px 10px -2px 0px" }} size={13} />{" "}
                        Downloading map...
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
        </Fragment>
      </Fragment>
    );
  }
}

function ManageMaps({ offlineLayers }) {
  return (
    <Fragment>
      <Online>
        <RenderFormSection heading="Manage Maps">
          <Suspense
            fallback={
              <Grid item xs={12}>
                <Typography gutterBottom>Loading available maps...</Typography>
              </Grid>
            }
          >
            <GetMaps offlineLayers={offlineLayers} />
          </Suspense>
        </RenderFormSection>
      </Online>
      <Offline>
        <RenderFormSection heading="Manage Maps">
          <Grid item xs={12}>
            <Alert severity="warning">
              Map downloads unavailable while offline. Connect to a network to download maps.
            </Alert>
          </Grid>
        </RenderFormSection>
      </Offline>
    </Fragment>
  );
}

export default ManageMaps;
