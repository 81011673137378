import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function ButtonBox({ children, id }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      top: "68px",
      right: "16px",
      zIndex: 400,
      position: id === "form-map" ? "initial" : "fixed",
      padding: id === "form-map" ? theme.spacing(1) : 0
    }
  }));

  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
}

export default ButtonBox;
