import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { RenderSelectField } from "@austere-monorepo/components";

function FormConditional({
  name1,
  name2,
  label1,
  label2,
  required1,
  required2,
  md1,
  md2,
  items,
  ...props
}) {
  const formName = props.form;

  const value1 = useSelector(
    (state) => state.form[formName].values && state.form[formName].values[name1]
  );

  // Memoize the first layer because it won't change
  const layer1 = useMemo(() => {
    const val = Object.keys(items).map((element) => {
      return {
        label: element.toString(),
        val: element.toString()
      };
    });
    return val;
  }, [items]);

  // console.log(value1);

  // console.log("layer 1", layer1);

  const layer2 = value1
    ? items[value1].map((element) => {
        return {
          label: element.toString(),
          val: element.toString()
        };
      })
    : null;

  // console.log("layer2", layer2);

  return (
    <Fragment>
      <Field
        name={name1}
        label={label1}
        required={required1}
        component={RenderSelectField}
        items={layer1}
        md={md1}
        {...props}
      />
      {value1 && (
        <Field
          name={name2}
          label={label2}
          required={required2}
          component={RenderSelectField}
          items={layer2}
          md={md2}
          {...props}
        />
      )}
    </Fragment>
  );
}

FormConditional.propTypes = {
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  required1: PropTypes.bool.isRequired,
  required2: PropTypes.bool.isRequired,
  md1: PropTypes.number,
  md2: PropTypes.number,
  items: PropTypes.object.isRequired
};

export default FormConditional;
