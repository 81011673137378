// https://github.com/m87wheeler/react-search-bar/blob/master/src/components/Item.js

import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Typography,
  OutlinedInput,
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  DialogContent,
  Button
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ReactMarkdown from "markdown-to-jsx";
import { SearchButton } from "@austere-monorepo/components";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "350px",
    maxHeight: "350px"
  },
  titleText: {
    display: "block",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500
  },
  resultText: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const Item = (props) => {
  const classes = useStyles();

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <ListItem button disableGutters component={Link} to={props.path} onClick={props.onClick}>
      <ListItemText
        disableTypography
        primary={
          <Typography
            className={classes.titleText}
            component="span"
            dangerouslySetInnerHTML={createMarkup(props.name)}
          />
        }
        secondary={
          <Typography component="span" className={classes.resultText}>
            <ReactMarkdown options={{ wrapper: Fragment }}>{props.content}</ReactMarkdown>
          </Typography>
        }
      />
    </ListItem>
  );
};

const Search = (props) => {
  const classes = useStyles();
  const { docs } = props;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);

  const newDocs = docs.map((doc) => doc.children).flat(1);

  useEffect(() => {
    setData(newDocs);
    setIsLoading(false);
  }, []);

  // Search And Highlight Function
  const handleInput = (e) => {
    let str = e.target.value.toLowerCase();
    setSearch(str);
    console.log(search);
    const newArr = data
      .filter(
        (item) =>
          item.name.toLowerCase().includes(str.toLowerCase()) ||
          item.content.toLowerCase().includes(str.toLowerCase())
      )
      .map((item) => {
        let newTitle = item.name.replace(
          new RegExp(str, "gi"),
          (match) => `<mark style="background: #2769AA; color: white;">${match}</mark>`
        );
        let newBody = item.content
          // .replace(/[^\w\s]/gi, "")
          .replace(/(\r\n|\n|\r|[^\w\s])/gi, " ")
          .slice(item.content.indexOf(str) - 75, item.content.indexOf(str) + 75)
          .replace(
            new RegExp(str, "gi"),
            (match) => `<mark style="background: #2769AA; color: white;">${match}</mark>`
          );
        return {
          ...item,
          name: newTitle,
          content: newBody
        };
      });
    setSearchData(newArr);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchData([]);
  };

  return (
    <Fragment>
      <SearchButton onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent>
          <OutlinedInput
            placeholder="Search"
            inputProps={{ "aria-label": "search", autoCapitalize: "none" }}
            fullWidth
            notched
            autoFocus
            onInput={(e) => handleInput(e)}
            startAdornment={<SearchIcon />}
          />
          {search.length > 0 && (
            <Typography variant="caption">
              {searchData.length === 1
                ? `${searchData.length} result`
                : `${searchData.length} results`}
            </Typography>
          )}
          {isLoading && <Typography variant="caption">Loading...</Typography>}
          {search.length > 0 && (
            <List disablePadding dense>
              {searchData.map((post) => (
                <Item
                  key={post.id}
                  user={post.userId}
                  name={post.name}
                  content={post.content}
                  path={post.path}
                  onClick={handleClose}
                />
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Search;
