import React, { Fragment } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { RenderFormSection } from "@austere-monorepo/components";
import { Offline, Online } from "react-detect-offline";
import Alert from "@material-ui/lab/Alert";
// import { useSelector, useDispatch } from "react-redux";

// function useForceUpdate() {
//   const [value, setValue] = useState(0); // integer state
//   return () => setValue((value) => value + 1); // update the state to force render
// }

function AppInstalled() {
  // const isAppInstalled = useSelector((state) => state.sw.appInstalled);

  // const forceUpdate = useForceUpdate();

  // const dispatch = useDispatch();

  // const [state, setState] = useState({
  //   theme: storeTheme === "dark" ? true : false
  // });

  // const installApp = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  //   localStorage.setItem("theme", state.theme === false ? "dark" : "light");
  //   dispatch({
  //     type: "UPDATE_APP_INSTALLED",
  //     payload: {
  //       theme: state.theme === false ? "dark" : "light"
  //     }
  //   });
  // };

  // window.addEventListener("appinstalled", (e) => {
  //   console.log(e);
  //   dispatch({
  //     type: "APP_INSTALLED",
  //     payload: {
  //       isAppInstalled: true
  //     }
  //   });
  // });

  const handleReset = () => {
    localStorage.setItem("installPrompt", true);
    location.reload();
  };

  return (
    <Fragment>
      <Online>
        <RenderFormSection heading="Installation">
          <Grid item xs={12} md={6}>
            <Typography gutterBottom paragraph>
              Click 'Reset Install Prompt' to force the reset prompt to reappear and assist you in
              installing this web app on your device.
            </Typography>

            <Button
              onClick={handleReset}
              fullWidth
              variant="outlined"
              color="primary"
              startIcon={<SystemUpdateAltIcon />}
            >
              Reset Install Prompt
            </Button>
          </Grid>
        </RenderFormSection>
      </Online>
      <Offline>
        <RenderFormSection heading="Installation">
          <Grid item xs={12}>
            <Alert severity="warning">
              App installation unavailable while offline. Connect to a network to install this app.
            </Alert>
          </Grid>
        </RenderFormSection>
      </Offline>
    </Fragment>
  );
}

export default AppInstalled;
