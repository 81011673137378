import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography, Backdrop } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  progress: {
    position: "fixed",
    top: "50%",
    color: theme.palette.grey[400]
  },
  text: {
    position: "fixed",
    top: "calc(50% + 65px)",
    color: theme.palette.grey[400],
    fontSize: "0.9rem"
  },
  cancel: {
    textDecoration: "underline",
    fontWeight: 500,
    cursor: "pointer"
  }
}));

function StyledProgress({ message }) {
  const classes = useStyles();

  const history = useHistory();

  const cancel = () => {
    history.goBack();
  };

  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCancel(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress className={classes.progress} size={50} />
      {!showCancel && (
        <Typography className={classes.text}>{message ? message : "Loading"}</Typography>
      )}
      {showCancel && (
        <Typography onClick={cancel} className={`${classes.text} ${classes.cancel}`}>
          Cancel
        </Typography>
      )}
    </Backdrop>
  );
}

StyledProgress.propTypes = {
  message: PropTypes.string
};

export default StyledProgress;
