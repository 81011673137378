const commsColor = (rating) => {
  if (!rating) {
    return ""; // Null
  }
  if (rating <= 110) {
    return "#29ad01"; // Green: Good connection
  }
  if (rating < 125 && rating > 110) {
    return "#ffaa00"; // Orange: Fair connection
  }
  if (rating >= 125) {
    return "#FF0000"; // Red: No connection
  }
  return "";
};

const commsMessage = (rating) => {
  if (!rating) {
    return ""; // Null
  }
  if (rating <= 110) {
    return "Good"; // Green: Good connection
  }
  if (rating < 125 && rating > 110) {
    return "Fair/Poor"; // Orange: Fair connection
  }
  if (rating >= 125) {
    return "No connection"; // Red: No connection
  }
  return "";
};

export function EmergencyMarker(feature, layer) {
  layer.bindPopup(
    '<div style="text-align: center;">' +
      '<b style="text-transform: capitalize;  padding: 10px 0px;">' +
      feature.properties.category +
      " " +
      feature.properties.description +
      "<br /><small>" +
      feature.geometry.coordinates[1] +
      ", " +
      feature.geometry.coordinates[0] +
      "</small></div>" +
      '<div style="text-align: left;">' +
      '</b><hr style="margin: 5px 0;" />' +
      "<b>Telstra: </b>" +
      '<span style="color:' +
      commsColor(feature.properties.telstra) +
      ';">' +
      "-" +
      feature.properties.telstra +
      "dBm (" +
      commsMessage(feature.properties.telstra) +
      ")" +
      "</span>" +
      "</br>" +
      "<b>Optus: </b>" +
      '<span style="color:' +
      commsColor(feature.properties.optus) +
      ';">' +
      "-" +
      feature.properties.optus +
      "dBm (" +
      commsMessage(feature.properties.optus) +
      ")" +
      "</span>" +
      "<br><b>Gradient: </b>" +
      feature.properties.gradient +
      "<br><b>Condition: </b>" +
      feature.properties.condition +
      "<br><b>Width: </b>" +
      feature.properties.width +
      "<br><b>Vegetation: </b>" +
      feature.properties.vegetation +
      "<p>" +
      "<b>Distance to 2WD track: </b>" +
      feature.properties.distance_2wd +
      "m" +
      '<table style="text-align: left;padding-left: 0px; margin-left: 0px; font-size: 11px; border-collapse:collapse;border-spacing:0;">' +
      '<colgroup style="font-size: 11px;">' +
      '<col span="4" >' +
      "</colgroup>" +
      '<tr style="font-size: 11px;">' +
      '<th style="font-size: 11px;">Mode</th>' +
      '<th style="font-size: 11px;">Speed</th>' +
      '<th style="font-size: 11px;">Time (min)</th>' +
      '<th style="font-size: 11px;">Notes</th>' +
      "</tr>" +
      '<tr style="font-size: 11px;">' +
      '<td style="font-size: 11px;">Walk</td>' +
      '<td style="font-size: 11px;">2km/h</td>' +
      '<td style="font-size: 11px;">' +
      (Math.round(feature.properties.distance_2wd / 2) * 60) / 1000 +
      "</td>" +
      '<td style="font-size: 11px;">Stretcher carry pace</td>' +
      "</tr>" +
      '<tr style="font-size: 11px;">' +
      '<td style="font-size: 11px;">Walk</td>' +
      '<td style="font-size: 11px;">4km/h</td>' +
      '<td style="font-size: 11px;">' +
      (Math.round(feature.properties.distance_2wd / 4) * 60) / 1000 +
      "</td>" +
      '<td style="font-size: 11px;">Easy walk pace</td>' +
      "</tr>" +
      '<tr style="font-size: 11px;">' +
      '<td style="font-size: 11px;">Walk</td>' +
      '<td style="font-size: 11px;">6km/h</td>' +
      '<td style="font-size: 11px;">' +
      (Math.round(feature.properties.distance_2wd / 6) * 60) / 1000 +
      "</td>" +
      '<td style="font-size: 11px;">Brisk walk pace</td>' +
      "</tr>" +
      '<tr style="font-size: 11px;">' +
      '<td style="font-size: 11px;">Ride</td>' +
      '<td style="font-size: 11px;">10km/h</td>' +
      '<td style="font-size: 11px;">' +
      (Math.round(feature.properties.distance_2wd / 10) * 60) / 1000 +
      "</td>" +
      '<td style="font-size: 11px;"></td>' +
      "</tr>" +
      '<tr style="font-size: 11px;">' +
      '<td style="font-size: 11px;">Ride</td>' +
      '<td style="font-size: 11px;">15km/h</td>' +
      '<td style="font-size: 11px;">' +
      (Math.round(feature.properties.distance_2wd / 15) * 60) / 1000 +
      "</td>" +
      '<td style="font-size: 11px;"></td>' +
      "</tr>" +
      '<tr style="font-size: 11px;">' +
      '<td style="font-size: 11px;">Ride</td>' +
      '<td style="font-size: 11px;">20km/h</td>' +
      '<td style="font-size: 11px;">' +
      (Math.round(feature.properties.distance_2wd / 20) * 60) / 1000 +
      "</td>" +
      '<td style="font-size: 11px;"></td>' +
      "</tr>" +
      "</table>" +
      "</p>" +
      "<p>" +
      "<div><b>Notes</b></div>" +
      feature.properties.notes +
      "</p>" +
      "<p>" +
      "<div><b>Access</b></div>" +
      "<b>Descriptor: </b>" +
      feature.properties.access_descriptor_name +
      "(" +
      feature.properties.access_descriptor +
      ")" +
      "<br><b>Road Crossing: </b>" +
      feature.properties.access_road_crossing_lat +
      ", " +
      feature.properties.access_road_crossing_lon +
      "<br><b>Vehicle Access: </b>" +
      feature.properties.access_vehicle_access +
      "<br><b>Major Rd Ref: </b>" +
      feature.properties.access_major_rd_ref +
      // Egress
      "<p>" +
      "<div><b>Egress</b></div>" +
      "<b>Descriptor: </b><span>" +
      feature.properties.egress_descriptor_name +
      " (" +
      feature.properties.egress_descriptor +
      ") " +
      "</spam><br><b>Road Crossing: </b>" +
      feature.properties.egress_road_crossing_lat +
      ", " +
      feature.properties.egress_road_crossing_lon +
      "<br><b>Vehicle Egress: </b>" +
      feature.properties.egress_vehicle_access +
      "<br><b>Major Rd Ref: </b>" +
      feature.properties.egress_major_rd_ref +
      "</p>" +
      "<p>" +
      "<b>Helicopter</b>" +
      "<br><b>Access ID: </b>" +
      feature.properties.helo_access_id +
      "<br><b>LZ Type: </b>" +
      feature.properties.lz_type +
      "<br><b>LZ Coordinates: </b>" +
      feature.properties.helo_access_lat +
      ", " +
      feature.properties.helo_access_lon +
      "</p>" +
      "<small>Accuracy: +/-" +
      Math.round(feature.properties.accuracy * 100) / 100 +
      "m</small>" +
      "<br><small>Added " +
      feature.properties.created_by +
      " " +
      feature.properties.created_at +
      "</small><br />" +
      "</div>",
    {
      closeButton: false,
      pane: "popupPane"
    }
  );
}
