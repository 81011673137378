import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import { StyledProgress, StyledViewSection } from "@austere-monorepo/components";

function ViewTask(props) {
  const { update } = props;

  const rows = update
    ? [
        {
          heading: "Bay of Fires",
          items: [
            { heading: "Status", value: update.poimena_status },
            { heading: "Message", value: update.poimena_message }
          ]
        },
        {
          heading: "St Helens Network",
          items: [
            { heading: "Status", value: update.stacked_loop_status },
            { heading: "Message", value: update.stacked_loop_message }
          ]
        },
        {
          heading: "Dreaming Pools",
          items: [
            { heading: "Status", value: update.dreaming_pools_status },
            { heading: "Message", value: update.dreaming_pools_message }
          ]
        },
        {
          heading: "Details",
          items: [
            {
              heading: "Sent by",
              value: `${update.created_by_name} ${moment(update.created_at).format(
                "DD-MMM-YYYY HH:mm"
              )}`,
              md: 6
            }
          ]
        }
      ]
    : [update];

  return (
    <Fragment>
      {update && (
        <Fragment>
          <StyledViewSection
            actionItems
            title={`Update ${moment(update.created_at).format("DD-MMM-YYYY HH:mm")}`}
            rows={rows}
            collection={"updates"}
            doc={"update"}
            id={update.id}
          />
        </Fragment>
      )}
      {!update && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firestore: { ordered } }, props) {
  return {
    update:
      ordered.updates && ordered.updates.filter((update) => update.id === props.match.params.id)[0]
  };
}

export default compose(connect(mapStateToProps))(ViewTask);
