import React, { Fragment, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader
  // Divider,
  // Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Document, StyledSideView, Search, getHash } from "@austere-monorepo/components";
import { NavLink, Redirect, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(0, 0, 0.5, 0),
    margin: theme.spacing(0.5, 1, 0, 1),
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: "0.75rem",
    lineHeight: 1.5
  },
  doc: {
    padding: theme.spacing(0.25, 0),
    "& .MuiListItemText-primary": {
      fontSize: "0.85rem",
      padding: theme.spacing(0.25)
    },
    "& .MuiListItemText-root": {
      margin: theme.spacing(0.3, 0, 0.3, 2),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize"
    }
  },
  heading: {
    padding: theme.spacing(0),
    // borderLeft: "1px solid",
    // borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    "& .MuiListItemText-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "initial"
    },
    "& .MuiListItemText-root > span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "initial",
      fontSize: "0.75rem",
      padding: theme.spacing(0),
      margin: theme.spacing(0.25, 0, 0.25, 3)
    }
  },
  listText: {
    margin: theme.spacing(0.15, 0)
  }
}));

function HandbookLayout(props) {
  const { docs, title, home } = props;

  const classes = useStyles();

  const validId = props.match.params.path;

  if (!validId) {
    return <Redirect to="/404" />;
  }

  const fetchedPost = {};

  let postExists = false;

  docs.forEach(function (obj) {
    const res = obj.children.map(function (post) {
      if (validId === post.path) {
        fetchedPost.title = post.name ? post.name : "No title given";
        fetchedPost.updated = post.updated;
        fetchedPost.author = post.author;
        fetchedPost.content = post.content ? post.content : "No content given";
        fetchedPost.headings = post.headings ? post.headings : null;
        fetchedPost.path = post.path;
        postExists = true;
      }
      return res;
    });
    if (postExists === false) {
      return <Redirect to="/404" />;
    }
  });

  const location = useLocation();

  const drawerList = useMemo(
    () => (
      <Fragment>
        <List dense>
          <ListItem
            button
            className={classes.doc}
            selected={fetchedPost.path === home}
            component={NavLink}
            to={`${home}`}
          >
            <ListItemText primary={`${home}`} />
          </ListItem>
          {/* <Divider /> */}
        </List>
        {/* Section */}
        {docs.map(
          (section, i) =>
            section.name && (
              <Fragment key={i}>
                <List
                  dense
                  subheader={
                    <ListSubheader disableGutters disableSticky className={classes.section}>
                      {section.name}
                    </ListSubheader>
                  }
                >
                  {section.children &&
                    section.children.map((doc, i) => (
                      <Fragment key={i}>
                        <ListItem
                          button
                          className={classes.doc}
                          selected={fetchedPost.path === doc.path}
                          component={NavLink}
                          to={doc.path}
                        >
                          <ListItemText primary={doc.name} />
                        </ListItem>
                        {fetchedPost.path === doc.path && (
                          <Fragment>
                            {doc.headings && (
                              <List dense disablePadding>
                                {doc.headings.map((heading, i) => (
                                  <ListItem
                                    key={i}
                                    button
                                    component={HashLink}
                                    to={getHash(heading)}
                                    selected={location.hash === getHash(heading)}
                                    className={classes.heading}
                                  >
                                    <ListItemText className={classes.listText} primary={heading} />
                                  </ListItem>
                                ))}
                              </List>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ))}
                </List>
              </Fragment>
            )
        )}
      </Fragment>
    ),
    [fetchedPost]
  );

  return (
    <StyledSideView
      title={title}
      sidebar={drawerList}
      actionItems={<Search docs={docs} />}
      content={
        postExists && (
          <Document
            html={fetchedPost.content}
            title={fetchedPost.title}
            updated={fetchedPost.updated}
            author={fetchedPost.author}
          />
        )
      }
    />
  );
}

export default HandbookLayout;
