import React from "react";
import { StyledTable } from "@austere-monorepo/components";

function HazardListTable({ _hazards }) {
  // console.log(_hazards);
  const columns = [
    { title: "Edit", name: "edit" },
    { title: "View", name: "view" },
    { title: "Status", name: "status" },
    { title: "Action", name: "description" },
    { title: "Rating", name: "hazard_rating" },
    { title: "ID", name: "hazard_id" },
    { title: "Created", name: "created_at" },
    { title: "Assigned To", name: "assigned_name" },
    { title: "By", name: "created_by_name" },
    { title: "Resolved", name: "completed_at" }
  ];

  const tableColumns = [
    // { columnName: "hazard_id", width: 100 },
    // { columnName: "status", width: 130 },
    // { columnName: "hazard_rating", width: 100 },
    // { columnName: "description", width: 350 }
  ];

  const dateColumns = ["created_at", "completed_at"];

  // const columnStyles = [
  //   {
  //     columnName: "hazard_rating"
  //     // formatterComponent: {
  //     //   Low: "green",
  //     //   Medium: "orange",
  //     //   High: "red",
  //     //   Extreme: "purple"
  //     // }
  //   }
  // ];

  // const rowStyles = {
  //   Resolved: { color: "red" },
  //   Unresolved: {}
  // };

  const columnStyles = ["hazard_rating"];

  const columnColors = {
    Low: "green",
    Medium: "orange",
    High: "red",
    Extreme: "purple"
  };

  const rowStyles = {
    columnName: "status",
    style: {
      Resolved: {
        textDecoration: "line-through"
      }
    }
  };

  const priorityWeights = {
    Low: 5,
    Medium: 4,
    High: 3,
    Extreme: 2,
    Unrated: 1,
    undefined: 0
  };

  // const comparePriority = (a, b) => {
  //   const priorityA = priorityWeights[a];
  //   const priorityB = priorityWeights[b];
  //   if (priorityA === priorityB) {
  //     return 0;
  //   }
  //   return priorityA < priorityB ? -1 : 1;
  // };

  // const priorityWeights = {
  //   Low: 4,
  //   Medium: 3,
  //   High: 2,
  //   Extreme: 1
  // };

  return (
    <StyledTable
      rows={_hazards && _hazards}
      columns={columns && columns}
      addButton
      collection={"actions"}
      doc={"action"}
      tableColumns={tableColumns}
      dateColumns={dateColumns}
      columnStyles={columnStyles}
      columnColors={columnColors}
      rowStyles={rowStyles}
      // groupedBy={[{ columnName: "status" }]}
      sortingColumns={[{ columnName: "hazard_rating", direction: "asc" }]}
      priorityWeights={priorityWeights}
      expandedGroups={["Unresolved"]}
    />
  );
}

export default HazardListTable;
