import React, { useMemo, useEffect, Fragment } from "react";
import { Field } from "redux-form";
import {
  FormNotes,
  FormWrapper,
  FormGeo,
  FormConditional,
  FormImage,
  FormUser,
  RenderTextField,
  RenderSelectField,
  RenderFormSection,
  RenderHiddenField
} from "@austere-monorepo/components";
import Map from "client/map/Map";
// import moment from "moment";
import trails from "../map/geojson/trails.json";
import { useSelector } from "react-redux";

function HazardForm(props) {
  const { role, users } = props;

  // Reduce the trails geoJson and group by activity area, then memoize
  const trailsReduced = useMemo(() => {
    const val = trails.features.reduce(function (r, a) {
      r[a.properties.area] = r[a.properties.area] || [];
      r[a.properties.area].push(a.properties.name);
      return r;
    }, Object.create(null));
    return val;
  }, []);

  // console.log(trailsReduced);

  const formName = props.form;
  const statusValue = useSelector(
    (state) => state.form[formName].values && state.form[formName].values.status
  );

  const change = props.change;

  useEffect(() => {
    if (statusValue === "Resolved") {
      change("completed_at", Date.now());
    }
    if (statusValue !== "Resolved") {
      change("completed_at", null);
    }
    return null;
  }, [statusValue, change]);

  return (
    <FormWrapper title="Action" {...props}>
      <RenderFormSection heading="Details">
        {/* <Field
          name="hazard_id"
          label="Action ID"
          type="number"
          disabled
          component={RenderHiddenField}
        /> */}
        <FormConditional
          name1={"hazard_activity"}
          name2={"hazard_trail"}
          label1={"Activity"}
          label2={"Trail"}
          required1={true}
          required2={false}
          md1={6}
          md2={6}
          items={trailsReduced}
          {...props}
        />
        <Field
          required
          name="description"
          label="Brief description of problem"
          type="text"
          component={RenderTextField}
          md={6}
        />
        <Field
          name="hazard_detail"
          label="More details about the problem (if required)"
          type="text"
          component={RenderTextField}
          multiline
        />
        {role === 2 && (
          <Fragment>
            <Field
              required
              name="hazard_rating"
              label="Problem Risk Rating"
              component={RenderSelectField}
              md={6}
              items={[
                { label: "Unrated", val: "Unrated" },
                { label: "Low", val: "Low" },
                { label: "Medium", val: "Medium" },
                { label: "High", val: "High" },
                { label: "Extreme", val: "Extreme" }
              ]}
            />
            <Field
              required
              name="status"
              label="Action Status"
              component={RenderSelectField}
              md={6}
              items={[
                { label: "Unresolved", val: "Unresolved" },
                { label: "Resolved", val: "Resolved" }
              ]}
            />
            <Field component={RenderHiddenField} name="completed_at" />
            <FormUser items={users} {...props} />
          </Fragment>
        )}
      </RenderFormSection>
      <FormImage maxImages={1} {...props} />
      <FormGeo {...props} displayMap={<Map id="form-map" />} />
      <FormNotes {...props} />
    </FormWrapper>
  );
}

export default HazardForm;
