import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import UserForm from "./UserForm";
import { StyledProgress, SaveButton, handleFormEdit } from "@austere-monorepo/components";

function MyProfile(props) {
  const { handleFormEdit, handleSubmit, _user, _auth } = props;

  const onSubmit = ({ id, ...formProps }) => {
    handleFormEdit({
      formCollection: "users",
      formData: formProps,
      currentData: _user,
      auth: _auth,
      redirect: "/account"
    });
  };

  return (
    <Fragment>
      {_user && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm
            {...props}
            title="My Profile"
            actionItems={
              <Fragment>
                <SaveButton {...props} />
              </Fragment>
            }
          />
        </form>
      )}
      {!_user && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firestore: { data }, firebase: { auth } }) {
  const currentUser = data.owner && data.owner;
  return {
    _auth: auth,
    _user: currentUser,
    initialValues: currentUser
  };
}

export default compose(
  connect(mapStateToProps, { handleFormEdit }),
  reduxForm({
    form: "profileForm"
  })
)(MyProfile);
