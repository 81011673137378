import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getFirebase } from "react-redux-firebase";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import {
  FormWrapper,
  RenderTextField,
  RenderFormSection,
  history
} from "@austere-monorepo/components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main
  }
}));

const handleLogout = () => async (dispatch) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  try {
    await dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Logging out...",
        options: {
          variant: "warning"
        }
      }
    });
    await auth.signOut();
    // await firebase().app.delete().then(function () {
    //   console.log("App deleted successfully");
    // });
    await dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Logged out successfully",
        options: {
          variant: "success"
        }
      }
    });
    // await dispatch({
    //   type: "CLEAR_STORE"
    // });
    await history.push("/");
    await history.go(0);
    // await firebase.firestore().clearPersistence();
  } catch (error) {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: `Logout failed ${error.message}`,
        options: {
          variant: "error"
        }
      }
    });
  }
};

function MyAccount(props) {
  const { handleLogout, deleteAccount } = props;
  const classes = useStyles();
  return (
    <FormWrapper {...props}>
      <RenderFormSection
        heading="Details"
        actions={
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLogout}
            startIcon={<ExitToAppIcon />}
          >
            Logout
          </Button>
        }
      >
        <Field
          name="displayName"
          label="Name"
          type="text"
          component={RenderTextField}
          disabled
          md={6}
        />
        <Field name="email" label="Email" type="text" component={RenderTextField} disabled md={6} />
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Button
                color="secondary"
                size="small"
                variant="text"
                component={Link}
                to="/change-name"
              >
                Change Name
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="secondary"
                size="small"
                variant="text"
                component={Link}
                to="/change-email"
              >
                Change Email
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="secondary"
                size="small"
                variant="text"
                component={Link}
                to="/reset-password"
              >
                Reset Password
              </Button>
            </Grid>
            {deleteAccount && (
              <Grid item>
                <Button
                  className={classes.red}
                  size="small"
                  variant="text"
                  component={Link}
                  to="/delete-account"
                >
                  Delete Account
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </RenderFormSection>
    </FormWrapper>
  );
}

function mapStateToProps({ firestore: { data } }) {
  return {
    initialValues: data.owner && data.owner
  };
}

export default compose(
  connect(mapStateToProps, { handleLogout }),
  reduxForm({
    form: "accountForm"
  })
)(MyAccount);
