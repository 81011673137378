import React, { useState, useMemo, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ImageOverlay,
  LayerGroup,
  LayersControl,
  TileLayer,
  GeoJSON,
  Marker,
  Tooltip,
  Popup,
  useMap
} from "react-leaflet";

import L from "leaflet";
import {
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormGroup,
  FormLabel
} from "@material-ui/core";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import LayersIcon from "@material-ui/icons/Layers";
import MapButton from "./MapButton";

//Signal
// import GoodSignalIcon from "@material-ui/icons/SignalCellular4Bar";
// import WeakSignalIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
// import NoSignalIcon from "@material-ui/icons/SignalCellularOff";

const { BaseLayer, Overlay } = LayersControl;

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1, 2),
    // border: "1px solid red",
    "& .MuiFormLabel-root": {
      padding: theme.spacing(1.5, 0, 0.5, 0)
    }
  },
  formControlLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.85rem"
    }
  },
  radio: {
    width: 32,
    height: 32
  },
  offlineCount: {
    marginTop: "-3px",
    marginLeft: "0px",
    fontSize: 10
  }
}));

function Layers(props) {
  const {
    baseLayers,
    offlineLayers,
    imageBaseLayers,
    dataLayers,
    geoJsonLayers,
    initBaseLayer,
    initDataLayers,
    initBaseBounds,
    initMaxZoom,
    initMinZoom
  } = props;

  // console.log(initDataLayers)

  const classes = useStyles();

  const map = useMap();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get the layers for the user's role
  const _role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );
  const userBaseLayers = useMemo(
    () => baseLayers && baseLayers.filter((layer) => _role >= layer.role || !layer.role),
    [_role]
  );

  const userOfflineLayers = useMemo(
    () => offlineLayers && offlineLayers.filter((layer) => _role >= layer.role || !layer.role),
    [_role]
  );
  const userImageBaseLayers = useMemo(
    () => imageBaseLayers && imageBaseLayers.filter((layer) => _role >= layer.role || !layer.role),
    [_role]
  );
  const userDataLayers = useMemo(
    () => dataLayers && dataLayers.filter((layer) => _role >= layer.role || !layer.role),
    [dataLayers, _role]
  );

  const userGeoJsonLayers = useMemo(
    () => geoJsonLayers && geoJsonLayers.filter((layer) => _role >= layer.role || !layer.role),
    []
  );

  // https://fonts.google.com/icons?selected=Material+Icons
  // https://www.w3schools.com/colors/colors_names.asp
  const markerIcons = {
    POI: {
      // done
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "royalblue"
    },
    Task: {
      // done
      icon: '<path d="M16.703,9.587l-4.943,4.942l-2.692,-2.685l-1.058,1.058l3.75,3.75l6,-6l-1.057,-1.065Zm-3.443,-4.185c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.315,-0 -6,-2.685 -6,-6c0,-3.315 2.685,-6 6,-6c3.315,-0 6,2.685 6,6c0,3.315 -2.685,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "cornflowerblue"
    },
    "Task Complete": {
      // done
      icon: '<path d="M16.703,9.587l-4.943,4.942l-2.692,-2.685l-1.058,1.058l3.75,3.75l6,-6l-1.057,-1.065Zm-3.443,-4.185c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.315,-0 -6,-2.685 -6,-6c0,-3.315 2.685,-6 6,-6c3.315,-0 6,2.685 6,6c0,3.315 -2.685,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "cornflowerblue"
    },
    Action: {
      // done
      icon: '<path d="M16.703,9.587l-4.943,4.942l-2.692,-2.685l-1.058,1.058l3.75,3.75l6,-6l-1.057,-1.065Zm-3.443,-4.185c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.315,-0 -6,-2.685 -6,-6c0,-3.315 2.685,-6 6,-6c3.315,-0 6,2.685 6,6c0,3.315 -2.685,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "cornflowerblue"
    },
    "Action Complete": {
      // done
      icon: '<path d="M16.703,9.587l-4.943,4.942l-2.692,-2.685l-1.058,1.058l3.75,3.75l6,-6l-1.057,-1.065Zm-3.443,-4.185c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.315,-0 -6,-2.685 -6,-6c0,-3.315 2.685,-6 6,-6c3.315,-0 6,2.685 6,6c0,3.315 -2.685,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "cornflowerblue"
    },
    Hazard: {
      // done
      icon: '<path d="M13.26 7.539l4.792 8.279H8.468l4.792-8.279m0-2.539l-7 12.091h14L13.26 5z" fill="#707070" fill-rule="nonzero" /><path d="M13.897 13.909h-1.273v1.273h1.273v-1.273zm0-3.818h-1.273v3.182h1.273v-3.182z" fill="#707070" fill-rule="nonzero" />',
      markerColor: "yellow"
    },
    "Hazard Resolved": {
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "red"
    },
    "High-risk Feature": {
      icon: '<path d="M13.26 7.539l4.792 8.279H8.468l4.792-8.279m0-2.539l-7 12.091h14L13.26 5z" fill="#707070" fill-rule="nonzero" /><path d="M13.897 13.909h-1.273v1.273h1.273v-1.273zm0-3.818h-1.273v3.182h1.273v-3.182z" fill="#707070" fill-rule="nonzero" />',
      markerColor: "yellow"
    },
    "Access Point": {
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "purple"
    },
    Casualty: {
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "red"
    },
    "Casualty Complete": {
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "red"
    },
    "Emergency Marker": {
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "#006B87"
    },
    "Start Point": {
      //done
      icon: '<path d="M14.848 7.931l-.764-1.529H7.966v13h1.53v-5.353h3.823l.765 1.529h5.353V7.931h-4.589zm3.059 6.118h-3.059l-.764-1.53H9.496V7.931h3.823l.765 1.53h3.823v4.588z" fill="#707070" fill-rule="nonzero" />',
      markerColor: "teal"
    },
    "Finish Point": {
      //done
      icon: '<path d="M14.713 7.931l-.306-1.529H7.525v13h1.529v-5.353h4.283l.306 1.529h5.353V7.931h-4.283z" fill="#707070" fill-rule="nonzero" />',
      markerColor: "teal"
    },
    "Helicopter LZ": {
      // done
      icon: '<path d="M8.223,19.202l0,-12.601l2.545,0l-0,4.96l4.985,-0l-0,-4.96l2.544,0l0,12.601l-2.544,0l-0,-5.51l-4.985,0l-0,5.51l-2.545,0Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "palegreen"
    },
    Airstrip: {
      icon: '<path d="M12.51,9.152l1.5,-0l0,1.5l-1.5,-0l0,-1.5Zm0,3l1.5,-0l0,4.5l-1.5,-0l0,-4.5Zm0.75,-6.75c-4.14,-0 -7.5,3.36 -7.5,7.5c0,4.14 3.36,7.5 7.5,7.5c4.14,-0 7.5,-3.36 7.5,-7.5c0,-4.14 -3.36,-7.5 -7.5,-7.5Zm0,13.5c-3.307,-0 -6,-2.693 -6,-6c0,-3.308 2.693,-6 6,-6c3.308,-0 6,2.692 6,6c0,3.307 -2.692,6 -6,6Z" style="fill:#707070;fill-rule:nonzero;" />',
      markerColor: "green"
    }
  };

  const markerBase = `<svg viewBox="0 0 35 35"" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M32.284 12.701c-3.344-2.585-9.025-1.268-12.706 2.947-4.994 5.717-6.318 18.834-6.318 18.834s13.419-3.763 18.413-9.481c3.681-4.214 3.954-9.714.611-12.3z" fill-opacity="0.11" fill-rule="nonzero" /> <path d="M13.26.518C6.138.518.377 5.833.377 12.405c0 8.916 12.883 22.077 12.883 22.077s12.883-13.161 12.883-22.077c0-6.572-5.76-11.887-12.883-11.887z" fill={markerColor} fill-rule="nonzero" stroke="#fff" stroke-width="0.4px" /> <circle cx="13.26" cy="12.902" r="9.5" fill="#fff" stroke="#fff" stroke-width="0.4px" stroke-linecap="round" stroke-miterlimit="1.5" />{markerIcon}</svg>`;

  const markerPosition = { iconSize: [35, 35], iconAnchor: [12, 35], popupAnchor: [0, -34] };

  // Map base layer state
  const [baseValue, setBaseValue] = useState(initBaseLayer);
  const [baseBounds, setBaseBounds] = useState(initBaseBounds);
  const [dataValue, setDataValue] = useState(initDataLayers);
  const [maxZoom, setMaxZoom] = useState(initMaxZoom);
  const [minZoom, setMinZoom] = useState(initMinZoom);

  // Update the store with all changes
  useEffect(() => {
    dispatch({
      type: "UPDATE_MAP_BASE",
      payload: {
        base: baseValue
      }
    });
    dispatch({
      type: "UPDATE_MAP_BOUNDS",
      payload: {
        bounds: baseBounds
      }
    });
    dispatch({
      type: "UPDATE_DATA_LAYERS",
      payload: {
        data: dataValue
      }
    });
    dispatch({
      type: "UPDATE_MAX_ZOOM",
      payload: {
        maxZoom: maxZoom
      }
    });
    dispatch({
      type: "UPDATE_MIN_ZOOM",
      payload: {
        minZoom: minZoom
      }
    });
    map.setMaxBounds(baseBounds);
    map.setMaxZoom(maxZoom);
    map.setMinZoom(minZoom);
  }, [baseValue, baseBounds, dataValue]);

  const handleDataChange = (event) => {
    setDataValue({ ...dataValue, [event.target.name]: event.target.checked });
  };

  // https://stackoverflow.com/questions/22538473/leaflet-center-popup-and-marker-to-the-map
  map.on("popupopen", function (e) {
    var px = map.project(e.target._popup._latlng); // find the pixel location on the map where the popup anchor is
    px.y -= e.target._popup._container.clientHeight / 2; // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
    map.panTo(map.unproject(px), { animate: true }); // pan to new center
  });

  // Get the number of tiles that need to be downloaded
  const getCacheLength = () => {
    offlineLayers.map(function (obj) {
      caches.open(obj.cacheName).then(function (cache) {
        cache.keys().then(function (keys) {
          return Object.assign(obj, {
            downloadedLength: keys.length
          });
        });
      });
    });
    return offlineLayers;
  };

  const offlineLayersWithCache = userOfflineLayers && getCacheLength();

  return (
    <Fragment>
      <MapButton title={"Map layers"} order={0} onClick={handleClick}>
        <LayersIcon />
      </MapButton>
      <Dialog open={Boolean(anchorEl)} onClose={handleClose}>
        <DialogContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup value={baseValue}>
              <Fragment>
                {userBaseLayers && (
                  <Fragment>
                    <FormLabel>Base Layers</FormLabel>
                    {userBaseLayers.length > 0 &&
                      userBaseLayers.map((layer, i) => (
                        <FormControlLabel
                          className={classes.formControlLabel}
                          key={i}
                          size="small"
                          value={layer.title}
                          control={<Radio size="small" classes={{ root: classes.radio }} />}
                          label={layer.title}
                          onChange={() => {
                            setBaseValue(layer.title);
                            setBaseBounds(layer.bounds);
                            setMaxZoom(layer.maxZoom);
                            setMinZoom(layer.minZoom);
                          }}
                        />
                      ))}
                  </Fragment>
                )}
              </Fragment>

              <Fragment>
                {userOfflineLayers && (
                  <Fragment>
                    <FormLabel>Offline Layers</FormLabel>
                    <FormHelperText className={classes.offlineCount}>
                      {/* {`Downloaded: ${
                              layer.downloadedLength === undefined ? "?" : layer.downloadedLength
                            }/${layer.cacheLength} tiles`} */}
                      <Link to="/account/settings">Manage downloads</Link>
                    </FormHelperText>
                    {userOfflineLayers.length > 0 &&
                      offlineLayersWithCache.map((layer, i) => (
                        <Fragment>
                          <FormControlLabel
                            className={classes.formControlLabel}
                            key={i}
                            size="small"
                            value={layer.title}
                            control={<Radio size="small" classes={{ root: classes.radio }} />}
                            label={layer.title}
                            onChange={() => {
                              setBaseValue(layer.title);
                              setBaseBounds(layer.bounds);
                              setMaxZoom(layer.maxZoom);
                              setMinZoom(layer.minZoom);
                            }}
                          />
                        </Fragment>
                      ))}
                  </Fragment>
                )}
              </Fragment>
            </RadioGroup>
          </FormControl>

          {userDataLayers && userDataLayers.length > 0 && (
            <FormControl className={classes.formControl}>
              <FormLabel>Data Layers</FormLabel>
              <FormGroup>
                {userDataLayers &&
                  userDataLayers.map((layer, i) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={i}
                      control={
                        <Checkbox
                          size="small"
                          classes={{ root: classes.radio }}
                          checked={dataValue[`${layer.title}`]}
                          onChange={handleDataChange}
                          name={layer.title}
                        />
                      }
                      label={layer.title}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          )}

          {userGeoJsonLayers && userGeoJsonLayers.length > 0 && (
            <FormControl className={classes.formControl}>
              <FormLabel>Other Layers</FormLabel>
              <FormGroup>
                {userGeoJsonLayers &&
                  userGeoJsonLayers.map((layer, i) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={i}
                      control={
                        <Checkbox
                          size="small"
                          classes={{ root: classes.radio }}
                          checked={dataValue[`${layer.title}`]}
                          onChange={handleDataChange}
                          name={layer.title}
                        />
                      }
                      label={layer.title}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <LayersControl>
        {userBaseLayers &&
          userBaseLayers.map((layer, i) => (
            <BaseLayer key={i} checked={baseValue === layer.title} name={layer.title}>
              <TileLayer
                attribution={false}
                url={layer.url}
                noWrap={true}
                maxZoom={layer.maxZoom ? layer.maxZoom : 20}
                minZoom={layer.minZoom ? layer.minZoom : 3}
                // updateWhenZooming={false}
                // updateInterval={400} // milliseconds, default 200
                noWrap={true}
                bounds={layer.bounds ? layer.bounds : null}
              />
            </BaseLayer>
          ))}

        {userImageBaseLayers &&
          userImageBaseLayers.map((layer, i) => (
            <BaseLayer key={i} checked={baseValue === layer.title} name={layer.title}>
              <ImageOverlay url={layer.url} />
            </BaseLayer>
          ))}

        {userOfflineLayers &&
          userOfflineLayers.map((layer, i) => (
            <BaseLayer key={i} checked={baseValue === layer.title} name={layer.title}>
              <TileLayer
                attribution={false}
                url={layer.url}
                noWrap={true}
                maxNativeZoom={layer.maxZoom ? layer.maxZoom : 20}
                minNativeZoom={layer.minZoom ? layer.minZoom : 3}
                // updateWhenZooming={false}
                // updateInterval={400} // milliseconds, default 200
                // noWrap={true}
                bounds={layer.bounds ? layer.bounds : null}
              />
            </BaseLayer>
          ))}

        {userDataLayers &&
          userDataLayers.map((layer, i) => (
            <Overlay key={i} checked={dataValue[layer.title]} name={layer.title}>
              <MarkerClusterGroup
                key={Date.now()}
                showCoverageOnHover={false}
                disableClusteringAtZoom={14}
                spiderfyOnMaxZoom={false}
                removeOutsideVisibleBounds={true}
              >
                {layer.data &&
                  layer.data.map((point, i) => (
                    <Marker
                      key={i}
                      position={[point.latitude, point.longitude]}
                      icon={L.divIcon({
                        html: L.Util.template(markerBase, {
                          markerColor: markerIcons[layer.category]
                            ? markerIcons[layer.category].markerColor
                            : markerIcons["POI"].markerColor,
                          markerIcon: markerIcons[layer.category]
                            ? markerIcons[layer.category].icon
                            : markerIcons["POI"].icon
                        }),
                        ...markerPosition
                      })}
                    >
                      <Popup closeButton={false}>
                        <div>
                          <p>
                            <b>
                              {layer.category} ({point.status})
                            </b>
                          </p>
                          <p> {point.description}</p>
                        </div>
                        <Link to={`${layer.collection}/view/${point.id}`}>View</Link>
                      </Popup>
                      <Tooltip permanent>{`${layer.category}: ${point.description}`}</Tooltip>
                    </Marker>
                  ))}
              </MarkerClusterGroup>
            </Overlay>
          ))}

        {userGeoJsonLayers &&
          userGeoJsonLayers.map((layer, i) => (
            <Overlay key={i} checked={dataValue[layer.title]} name={layer.title}>
              <LayerGroup>
                <MarkerClusterGroup
                  key={Date.now()}
                  showCoverageOnHover={false}
                  disableClusteringAtZoom={14}
                  spiderfyOnMaxZoom={false}
                  removeOutsideVisibleBounds={true}
                >
                  <GeoJSON
                    data={layer.data}
                    style={function (feature) {
                      return {
                        // fillColor: feature.properties.fill
                        //   ? feature.properties.fill
                        //   : "red", // defaults to color
                        color: feature.properties.stroke ? feature.properties.stroke : "purple", // stroke color

                        // weight: 0.3,
                        // //stroke-width: to have a constant width on the screen need to adapt with scale
                        opacity: 1,
                        // color: material_design_colors[this.state.polygonFillColor]["400"],
                        dashArray: feature.properties.dash,
                        fillOpacity: 0.2,
                        weight: 2
                      };
                    }}
                    onEachFeature={
                      layer.popup
                        ? layer.popup
                        : function (feature, layer) {
                            layer.bindPopup(
                              `<div><p><b>${feature.properties.category}</b></p><p>${
                                feature.properties.description
                              }</p> ${
                                feature.properties.notes ? `<p>${feature.properties.notes}</p>` : ""
                              } </div>`,
                              { closeButton: false }
                            );
                          }
                    }
                    pointToLayer={function (feature, latlng) {
                      return L.marker(latlng, {
                        // icon: markers[feature.properties.category],
                        icon: L.divIcon({
                          html: L.Util.template(markerBase, {
                            markerColor: markerIcons[feature.properties.category]
                              ? markerIcons[feature.properties.category].markerColor
                              : markerIcons["POI"].markerColor,
                            markerIcon: markerIcons[feature.properties.category]
                              ? markerIcons[feature.properties.category].icon
                              : markerIcons["POI"].icon
                          }),
                          ...markerPosition
                        })
                      }).bindTooltip(
                        String(
                          ` ${
                            feature.properties.signal
                              ? `<svg style="margin-right:3px;" height="8px" viewBox="0 0 6 6" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" > <path fill="${feature.properties.signal}" d="M0 6h6V0L0 6z" fill-rule="nonzero" /> </svg>`
                              : ""
                          }
                            ${feature.properties.description}`
                        ),
                        {
                          permanent: true
                        }
                      );
                    }}
                    // onClick={(e) => {
                    //   // map.setView(latlng, 16);
                    //   console.log(latlng);
                    // }}
                  />
                </MarkerClusterGroup>
              </LayerGroup>
            </Overlay>
          ))}
      </LayersControl>
    </Fragment>
  );
}

export default Layers;
