import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0),
    boxShadow: 'none'
    // borderRadius: '4px',
    // border: '1px solid',
    // borderColor: theme.palette.divider
  },
  summary: {
    padding: theme.spacing(0)
  }
}));

function StyledAccordion(props) {
  const classes = useStyles();
  return (
    <Accordion className={classes.root} container>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
      >
        <Typography variant="body1">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}

export default StyledAccordion;
