import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, Card, CardHeader, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import HazardIcon from "@material-ui/icons/ReportProblem";
import EmergencyIcon from "@material-ui/icons/LocalHospital";
// import FeedbackIcon from "@material-ui/icons/SentimentVerySatisfied";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
  orange: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main
  },
  // blue: {
  //   borderColor: theme.palette.info.main,
  //   color: theme.palette.info.main
  // },
  red: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  }
}));

function QuickButtons(props) {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader avatar={<HelpIcon />} title={"Quick Links"} />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Button
            fullWidth
            variant="outlined"
            className={classes.red}
            component={Link}
            to="/information/emergency"
            startIcon={<EmergencyIcon />}
          >
            Emergency Info
          </Button>
        </Grid>

        <Grid item md={6} xs={12}>
          <Button
            fullWidth
            variant="outlined"
            className={classes.orange}
            component={Link}
            to={"/information/report-problem"}
            startIcon={<HazardIcon />}
          >
            Report A Problem
          </Button>
        </Grid>

        {/* <Grid item md={4} xs={12}>
          <Button
            fullWidth
            variant="outlined"
            className={classes.blue}
            component={Link}
            to={"/feedback/add"}
            startIcon={<FeedbackIcon />}
          >
            Send Feedback
          </Button>
        </Grid> */}
      </Grid>
    </Card>
  );
}

QuickButtons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default QuickButtons;
