import React, { Fragment, useState, useEffect } from "react";
import {
  AppBar,
  Grid,
  Hidden,
  Drawer,
  IconButton,
  Avatar,
  Box,
  Toolbar,
  Typography,
  Badge,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { Transition } from "react-transition-group";
import Sidebar from "./Sidebar";
// Icons
import HelpIcon from "@material-ui/icons/HelpOutlineSharp";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AustereLogo from "./AustereLogo";

import QuickActions from "./QuickActions";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    width: "100%"
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    minHeight: "100vh",
    // overflow: "auto",
    maxWidth: "100vw",
    overflowX: "hidden",
    /* Hide scrollbar for IE, Edge and Firefox */
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
    /* Hide scrollbar for Chrome, Safari and Opera */
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  toolbar: {
    minHeight: "56px"
  },
  header: {
    display: "flex",
    display: "-webkit-flex",
    alignItems: "center"
  },
  toolbarLogo: {
    margin: theme.spacing(0, 0.75, 0, 0.5),
    height: "26px"
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  appTitle: {
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.2,
    textOverflow: "ellipsis"
  },
  appSubtitle: {
    display: "block",
    fontSize: 11,
    lineHeight: 1.1,
    textOverflow: "ellipsis"
  },
  drawer: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerLower: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    borderRight: `1px solid ${theme.palette.divider}`
  },
  drawerInfo: {
    padding: theme.spacing(3, 2.5),
    height: "100%",
    alignItems: "stretch",
    flex: 1
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      padding: theme.spacing(0, 1.5)
    }
  },
  menuButton: {
    margin: theme.spacing(0, 0.5),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  user: {
    backgroundColor: theme.palette.primary.main
  },
  badge: {
    "& .MuiSvgIcon-root": {
      fontSize: 20,
      stroke: theme.palette.divider,
      fill: theme.palette.grey[300]
    }
  },
  drawerPaper: {
    width: drawerWidth,
    border: "none"
  },
  footer: {
    width: "100%",
    marginBottom: "1px",
    marginTop: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  logoBox: {
    flexDirection: "column"
  },
  logo: {
    width: "115px",
    padding: "0",
    margin: "1px 0 -5px 0"
  },
  footerText: {
    fontSize: "10px",
    color: "#828282",
    letterSpacing: "0.08em",
    padding: 0
  },
  helpBox: {
    textAlign: "center",
    marginTop: "auto"
  },
  helpIcon: {
    "& .MuiSvgIcon-root": {
      fill: "#828282",
      fontSize: "1.5rem"
    },
    padding: "2px"
  },
  userDrawer: {
    position: "fixed",
    padding: theme.spacing(0.5, 1),
    width: drawerWidth,
    zIndex: 10,
    height: "56px",
    backgroundColor: theme.palette.primary.main,
    "& :hover": {
      backgroundColor: theme.palette.contrastThreshold
    }
  },
  userTextBox: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "140px",
    color: "#fff",
    fontSize: 11,
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 0, 0.2),
    height: "0.86rem"
  },
  avatar: {
    // borderRadius: theme.shape.borderRadius,
    width: 38,
    height: 38
  }
}));

function DashboardLayout({
  window,
  menuItems,
  isAuth,
  user,
  role,
  roleTitle,
  quickAddItems,
  // storeItems,
  profile,
  version,
  appTitle,
  appSubtitle,
  toolbarLogo,
  info,
  prompts,
  children
}) {
  const classes = useStyles();

  // Setup transitions
  const [fade, setFade] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setFade(true);
  }, [location.key]);

  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  function toggleDrawer() {
    setDrawerOpen((prevOpenState) => !prevOpenState);
  }

  const drawer = (
    <Fragment>
      <div className={`${classes.toolbar} ${classes.user}`}>
        <Grid container className={classes.userDrawer} alignItems="center">
          <Grid item xs={3} component={Link} to={"/account"} display="flex">
            <Badge
              color="default"
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              badgeContent={<SettingsIcon />}
              className={classes.badge}
            >
              <Avatar
                // variant="square"
                alt={profile ? profile.displayName : "Login"}
                src={profile && profile.avatar}
                className={classes.avatar}
              />
            </Badge>
          </Grid>
          {isAuth ? (
            <Fragment>
              <Grid item xs={8}>
                <Typography component="div" className={classes.userTextBox}>
                  {user.displayName ? user.displayName : "Loading..."}
                </Typography>

                <Typography component="div" className={classes.userTextBox}>
                  {user.email && user.email}
                </Typography>

                <Typography component="div" className={classes.userTextBox}>
                  {roleTitle && roleTitle}
                </Typography>
              </Grid>
            </Fragment>
          ) : (
            <Grid item xs={9}>
              <Box display="flex" alignItems="center">
                <Button
                  style={{ color: "#fff" }}
                  // startIcon={<AccountCircleIcon />}
                  component={Link}
                  to="/login"
                >
                  Login
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </div>

      {/* Sidebar Drawer Menu */}

      <Box className={classes.drawerLower}>
        <Sidebar items={menuItems} role={role} />

        {info && <Box className={classes.drawerInfo}>{info}</Box>}

        <Box className={classes.footer}>
          <Box className={classes.logoBox}>
            <Typography className={classes.footerText}>Built by</Typography>
            <Box className={classes.logo}>
              <a href="https://austererisk.com/apps">
                <AustereLogo />
              </a>
            </Box>
            <Typography className={classes.footerText}>austererisk.com/apps</Typography>
          </Box>
          <Box className={classes.helpBox}>
            <IconButton
              className={classes.helpIcon}
              component={Link}
              to="/help"
              children={<HelpIcon />}
            />
            <Typography className={classes.footerText}>v{version}</Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  // console.log(location.key);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerOpen}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Box className={classes.header}>
                  <Box component="img" className={classes.toolbarLogo} src={toolbarLogo} />
                  <Box className={classes.titleBox}>
                    <Typography component="div" noWrap className={classes.appTitle}>
                      {appTitle}
                    </Typography>
                    <Typography component="div" noWrap className={classes.appSubtitle}>
                      {appSubtitle}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              <QuickActions
                quickAddItems={quickAddItems}
                role={role}
                isAuth={isAuth}
                prompts={prompts}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            open={drawerOpen}
            variant="temporary"
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              onClose: toggleDrawer
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            container={container}
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Transition in={fade} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
            >
              {children}
            </div>
          )}
        </Transition>
      </main>
    </div>
  );
}

export default DashboardLayout;
