// Gets a hash url from a string by adding a # and replacing spaces with a -
export function getHash(heading) {
  // return `#${heading.toLowerCase().replace(/\s/g, "-")}`;
  return heading ? `#${heading.toLowerCase().replace(/\W+/g, "-")}` : null;
}

// str.replace(/\W+/g, '*');

// Gets a heading from a string by replacing spaces with a -
export function getHeading(heading) {
  return heading ? `${heading.toLowerCase().replace(/\W+/g, "-")}` : null;
}
