// https://codesandbox.io/s/verticaltabs-material-demo-forked-mcxiy?file=/demo.tsx:0-2926

import React, { useState, useMemo, useEffect, Fragment } from "react";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { StyledProgress } from "@austere-monorepo/components";

function TabPanel(props) {
  const { children, value, index } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
      margin: theme.spacing(0)
      // display: "flex"
      // background: theme.palette.background.paper,
    }
  }));
  const classes = useStyles();

  return <Fragment>{value === index && <Box className={classes.root}>{children}</Box>}</Fragment>;
}

function StyledTabs({ base, tabs, secondary }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
      margin: theme.spacing(0)
      // background: theme.palette.background.paper,
    },
    appBar: {
      position: "sticky",
      backgroundColor: secondary
        ? theme.palette.background.default
        : theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: theme.zIndex.appBar - 1,
      top: "56px",
      width: "calc(100vw - 200px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    tabs: {
      color: theme.palette.text.secondary,
      "& .MuiTabs-indicator": {
        // borderLeft: secondary ? "none" : `1px solid ${theme.palette.divider}`,
        // borderRight: secondary ? "none" : `1px solid ${theme.palette.divider}`,
        // backgroundColor: secondary ? "inherit" : theme.palette.background.default
        height: secondary ? "0px" : "2px"
      }
    }
  }));
  const classes = useStyles();

  // Get the tabs for the user's role
  const _role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );
  const userTabs = useMemo(
    () => tabs && tabs.filter((tab) => _role >= tab.role || !tab.role),
    [_role]
  );

  const history = useHistory();
  const location = useLocation();

  const getNumber = () => {
    const path = location.pathname.split("/")[2];
    if (!path) {
      return 0;
    }
    if (location.pathname === `/${base}/:tab`) {
      history.push(`/${base}/${userTabs[0].path}`);
      return 0;
    } else if (path) {
      return userTabs && userTabs.findIndex((p) => p.path === path);
    }
  };

  const [value, setValue] = useState(getNumber());

  const handleChange = (event, newValue) => {
    // console.log(newValue);
    history.push(`/${base}/${userTabs[newValue].path}`);
  };

  useEffect(() => {
    setValue(getNumber());
  }, [location, userTabs]);

  return (
    <Box className={classes.root}>
      <AppBar color="default" className={classes.appBar}>
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          {userTabs &&
            userTabs.map((tab, i) => {
              return <Tab key={i} label={tab.label} />;
            })}
        </Tabs>
      </AppBar>
      {userTabs ? (
        userTabs.map((tab, i) => {
          return (
            <TabPanel key={i} value={value} index={i}>
              {tab.content}
            </TabPanel>
          );
        })
      ) : (
        <StyledProgress />
      )}
    </Box>
  );
}

StyledTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  secondary: PropTypes.bool
};

export default StyledTabs;
