import React, { Fragment } from "react";
import { getFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import {
  RenderTextField,
  RenderFormSection,
  StyledWrapper,
  validate,
  history
} from "@austere-monorepo/components";
import { Field } from "redux-form";
import { Button, Grid, Typography } from "@material-ui/core";

const handleResetPassword =
  ({ email }) =>
  async (dispatch) => {
    const firebase = getFirebase();
    console.log(email);
    try {
      await firebase.auth().currentUser.getIdToken(true);
      await firebase.auth().sendPasswordResetEmail(email);
      await dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Password reset email sent to ${email}`,
          options: {
            variant: "success"
          }
        }
      });
      await history.goBack();
    } catch (error) {
      console.log(error);
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Error resetting password ` + error,
          options: {
            variant: "error"
          }
        }
      });
    }
  };

function PasswordResetForm(props) {
  const { handleResetPassword, handleSubmit, submitting, error } = props;
  return (
    <form onSubmit={handleSubmit(handleResetPassword)}>
      <StyledWrapper maxWidth={"sm"}>
        <RenderFormSection
          heading="Reset Password"
          actions={
            <Fragment>
              <Button
                variant="text"
                color="secondary"
                size="small"
                component={Link}
                to={"/account"}
              >
                My Account
              </Button>

              <Button variant="text" color="secondary" size="small" component={Link} to={"/login"}>
                Login Again
              </Button>

              <Button variant="text" color="secondary" size="small" component={Link} to={"/help"}>
                Help
              </Button>
            </Fragment>
          }
        >
          <Grid item xs={12}>
            <Typography>
              You will be sent a password reset link to the email address associated with your
              account
            </Typography>
          </Grid>
          <Field
            name="email"
            label="Email"
            type="email"
            placeholder="Email Address"
            component={RenderTextField}
          />

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label="register"
              type="submit"
              disabled={submitting || error}
            >
              Send Password Reset Link
            </Button>
          </Grid>
        </RenderFormSection>
      </StyledWrapper>
    </form>
  );
}

// const initialValues = {
//   password: '*************'
// };

export default compose(
  connect(null, { handleResetPassword }),
  reduxForm({
    form: "passwordReset",
    // handleResetPassword,
    validate
    // initialValues
  })
)(PasswordResetForm);
