import { Field } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getFirebase } from "react-redux-firebase";
import {
  StyledWrapper,
  RenderTextField,
  RenderPasswordField,
  validate,
  history
} from "@austere-monorepo/components";
import { Typography, Button, Card, Grid, Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const handleLogin = (creds) => async (dispatch) => {
  const firebase = getFirebase();
  try {
    await dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Logging in...",
        options: {
          variant: "info"
        }
      }
    });
    await firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      // .then(() => {
      //   history.push("/");
      // })
      .then(() => {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: "Login success",
            options: {
              variant: "success"
            }
          }
        });
      });

    // .then(() => {
    //   window.location.reload();
    // });

    await history.push("/");
    await history.go(0);
    // Check if the user has dismissed the install prompt. If this is a new browser, prompt the user to install
    // if (localStorage.getItem("installPrompt") === null) {
    //   localStorage.setItem("installPrompt", true);
    // }

    // history.go();
  } catch (error) {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: `Login failed ${error.message}`,
        options: {
          variant: "error"
        }
      }
    });
  }
};

function LoginForm(props) {
  const { handleSubmit, handleLogin, register, message } = props;
  // console.log(props);
  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <StyledWrapper maxWidth={"sm"}>
        <Card>
          <Typography gutterBottom variant="h2" align="center">
            Login
          </Typography>

          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Field
              name="email"
              label="Email"
              type="email"
              placeholder="Email Address"
              component={RenderTextField}
            />

            <Field
              name="password"
              label="Password"
              placeholder="Password"
              component={RenderPasswordField}
            />

            <Grid item xs={12}>
              <Button fullWidth variant="outlined" color="primary" type="submit">
                Login
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {message && (
              <Grid item xs={12}>
                <Alert severity="info">{message}</Alert>
              </Grid>
            )}

            {register && (
              <Typography style={{ padding: "5px 10px" }}>
                No account? <Link to="/register">Register here</Link>
              </Typography>
            )}

            <Typography style={{ padding: "5px 10px" }}>
              Forgot password? <Link to="/reset-password">Reset here</Link>
            </Typography>

            <Typography style={{ padding: "5px 10px" }}>
              Issues?{" "}
              <Typography component="a" target="_blank" href="https://austererisk.com/support">
                austererisk.com/support
              </Typography>
            </Typography>
          </Grid>
        </Card>
      </StyledWrapper>
    </form>
  );
}

export default compose(
  connect(null, {
    handleLogin
  }),
  reduxForm({
    form: "loginForm",
    forceUnregisterOnUnmount: true,
    // onSubmit: handleLogin,
    validate
  })
)(LoginForm);
