import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, Select, MenuItem } from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    // backgroundColor: theme.palette.background.default,
  }
}));

export function RenderSelectUserField({
  input,
  label,
  name,
  type,
  helper,
  md,
  multiline,
  items,
  required,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  // console.log(items);
  return (
    <FieldWrapper label={label} required={required} name={name} helper={helper} md={md}>
      {/* <Select
        variant="outlined"
        {...input}
        {...rest}
        name={name}
        inputProps={{
          name: `${name}`,
          id: `${name}`
        }}
      >
        {items &&
          items.map((item) => (
            <MenuItem key={item.val} value={item.val}>
              {item.label}
            </MenuItem>
          ))}
      </Select> */}
      <Select
        className={classes.field}
        required={required}
        variant="outlined"
        {...input}
        {...rest}
        name={name}
        inputProps={{
          name: `${name}`,
          id: `${name}`
        }}
      >
        {items &&
          items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.displayName}
            </MenuItem>
          ))}
      </Select>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
