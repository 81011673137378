import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

function CallEmergency(props) {
  const { number } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>{`Are you sure you want to call ${number}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`The ${number} service should only be used in emergencies. If you press 'Call ${number}', a call will
            be made from your phone to emergency services.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="outlined"
            component="a"
            href={`tel:${number}`}
            onClick={handleClose}
            startIcon={<PhoneIcon />}
            className={classes.button}
          >
            {`Call ${number}`}
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.root}>
        <Button
          className={classes.button}
          variant="outlined"
          fullWidth
          onClick={handleOpen}
          startIcon={<PhoneIcon />}
        >
          {`Call ${number}`}
        </Button>
      </Box>
    </Fragment>
  );
}

CallEmergency.propTypes = {
  number: PropTypes.string.isRequired
};

export default CallEmergency;
