import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "@austere-monorepo/components";
// Icons
import AddIcon from "@material-ui/icons/Add";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import EmailIcon from "@material-ui/icons/MailOutline";
import TocIcon from "@material-ui/icons/Toc";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";

// Styles
import {
  Button,
  Fab,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  // SvgIcon,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5)
  },
  icon: {
    fontSize: "26px"
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "20px"
    // }
  },
  fab: {
    position: "fixed",
    right: "30px",
    zIndex: 999
  },
  red: {
    color: theme.palette.error.main
  }
}));

export function EditButton({ collection, id, doc }) {
  const classes = useStyles();
  return (
    <Grid item>
      <Hidden mdUp implementation="css">
        <Link to={"/" + collection + "/edit/" + id}>
          <Tooltip title={"Edit " + doc}>
            <IconButton className={classes.iconButton}>
              <EditIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </Link>
      </Hidden>
      <Hidden smDown implementation="css">
        <Link to={"/" + collection + "/edit/" + id}>
          <Tooltip title={"Edit " + doc}>
            <Button
              className={classes.iconButton}
              startIcon={<EditIcon className={classes.icon} />}
            >
              Edit
            </Button>
          </Tooltip>
        </Link>
      </Hidden>
    </Grid>
  );
}

export function PdfButton(props) {
  // const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Export as PDF">
        <Button startIcon={<PdfIcon />} onClick={props.onClick}>
          PDF
        </Button>
      </Tooltip>
    </Grid>
  );
}

export function TocButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Contents">
        <IconButton className={classes.iconButton} onClick={props.onClick}>
          <TocIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function SearchButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Search">
        <IconButton className={classes.iconButton} onClick={props.onClick}>
          <SearchIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function MenuButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Hidden smUp implementation="css">
        <Tooltip title="Menu">
          <IconButton className={classes.iconButton} onClick={props.onClick}>
            <MenuIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Hidden>
    </Grid>
  );
}

export function HelpButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Help">
        <IconButton className={classes.iconButton} onClick={props.onClick}>
          <HelpIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function AddButton(props) {
  const classes = useStyles();
  return (
    <div className={classes.fab} style={{ bottom: "25px" }}>
      <Tooltip title={"Add " + props.doc}>
        <Fab color="secondary" component={Link} to={"/" + props.collection + "/add"}>
          <AddIcon style={{ color: "#ffffff" }} />
        </Fab>
      </Tooltip>
    </div>
  );
}

// export function LinkFabButton(props) {
//   const classes = useStyles();
//   return (
//     <div className={classes.fab} style={{ bottom: props.bottom }}>
//       <Tooltip title={"To " + props.to}>
//         <Fab className={classes.blue} component={Link} to={"/" + props.collection}>
//           {props.children}
//         </Fab>
//       </Tooltip>
//     </div>
//   );
// }

export function DeleteButton(props) {
  const { action, disabled } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    action();
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Grid item>
        <Hidden mdUp implementation="css">
          <Tooltip title="Delete">
            <span>
              <IconButton
                className={`${classes.iconButton} ${classes.red}`}
                onClick={handleClickOpen}
                disabled={disabled}
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>
        </Hidden>
        <Hidden smDown implementation="css">
          <Tooltip title="Delete">
            <span>
              <Button
                className={`${classes.iconButton} ${classes.red}`}
                startIcon={<DeleteIcon className={classes.icon} />}
                onClick={handleClickOpen}
                disabled={disabled}
              >
                Delete
              </Button>
            </span>
          </Tooltip>
        </Hidden>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button className={classes.red} onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export function SaveButton(props) {
  const classes = useStyles();
  const disabled = props.pristine || props.submitting || props.error;
  return (
    <Grid item>
      <Hidden mdUp implementation="css">
        <Tooltip title="Save">
          <span>
            <IconButton
              color="secondary"
              className={classes.iconButton}
              type="submit"
              disabled={disabled}
            >
              <SaveIcon className={classes.icon} />
            </IconButton>
          </span>
        </Tooltip>
      </Hidden>
      <Hidden smDown implementation="css">
        <Tooltip title="Save">
          <span>
            <Button
              color="secondary"
              className={classes.iconButton}
              type="submit"
              disabled={disabled}
              startIcon={<SaveIcon className={classes.icon} />}
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </Hidden>
    </Grid>
  );
}

export function DownloadButton(props) {
  const { disabled, onClick } = props;
  const classes = useStyles();
  return (
    <Grid item>
      <Hidden mdUp implementation="css">
        <Tooltip title="Download">
          <span>
            <IconButton
              disabled={disabled}
              color="secondary"
              className={classes.iconButton}
              onClick={onClick}
            >
              <GetAppIcon className={classes.icon} />
            </IconButton>
          </span>
        </Tooltip>
      </Hidden>
      <Hidden smDown implementation="css">
        <Tooltip title="Download">
          <span>
            <Button
              disabled={disabled}
              color="secondary"
              className={classes.iconButton}
              startIcon={<GetAppIcon className={classes.icon} />}
              onClick={onClick}
            >
              Download
            </Button>
          </span>
        </Tooltip>
      </Hidden>
    </Grid>
  );
}

export function GoBackButton(props) {
  const classes = useStyles();
  let back = () => {
    history.goBack();
  };
  return (
    <Grid item>
      <Hidden mdUp implementation="css">
        <Tooltip title="Cancel / Go back">
          <IconButton className={classes.iconButton} onClick={back}>
            <ChevronLeftIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Hidden>

      <Hidden smDown implementation="css">
        <Tooltip title="Cancel / Go back">
          <Button
            startIcon={<ChevronLeftIcon className={classes.icon} />}
            className={classes.iconButton}
            onClick={back}
          >
            Go Back
          </Button>
        </Tooltip>
      </Hidden>
    </Grid>
  );
}

export function SendButton(props) {
  const formName = props.form;
  const [open, setOpen] = useState(false);
  const disabled = props.pristine || props.submitting || props.error;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Grid item>
        <Hidden mdUp implementation="css">
          <Tooltip title="Send">
            <span>
              <IconButton
                className={classes.iconButton}
                color="secondary"
                disabled={disabled}
                onClick={handleClickOpen}
              >
                <EmailIcon className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>
        </Hidden>
        <Hidden smDown implementation="css">
          <Tooltip title="Send">
            <span>
              <Button
                disabled={disabled}
                startIcon={<EmailIcon className={classes.icon} />}
                className={classes.iconButton}
                onClick={handleClickOpen}
                color="secondary"
              >
                Send
              </Button>
            </span>
          </Tooltip>
        </Hidden>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>{"Confirm Send"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to send this?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={handleSend}
            color="primary"
            type="submit"
            form={formName}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export function ViewButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Hidden mdUp implementation="css">
        <Tooltip title={"View"}>
          <IconButton className={classes.iconButton} component={Link} to={props.to}>
            <SearchIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Hidden>
      <Hidden smDown implementation="css">
        <Tooltip title={"View"}>
          <Button
            startIcon={<SearchIcon className={classes.icon} />}
            className={classes.iconButton}
            component={Link}
            to={props.to}
          >
            View
          </Button>
        </Tooltip>
      </Hidden>
    </Grid>
  );
}

export function LinkButton(props) {
  // const classes = useStyles();
  return (
    <div style={{ margin: "5px", display: "inline-block" }}>
      <Tooltip title={props.text}>
        <Link to={props.to}>
          <Button
            inline
            style={{
              padding: "5px 10px 5px 5px"
            }}
          >
            <Typography variant="body2" style={{ paddingLeft: "5px" }}>
              {props.text}
            </Typography>
          </Button>
        </Link>
      </Tooltip>
    </div>
  );
}

// export default { PdfButton }

export function StyledIconButton(props) {
  const classes = useStyles();
  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        style={{ color: props.color }}
        className={classes.root}
        component={props.to && Link}
        to={props.to && props.to}
        children={props.children}
        onClick={props.onClick && props.onClick}
      />
    </Tooltip>
  );
}

// export function GoogleButton(props) {
//   return (
//     <Tooltip title={"Continue with Google"}>
//       <Button
//         fullWidth
//         variant="outlined"
//         startIcon={
//           <SvgIcon viewBox={"0 0 533.5 544.3"}>
//             <path
//               d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
//               fill="#4285f4"
//             />
//             <path
//               d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
//               fill="#34a853"
//             />
//             <path
//               d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
//               fill="#fbbc04"
//             />
//             <path
//               d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
//               fill="#ea4335"
//             />
//           </SvgIcon>
//         }
//         onClick={props.onClick && props.onClick}
//       >
//         Continue with Google
//       </Button>
//     </Tooltip>
//   );
// }
