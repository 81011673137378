import React, { PureComponent } from 'react'
import axios from 'axios'
import moment from 'moment/moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import Typography from '@material-ui/core/Typography'

class BriefingWeather extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      weather: [],
      loc: []
    }
  }

  componentDidMount() {
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${this.props.weatherLat}&lon=${this.props.weatherLon}&appid=${process.env.REACT_APP_WEATHER_KEY}&units=metric`
      )
      .then(res => {
        console.log(res)
        const loc = {
          id: res.data.city.id,
          name: res.data.city.name,
          country: res.data.city.country
        }
        const weather = res.data.list.map(forecast => ({
          condition: forecast.weather[0].description,
          date: new Date(forecast.dt * 1000),
          newDate: moment(forecast.dt * 1000).format('ddd, DD-MM HH:mm'),
          temperature: forecast.main.temp,
          pressure: forecast.main.pressure,
          humidity: forecast.main.humidity,
          windSpeed: forecast.wind.speed,
          windDeg: forecast.wind.deg
        }))
        this.setState({ weather, loc })
      })
  }

  render() {
    const cityURL = `https://openweathermap.org/city/${this.state.loc.id}`
    return (
      <div>
        <Typography variant="body1" gutterBottom>
          5-day weather for {this.state.loc.name}
          {', '}
          {this.state.loc.country}
        </Typography>
        <div style={{ width: '100%', height: '450px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              // width={}
              height={440}
              data={this.state.weather}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                //  dataKey={moment(this.state.weather.date).format('DD-MMM-YY HH:mm')}
                dataKey="newDate"
                angle={-45}
                textAnchor="end"
                tickCount={2}
                height={120}
                stroke="#000000de"
              // tickMargin={200}
              // tickFormatter={formatXAxis}
              />
              <YAxis width={40} stroke="#000000de" />
              <Tooltip />
              <Legend stroke="#000000de" />
              <Line
                type="linear"
                name="Temperature"
                dataKey="temperature"
                stroke="#009e15"
                unit="&deg;C"
              // label={{ fontSize: 12 }}
              />
              <Line
                type="monotone"
                name="Humidity"
                dataKey="humidity"
                stroke="#0015ff"
                unit="%"
              // label={{ fontSize: 12 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ textAlign: 'center', margin: '20px 0px' }}>
          <a target="_blank" rel="noopener noreferrer" href={cityURL}>
            {'Full weather report for ' + this.state.loc.name}
          </a>
        </div>
      </div>
    )
  }
}

export default BriefingWeather
