import React, { useState, useEffect } from 'react';
import { FieldWrapper } from '@austere-monorepo/components';
import {
  Avatar,
  Button,
  FormHelperText,
  Box,
  InputBase,
  FormLabel
} from '@material-ui/core';
import Resizer from 'react-image-file-resizer';
import { makeStyles } from '@material-ui/core/styles';
import FileUploader from 'react-firebase-file-uploader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default
  },
  avatar: {
    height: '150px',
    width: '150px'
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2)
  },
  button: {
    display: 'block',
    padding: theme.spacing(0),
    margin: theme.spacing(0.5, 0)
  }
}));

export const RenderAvatarField = ({
  input,
  label,
  name,
  helper,
  md,
  xs,
  meta: { initial },
  ...rest
}) => {
  const classes = useStyles();

  // const dispatch = useDispatch;

  // const [newFile, setNewFile] = useState(null);

  const [image, setImage] = useState(initial);

  // console.log(props);

  // const currentForm = props.meta.form;

  // const currentImage = useSelector((state) => state.firestore.data.owner && state.firestore.data.owner[input.name]);
  // const currentImage = currentForm && useSelector((state) => state.form && state.form[currentForm].avatar);

  // const currentImage = props.meta.initial;

  // useEffect(() => {
  //   setImage(currentImage);
  // }, [currentImage]);

  useEffect(() => {
    setImage(image);
  }, [image]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file, // File of the image which will resized.
        300, // MaxWidth of the resized new image.
        300, // MaxHeight of the resized new image.
        'WEBP', // CompressFormat of the resized new image.
        60, // Quality of the resized new image.
        0, // Degree of clockwise rotation to apply to uploaded image.
        (uri) => {
          resolve(uri);
          setImage(uri);
        }, // CallBack function of the resized new image URI.
        'file', // Output type of the resized new image.,
        300, // minWidth of the resized new image.
        300 // minHeight of the resized new image.
      );
    });

  const onFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      // setNewFile(file);
      setImage(file);
      // console.log(file);
      // console.log(newFile);
      const image = await resizeFile(file);

      console.log(image);
      input.onChange(image);
    } catch (err) {
      console.log(err);
    }
  };

  const removeImage = () => {
    // setNewFile(null);
    setImage(null);
    input.onChange(null);
  };

  return (
    <FieldWrapper label={label} name={name} helper={helper} md={md} xs={xs}>
      <Box className={classes.root}>
        <Avatar
          variant="square"
          className={classes.avatar}
          src={image}
          alt=""
        />
        <Box className={classes.infoBox}>
          <FormLabel htmlFor={name}>
            <InputBase
              style={{ display: 'none' }}
              id={name}
              type="file"
              accept="image/png, image/jpeg"
              onChange={onFileChange}
            />
            <Button
              className={classes.button}
              size="small"
              color="secondary"
              component="span"
            >
              Add/Change Profile Image
            </Button>
          </FormLabel>
          <Button
            className={classes.button}
            size="small"
            color="secondary"
            component="span"
            onClick={removeImage}
          >
            Remove Profile Image
          </Button>
          <FormHelperText>{helper}</FormHelperText>
        </Box>
      </Box>
    </FieldWrapper>
  );
};
