import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, List, ListSubheader, Box, IconButton, Hidden, Badge } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector } from "react-redux";

// Toolbar Buttons
import QuickAdd from "./QuickAdd";
import CopyUrl from "./CopyUrl";
import RefreshButton from "./RefreshButton";
import UpdateButton from "./UpdateButton";
import InstallPrompt from "./InstallPrompt";
import DownloadMap from "./DownloadMap";

const useStyles = makeStyles({
  list: {},
  badge: {
    "& .MuiBadge-badge": {
      // margin: theme.spacing(0.5),
      fontSize: 10
    }
  }
});

function QuickActions({ quickAddItems, prompts, role, isAuth }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Check if any prompt items are true
  const promptItems = useSelector((state) => state.prompt && state.prompt);

  // console.log(promptItems);

  const promptCount =
    promptItems &&
    Object.keys(promptItems).filter(function (key) {
      return promptItems[key];
    }).length;

  // console.log(promptCount);

  const items = [
    <QuickAdd quickAddItems={quickAddItems} role={role} isAuth={isAuth} />,
    <CopyUrl />,
    <RefreshButton />,
    <UpdateButton />,
    prompts.downloadMap ? <DownloadMap /> : null,
    prompts.install ? <InstallPrompt /> : null
  ];

  return (
    <Fragment>
      <Hidden mdUp implementation="css">
        <Badge
          showZero={false}
          badgeContent={promptCount}
          color="error"
          className={classes.badge}
          overlap="circular"
        >
          <IconButton color="inherit" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Badge>
        <Dialog maxWidth="md" open={Boolean(anchorEl)} onClose={handleClose}>
          <List className={classes.list}>
            <ListSubheader>Quick Actions</ListSubheader>
            {items && items.reverse().map((item, i) => <Fragment key={i}>{item}</Fragment>)}
          </List>
        </Dialog>
      </Hidden>
      <Hidden smDown implementation="css">
        <Box display="flex">{items && items.map((item) => item)}</Box>
      </Hidden>
    </Fragment>
  );
}

export default QuickActions;
