import { makeStyles } from "@material-ui/core/styles";

// Styles
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function StyledPaper(props) {
  const classes = useStyles();
  return <Box className={classes.root}>{props.children}</Box>;
}

export default StyledPaper;
