import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput
} from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    // backgroundColor: theme.palette.background.default,
  }
}));

export function RenderPasswordField({
  input,
  label,
  name,
  helper,
  md,
  xs,
  actions,
  multiline,
  // onChange,
  required,
  meta: { touched, error },
  ...rest
}) {
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles();
  return (
    <FieldWrapper
      label={label}
      name={name}
      helper={helper}
      md={md}
      xs={xs}
      actions={actions}
    >
      <OutlinedInput
        className={classes.field}
        fullWidth
        required
        {...input}
        id={name}
        type={visibility ? "text" : "password"}
        {...rest}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setVisibility(!visibility)}
            >
              {visibility ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
      {visibility}
    </FieldWrapper>
  );
}
