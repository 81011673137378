import React, { Fragment } from "react";
import { useMap } from "react-leaflet";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import MapButton from "./MapButton";

function Zoom() {
  const map = useMap();

  function zoomIn() {
    map.setZoom(map.getZoom() + 1);
  }
  function zoomOut() {
    map.setZoom(map.getZoom() - 1);
  }

  return (
    <Fragment>
      <MapButton title={"Zoom in"} onClick={zoomIn}>
        <ZoomInIcon />
      </MapButton>
      <MapButton title={"Zoom out"} onClick={zoomOut}>
        <ZoomOutIcon />
      </MapButton>
    </Fragment>
  );
}

export default Zoom;
