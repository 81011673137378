import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, Select, MenuItem } from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.background.default
  }
}));

export function RenderSelectRoleField({
  input,
  label,
  name,
  type,
  helper,
  md,
  multiline,
  items,
  required,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  // console.log(items);
  // console.log(typeof items);
  const mappedItems = Object.keys(items).map((key) => [Number(key), items[key]]);
  return (
    <FieldWrapper label={label} required={required} name={name} helper={helper} md={md}>
      <Select
        className={classes.field}
        required={required}
        variant="outlined"
        {...input}
        {...rest}
        name={name}
        inputProps={{
          name: `${name}`,
          id: `${name}`
        }}
      >
        {mappedItems &&
          mappedItems

            // .sort((a, b) => {
            //     if (a.displayName < b.displayName) {
            //         return -1;
            //     }
            //     if (a.displayName > b.displayName) {
            //         return 1;
            //     }
            //     return 0;
            // })

            .map((item, i) => (
              <MenuItem key={i} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))}
      </Select>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
