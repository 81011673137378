import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactMarkdown from "markdown-to-jsx";
import { makeStyles } from "@material-ui/core/styles";
import { StyledProgress } from "@austere-monorepo/components";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Container,
  Divider,
  Box
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto"
    // maxWidth: '100vw'
  },
  table: {
    // margin: theme.spacing(0, 0, 2, 0),
    // minWidth: '100%',
    // maxWidth: '100%',
    // display: 'block',
    // maxWidth: '-moz-fit-content',
    // maxWidth: 'fit-content'
    // // margin: '0 auto',
    // overflowX: 'auto'
    // whiteSpace: 'nowrap'
  },
  tableHead: {
    fontWeight: 500,
    background: theme.palette.divider,
    padding: theme.spacing(1, 1.25)
  },
  tableCell: {
    padding: theme.spacing(1, 1.25),
    verticalAlign: "middle",
    wordWrap: "break-all"
  },
  list: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    "& li div": {
      marginTop: theme.spacing(0.65),
      marginBottom: theme.spacing(0)
    },
    "& ul li": {
      // listStyleType: "square"
    },
    "& li input": {
      listStyleType: "square"
    }
  },
  listItem: {
    fontSize: theme.typography.body1.fontSize
  },
  checkbox: {
    listStyleType: "none"
  },
  summary: {
    // background: theme.palette.divider,
    fontSize: theme.typography.body2.fontSize,
    margin: theme.spacing(1),
    "&:focus": {
      outline: "none"
    }
  },
  details: {
    background: theme.palette.divider,
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(0.5),
    "& > p": {
      padding: theme.spacing(0.5, 2),
      fontSize: theme.typography.body2.fontSize
    }
  },
  alertMessage: {
    padding: "6px 0",
    "& > p": {
      marginBottom: 0
    }
  },
  strong: {
    fontWeight: 500
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: "underline"
  },
  divider: {
    margin: theme.spacing(3, 0)
  },
  imageBox: {
    textAlign: "center"
  },
  image: {
    // border: `1px solid ${theme.palette.divider}`,
    maxWidth: "100%"
  },
  spacer: {
    marginBottom: theme.spacing(3)
  }
}));

function MarkdownLink(props) {
  const classes = useStyles();
  return props.href.startsWith("#") || props.href.startsWith("/") ? (
    <Link className={classes.link} to={props.href}>
      {props.children}
    </Link>
  ) : (
    <a className={classes.link} href={props.href} target="_blank">
      {props.children}
    </a>
  );
}

function Markdown(props) {
  const classes = useStyles();
  const options = {
    overrides: {
      h2: {
        component: ({ ...props }) => (
          <Box className={classes.spacer}>
            <Typography gutterBottom variant="h2" {...props} />
            <Divider />
          </Box>
        )
      },
      h3: {
        component: ({ ...props }) => <Typography gutterBottom variant="h3" {...props} />
      },
      h4: {
        component: ({ ...props }) => <Typography gutterBottom variant="h4" {...props} />
      },
      h5: {
        component: ({ ...props }) => <Typography gutterBottom variant="h5" {...props} />
      },
      h6: {
        component: ({ ...props }) => <Typography gutterBottom variant="h6" {...props} />
      },
      p: {
        component: Typography,
        props: { paragraph: true, variant: "body1" }
      },
      ol: {
        component: ({ ...props }) => (
          <Typography component="div" className={classes.list}>
            <Typography component="ol" {...props} />
          </Typography>
        )
      },
      ul: {
        component: ({ ...props }) => (
          <Typography component="div" className={classes.list}>
            <Typography component="ul" {...props} />
          </Typography>
        )
      },
      li: {
        component: ({ ...props }) => (
          <Typography gutterBottom className={classes.listItem} component="li" {...props} />
        )
      },
      // input: {
      //   component: ({ ...props }) => (
      //     <Typography gutterBottom className={classes.checkbox} component="li" {...props}>
      //       <CheckBoxOutlineBlankIcon {...props} />
      //       {props.children}
      //     </Typography>
      //   )
      // },
      a: {
        component: ({ ...props }) => <MarkdownLink {...props} />
      },
      blockquote: {
        component: ({ ...props }) => (
          <Box className={classes.spacer}>
            <Alert
              classes={{
                // root: classes.alertRoot, // class name, e.g. `root-x`
                message: classes.alertMessage // class name, e.g. `disabled-x`
              }}
              variant="outlined"
              severity="info"
              color="warning"
            >
              {props.children}
            </Alert>
          </Box>
        )
      },

      table: {
        component: ({ ...props }) => (
          <Box className={classes.spacer}>
            <Table className={classes.table}>{props.children}</Table>
          </Box>
        )
      },

      strong: { props: { className: classes.strong } },
      // blockquote: { props: { className: classes.blockquote } },
      // table: { component: Table, props: { className: classes.table } },
      tbody: { component: TableBody },
      tr: { component: TableRow },
      thead: { component: TableHead },
      td: { component: TableCell, props: { className: classes.tableCell } },
      th: { component: TableCell, props: { className: classes.tableHead } },
      summary: { props: { className: classes.summary } },
      details: { props: { className: classes.details } },
      hr: { component: Divider, props: { className: classes.divider } },
      img: {
        component: ({ ...props }) => (
          <Typography paragraph>
            <Container maxWidth="sm" className={classes.imageBox}>
              <img
                className={classes.image}
                width={props.width}
                src={
                  props.src.startsWith("http")
                    ? props.src
                    : `${process.env.PUBLIC_URL}/images/${props.src}`
                }
              />
              <Typography paragraph color="textSecondary" align="center" variant="caption">
                {props.alt}
              </Typography>
            </Container>
          </Typography>
        )
      }
    }
  };
  const [content, setContent] = useState(null);

  useEffect(() => {
    // setContent("<div></div>");
    if (props.doc) {
      fetch(props.doc)
        .then((res) => res.text())
        .then(setContent);
    } else if (props.html) {
      setContent(String(props.html));
    } else setContent("<div></div>");
  }, [props.doc, props.html]);

  return (
    <Fragment>
      {content ? (
        <Box className={classes.root}>
          <ReactMarkdown options={options}>{content}</ReactMarkdown>
        </Box>
      ) : (
        <StyledProgress />
      )}
    </Fragment>
  );
}

Markdown.propTypes = {
  doc: PropTypes.string,
  html: PropTypes.string
};

export default Markdown;
