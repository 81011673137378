// https://blog.formpl.us/how-to-create-a-custom-route-blocking-modal-in-react-9306139ee3c7

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Prompt, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

function FormBlocker(props) {
  const { isBlocked } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content;
      }
      if (shouldUnload) {
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", unload);

    return () => window.removeEventListener("beforeunload", unload);
  }, [isBlocked, shouldUnload]);

  return (
    <Fragment>
      <Prompt when message={handleBlockedRoute} />
      <Dialog style={{ padding: "20px" }} open={isModalOpen} onClose={closeModal}>
        <DialogTitle disableTypography style={{ textAlign: "center" }}>
          Abandon unsaved changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click stay to return to the form, or leave to abandon your unsaved changes
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Stay</Button>

          <Button
            onClick={handleConfirmNavigationClick}
            startIcon={<ErrorOutlineOutlinedIcon />}
            className={classes.red}
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

FormBlocker.propTypes = {
  isBlocked: PropTypes.bool.isRequired
};

export default FormBlocker;
