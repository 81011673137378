import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TocButton } from "@austere-monorepo/components";
import { Drawer, Hidden, Box, Typography, AppBar, Toolbar } from "@material-ui/core";
import { GoBackButton } from "@austere-monorepo/components";

function StyledSideView({ title, sidebar, content, actionItems }) {
  const drawerWidth = 200;
  const actionBarHeight = "45px";
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    appBar: {
      top: "auto",
      width: sidebar ? `calc(100% - (${drawerWidth}px *2))` : `calc(100% - ${drawerWidth}px)`,
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    toolbar: {
      justifyContent: "space-between",
      alignItems: "center"
    },
    actionItems: {
      display: "flex",
      gap: theme.spacing(2)
    },
    title: {
      fontSize: "1rem",
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    drawer: {
      backgroundColor: theme.palette.background.paper,
      "& .MuiDrawer-paperAnchorLeft": {
        left: "auto"
      },
      "& .MuiDrawer-paper": {
        top: "auto"
      },
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      },
      overflow: "auto",
      width: drawerWidth
    },
    drawerPaper: {
      width: drawerWidth,
      padding: theme.spacing(1),
      overflow: "auto",
      height: "100%",
      scrollbarWidth: "none" /* Firefox */,
      "&::-webkit-scrollbar": {
        display: "none" /* Hide scrollbar for Chrome, Safari and Opera */
      },
      [theme.breakpoints.up("sm")]: {
        paddingBottom: `calc(${actionBarHeight} + 12px)`
      }
    },
    content: {
      marginTop: `-${actionBarHeight}`,
      paddingTop: `calc(${actionBarHeight} * 2)`,
      // marginTop: "48px", // actionBar height
      marginLeft: 0,
      [theme.breakpoints.up("md")]: {
        marginLeft: sidebar ? drawerWidth : 0
        //  marginTop: "100px"
      }
    }
  }));

  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <Box className={classes.root}>
        <Fragment>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar} variant="dense">
              <GoBackButton />
              {title && (
                <Typography display="inline" noWrap className={classes.title}>
                  {title}
                </Typography>
              )}
              <Box className={classes.actionItems}>
                {actionItems}
                {sidebar && (
                  <Hidden mdUp implementation="css">
                    <TocButton onClick={handleDrawerToggle} />
                  </Hidden>
                )}
              </Box>
            </Toolbar>
          </AppBar>
        </Fragment>

        {sidebar && (
          <Fragment>
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                onClick={handleDrawerToggle}
              >
                {sidebar}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  root: classes.drawer,
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {sidebar}
              </Drawer>
            </Hidden>
          </Fragment>
        )}
        <Box className={classes.content}>{content}</Box>
      </Box>
    </div>
  );
}

export default StyledSideView;
