import React, { Fragment, useMemo } from "react";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Notifier, createMasterTheme } from "@austere-monorepo/components";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { compose } from "redux";
import { primaryColor, storeItems } from "config";
import { firestoreConnect } from "react-redux-firebase";

// Fonts
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";

export const history = createBrowserHistory();

function App(props) {
  const { routes, dashboard } = props;

  const storeTheme = useSelector((state) => state.theme.theme);

  const theme = useMemo(
    () =>
      createMasterTheme({
        primaryColor: primaryColor,
        type: storeTheme
      }),
    [storeTheme]
  );

  // Reduce the routes to keep only the elements needed
  const reducedRoutes = useMemo(
    () => routes && routes.map(({ name, icon, ...keepAttrs }) => keepAttrs),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        preventDuplicate={true}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Notifier />
        <Router history={history}>
          <Switch>
            {reducedRoutes &&
              reducedRoutes.map((route, i) => {
                const RouteType = route.type || Fragment;
                const Component = route.component;
                const Dashboard = dashboard;
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact
                    render={(props) => (
                      <RouteType role={route.role}>
                        <Dashboard>
                          <Component {...route.options} {...props} />
                        </Dashboard>
                      </RouteType>
                    )}
                  />
                );
              })}
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ firebase: { auth } }) => ({
  _user: auth
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const standardItems = [
      {
        collection: "users",
        doc: `${props._user.uid}`,
        storeAs: "owner"
      }
    ];
    const newItems = standardItems.concat(storeItems);
    return newItems && newItems;
  })
)(App);
