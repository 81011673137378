import React from "react";
import { Card, CardHeader, List, ListItemAvatar, ListItem, ListItemText } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import HazardIcon from "@material-ui/icons/ReportProblem";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PhonelinkRingIcon from "@material-ui/icons/PhonelinkRing";

function StayingSafe() {
  return (
    <Card>
      <CardHeader avatar={<HazardIcon />} title={"Staying Safe"} />
      <List dense subheader="Here are some tips to remember to stay safe on the trails">
        <ListItem disableGutters>
          <ListItemAvatar>
            <DirectionsBikeIcon color="primary" />
          </ListItemAvatar>
          <ListItemText
            primary="Stay in control and on the track"
            secondary="You are responsible for avoiding people and obstacles on
                      the trail as well as your speed. It's important to stay on the trail at all times so as not to
                      cause any environmental damage."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemAvatar>
            <InfoIcon color="primary" />
          </ListItemAvatar>
          <ListItemText
            primary="Wear the right gear"
            secondary="Helmets are compulsory while using the St Helens Mountain Bike Network
                      and a full face helmet is recommended for the descents. We highly recommend the use of other
                      protective gear such as gloves and knee/elbow pads."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemAvatar>
            <SupervisedUserCircleIcon color="primary" />
          </ListItemAvatar>
          <ListItemText
            primary="Be prepared and let someone know where you are"
            secondary="It is always a good idea to carry
                      water and a snack. If riding alone, you should always let someone know where you are headed and
                      what time you will be back."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemAvatar>
            <LocalHospitalIcon color="primary" />
          </ListItemAvatar>
          <ListItemText
            primary="If you need emergency assistance"
            secondary="Take note of the orange emergency marker posts as you ride– they are
                      marked with a code (letters + numbers) representing your exact location and will be essential in
                      an emergency. If you are injured or lost and require assistance, call 000 and quote the nearest
                      marker code."
          />
        </ListItem>

        <ListItem disableGutters>
          <ListItemAvatar>
            <PhonelinkRingIcon color="primary" />
          </ListItemAvatar>
          <ListItemText
            primary="Keep your phone switched on and charged"
            secondary="In the case of an emergency in the area (for example, a bushfire), your
                      mobile phone will be pinged by Emergency Services so check your messages as regularly as you can."
          />
        </ListItem>
      </List>
    </Card>
  );
}

export default StayingSafe;
