import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { getFirebase } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, change } from "redux-form";
import FileUploader from "react-firebase-file-uploader";
import { Field } from "redux-form";
import { Detector } from "react-detect-offline";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  RenderFormSection,
  RenderTextField,
  RenderHiddenField,
  StyledProgress
} from "@austere-monorepo/components";
import moment from "moment";

// Icons
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
    // margin: theme.spacing(2, 0, 0, 0)
  },
  viewButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main
    // margin: theme.spacing(2, 0, 0, 0)
  },
  saveButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main
    // margin: theme.spacing(2, 0, 0, 0)
  },
  status: {
    fontSize: "0.7rem"
  },
  current: {
    color: theme.palette.success.main
  },
  expired: {
    color: theme.palette.warning.main
  }
}));

function FormFilesArray(props) {
  const { fields, formName, maxFiles } = props;

  const classes = useStyles();

  const currentFiles = useSelector(
    (state) => state.form[formName].values && state.form[formName].values.files
  );

  const dispatch = useDispatch();

  const firebase = getFirebase();

  // Get the UID of the user uploading
  const uid = useSelector((state) => state.firebase && state.firebase.auth.uid);
  const storageRef = firebase.storage().ref(uid);

  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleUploadStart = () => {
    setUploading(true);
    setProgress(0);
  };

  const handleProgress = (progress) => setProgress(progress);

  const handleUploadError = (error) => {
    setUploading(false);
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: `Error: ${error}`,
        options: {
          variant: "error"
        }
      }
    });
  };

  async function handleFileDelete(currentFile) {
    if (currentFile.url) {
      setDeleting(true);
      var storageRef = firebase.storage();
      const fileRef = storageRef.refFromURL(currentFile.url);
      try {
        await fileRef.getDownloadURL();
        fileRef.delete().then(function () {
          setDeleting(false);
        });
      } catch (error) {
        setDeleting(false);
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: error,
            options: {
              variant: "error"
            }
          }
        });
      }
    }
  }

  return (
    <Fragment>
      {fields.map((file, index) => {
        const currentFile = {
          url: currentFiles[index].url,
          expires: currentFiles[index].expires
        };

        return (
          <RenderFormSection
            key={index}
            actions={
              <Fragment>
                {currentFile.url && (
                  <Button
                    size="small"
                    // className={classes.viewButton}
                    startIcon={<SearchIcon />}
                    component="a"
                    href={currentFile.url}
                    target="_blank"
                  >
                    View
                  </Button>
                )}
                {currentFile.url && (
                  <Button
                    className={classes.saveButton}
                    size="small"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!currentFile.url}
                  >
                    Save
                  </Button>
                )}
                <Button
                  size="small"
                  className={classes.deleteButton}
                  startIcon={<DeleteIcon />}
                  onClick={async () => {
                    await handleFileDelete(currentFile).then(() => fields.remove(index));
                  }}
                >
                  {currentFile.url ? "Delete" : "Cancel"}
                </Button>
              </Fragment>
            }
          >
            {!currentFile.url && (
              <Grid item xs={12}>
                <FileUploader
                  // hidden
                  accept="application/pdf"
                  max-size="500" // kb
                  // randomizeFilename
                  storageRef={storageRef}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={(filename) => {
                    dispatch(
                      change(
                        formName,
                        `files[${index}].description`,
                        filename.replace(/\.[^/.]+$/, "")
                      )
                    );
                    setProgress(100);
                    setUploading(false);
                    if (firebase) {
                      firebase
                        .storage()
                        .ref(uid)
                        .child(filename)
                        .getDownloadURL()
                        .then((url) => dispatch(change(formName, `files[${index}].url`, url)))

                        .catch(function (error) {
                          dispatch({
                            type: "ENQUEUE_SNACKBAR",
                            notification: {
                              key: new Date().getTime() + Math.random(),
                              message: `Error: ${error}`,
                              options: {
                                variant: "error"
                              }
                            }
                          });
                        });
                    }
                  }}
                  onProgress={handleProgress}
                  // metadata={{ cacheControl: "max-age=3600" }}
                />
              </Grid>
            )}

            <Field name={`${file}.url`} type="text" component={RenderHiddenField} />
            <Field
              required
              name={`${file}.description`}
              type="text"
              component={RenderTextField}
              label="Description"
              md={8}
            />
            <Field
              name={`${file}.expires`}
              type="date"
              actions={
                currentFile.expires && (
                  <Typography
                    variant="button"
                    className={
                      currentFile.expires === null || moment(currentFile.expires).isAfter(moment())
                        ? `${classes.status} ${classes.current}`
                        : `${classes.status} ${classes.expired}`
                    }
                  >
                    {currentFile.expires === null || moment(currentFile.expires).isAfter(moment())
                      ? `Current`
                      : `Expired`}
                  </Typography>
                )
              }
              component={RenderTextField}
              helper="Enter the expiry date of the document, if applicable"
              label="Expires"
              md={4}
            />
          </RenderFormSection>
        );
      })}
      <Grid item xs={12}>
        <Button
          // fullWidth
          variant="outlined"
          color="secondary"
          startIcon={fields.length >= maxFiles ? null : <AddIcon />}
          disabled={fields.length >= (maxFiles ? maxFiles : 6)}
          onClick={() => fields.push({ url: "", description: "", expires: null })}
        >
          {fields.length >= (maxFiles ? maxFiles : 10) ? "Max. files Reached" : "Add file"}
        </Button>
      </Grid>
      {deleting && <StyledProgress message={"Deleting file"} />}
      {uploading && <StyledProgress message={"Uploading file " + progress + "%"} />}
    </Fragment>
  );
}

function FormFiles(props) {
  const { subHeading, maxFiles } = props;
  const formName = props.form;
  return (
    <Fragment>
      <RenderFormSection
        heading={"Files"}
        id="files"
        subHeading={subHeading ? subHeading : "Select files to attach to this form"}
      >
        <Detector
          render={({ online }) => (
            <Fragment>
              {online ? (
                <FieldArray
                  name="files"
                  component={FormFilesArray}
                  formName={formName}
                  maxFiles={maxFiles}
                  {...props}
                />
              ) : (
                <Grid item xs={12}>
                  File upload is disabled offline. Connect to a network, or upload files when you're
                  back online.
                </Grid>
              )}
            </Fragment>
          )}
        />
      </RenderFormSection>
    </Fragment>
  );
}

FormFiles.propTypes = {
  subHeading: PropTypes.object || PropTypes.string,
  maxFiles: PropTypes.number
};

export default FormFiles;
