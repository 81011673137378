import React from "react";
import {
  StyledTabs
  //  ClientPortal
} from "@austere-monorepo/components";
import Updates from "../updates/UpdateListTable";
import UsersListTable from "./UsersListTable";
import EditSettings from "./EditSettings";

function Management() {
  return (
    <StyledTabs
      base={"management"}
      tabs={[
        { label: "Users", path: "users", content: <UsersListTable /> },
        { label: "Updates", path: "updates", content: <Updates /> },
        { label: "Settings", path: "settings", content: <EditSettings /> }
        // { label: "Client Portal", path: "client-portal", content: <ClientPortal /> }
      ]}
    />
  );
}

export default Management;
