export const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = 'Required';
    } else if (!/^[a-zA-Z]*$/.test(values.firstname)) {
      errors.firstname = 'Invalid characters';
    } else if (values.firstname.length > 12) {
      errors.firstname = 'Too long';
    }
    if (!values.lastname) {
      errors.lastname = 'Required';
    } else if (!/^[a-zA-Z]*$/.test(values.lastname)) {
      errors.lastname = 'Invalid characters';
    } else if (!values.lastname.length > 20) {
      errors.lastname = 'Too long';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  
    // for Special Characters (?=.*?[#?!@$%^&*-])
    // if (!values.password) {
    //   errors.password = 'Required';
    // } else if (
    //   !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(values.password)
    // ) {
    //   errors.password = `at least • 8 Characters • Upper & Lower cases • Number`;
    // }
  
    if (!values.password) {
      errors.password = 'Required';
    } else if (!/^.{6,}$/.test(values.password)) {
      errors.password = `at least • 6 Characters`;
    }
  
    if (values.cpassword !== values.password) {
      errors.cpassword = "Password don't match";
    }
    return errors;
  };