import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  StyledProgress,
  MiniMap,
  ImageBlock,
  NotesBlock,
  StyledViewSection,
  VersionBlock
} from "@austere-monorepo/components";
import moment from "moment";

function ViewHazard(props) {
  const { item } = props;

  const rows = item
    ? [
        {
          heading: "Details",
          items: [
            { heading: "Description", value: item.description, md: 6 },
            { heading: "ID", value: item.hazard_id, md: 6 },
            { heading: "Status", value: item.status, md: 6 },
            {
              heading: "Resolved at",
              value: item.completed_at
                ? moment(item.completed_at).format("DD-MMM-YYYY HH:mm")
                : "N/A",
              md: 6
            },
            { heading: "Rating", value: item.hazard_rating, md: 6 },
            { heading: "Assigned", value: item.assigned_name, md: 6 },
            { heading: "Area", value: item.hazard_activity, md: 6 },
            { heading: "Trail", value: item.hazard_trail, md: 6 },
            { heading: "Details", value: item.hazard_detail }
          ]
        },
        {
          heading: "Location",
          items: [
            {
              value: (
                <MiniMap
                  // id={item.id}
                  lat={item.latitude}
                  lng={item.longitude}
                  accuracy={item.accuracy}
                />
              )
            }
          ]
        },
        {
          heading: "Images",
          items: [{ value: <ImageBlock images={item.images} /> }]
        },
        {
          heading: "Notes",
          items: [{ value: <NotesBlock notes={item.notes} /> }]
        },
        {
          heading: "Version",
          items: [
            {
              value: (
                <VersionBlock
                  createdByName={item.created_by_name}
                  createdAt={item.created_at}
                  updatedByName={item.updated_by_name}
                  updatedAt={item.updated_at}
                />
              )
            }
          ]
        }
      ]
    : [item];

  return (
    <Fragment>
      {item && (
        <Fragment>
          <StyledViewSection
            title={`Action ${item.hazard_id}`}
            rows={rows}
            collection={"actions"}
            doc={"action"}
            id={item.id}
            editRole={1}
          />
        </Fragment>
      )}
      {!item && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firestore: { ordered } }, props) {
  return {
    item: ordered.hazards && ordered.hazards.filter((item) => item.id === props.match.params.id)[0]
  };
}

export default compose(connect(mapStateToProps))(ViewHazard);
