export function Trails(feature, layer) {
  layer.bindPopup(
    '<div style="text-align: center;">' +
      '<b style="text-transform: uppercase;  padding: 10px 0px;">' +
      feature.properties.name +
      "</b></div>" +
      '<div style="text-align: left;">' +
      "<div><b>Rating: " +
      '<span style="color:' +
      feature.properties.rating +
      ';text-transform: uppercase;">' +
      feature.properties.rating +
      "</b></span></div>" +
      "<div><b>Distance: <span>" +
      (feature.properties.distance / 1000).toFixed(2) +
      "km</b></span></div>" +
      "<div><b>Description: <span></b>" +
      feature.properties.notes +
      "</span></div>" +
      "</div>"
  );
}
