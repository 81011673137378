import { StyledTable } from "@austere-monorepo/components";
import { useSelector } from "react-redux";

function UpdateListTable() {
  const _updates = useSelector((state) => state.firestore.ordered.updates);

  const columns = [
    { title: "View", name: "view" },
    { title: "Sent", name: "created_at" },
    { title: "Author", name: "created_by_name" },
    { title: "Bay of Fires Status", name: "poimena_status" },
    { title: "Bay of Fires Message", name: "poimena_message" },
    { title: "St Helens Status", name: "stacked_loop_status" },
    { title: "St Helens Message", name: "stacked_loop_message" },
    { title: "Dreaming Pools Status", name: "dreaming_pools_status" },
    { title: "Dreaming Pools Message", name: "dreaming_pools_message" }
  ];

  // const tableColumns = [
  //   { columnName: "created_at", width: 150 },
  //   { columnName: "project_reference", width: 200 },
  //   { columnName: "authorName", width: 120 }
  // ];

  const dateTimeColumns = ["created_at"];

  const htmlColumns = ["poimena_message", "stacked_loop_message", "dreaming_pools_message"];

  return (
    <StyledTable
      addButton
      title={"Update Log"}
      rows={_updates}
      columns={columns && columns}
      collection={"updates"}
      doc={"update"}
      htmlColumns={htmlColumns}
      dateTimeColumns={dateTimeColumns}
      editDisabled
    />
  );
}

export default UpdateListTable;
