import React, { Fragment, useState, useCallback } from "react";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import { history } from "@austere-monorepo/components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography
} from "@material-ui/core";
import { RenderFormSection, StyledWrapper } from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

function DeleteOwnAccount() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const firebase = getFirebase();

  const user = firebase.auth().currentUser;

  const firestore = getFirestore();

  const deleteAccount = () => {
    console.log(user);
    console.log(user.uid);
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: `Deleting account...`,
        options: {
          variant: "warning"
        }
      }
    });
    firestore
      .collection("users")
      .doc(user.uid)
      .delete()
      .then(() => {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: `Account deleted successfully`,
            options: {
              variant: "success"
            }
          }
        });
      })
      .then(() => {
        firebase.auth().signOut();
      })
      .then(() => {
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = useCallback(() => {
    setOpen(false);
    deleteAccount();
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const warningText =
    "Account deletion is permanent and your account and data cannot be restored. Are you sure you want to delete your account?";

  return (
    <Fragment>
      <StyledWrapper maxWidth={"sm"}>
        <RenderFormSection
          heading="Delete Account"
          actions={
            <Fragment>
              <Button
                variant="text"
                color="secondary"
                size="small"
                component={Link}
                to={"/account"}
              >
                My Account
              </Button>

              <Button variant="text" color="secondary" size="small" component={Link} to={"/login"}>
                Login Again
              </Button>

              <Button variant="text" color="secondary" size="small" component={Link} to={"/help"}>
                Help
              </Button>
            </Fragment>
          }
        >
          <Grid item component={Typography}>
            {warningText}
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" fullWidth className={classes.red} onClick={handleClickOpen}>
              Delete Account
            </Button>
          </Grid>
        </RenderFormSection>
      </StyledWrapper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{warningText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button className={classes.red} onClick={handleClick}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DeleteOwnAccount;
