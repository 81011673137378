export const mapStyles = (theme) => ({
  root: {
    "& > #main-map, & > .markercluster-map": {
      width: "100%",
      position: "absolute",
      top: 56,
      bottom: 0,
      [theme.breakpoints.up("md")]: {
        width: "calc(100vw - 200px)"
      }
    },
    "& > #form-map": {
      height: "300px",
      position: "relative",
      border: `1px solid ${theme.palette.divider}`
    },
    // "& .markercluster-map": {
    //   height: "90vh"
    // },
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      top: "72px",
      right: "16px",
      zIndex: 400
    },
    "& .leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before":
      {
        position: "absolute",
        pointerEvents: "none",
        border: "6px solid transparent",
        background: "transparent",
        content: '""'
      },
    "& .leaflet-tooltip ": {
      position: "absolute",
      padding: "1px 4px",
      backgroundColor: theme.palette.background.paper,
      border: "none",
      color: theme.palette.text.primary,
      fontWeight: "500",
      whiteSpace: "nowrap",
      WebkitUserSelect: "none",
      userSelect: "none",
      pointerEvents: "none",
      fontSize: "10px",
      boxShadow: "none",
      borderRadius: "3px",
      maxWidth: "200px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "&.leaflet-tooltip-bottom": {
      marginTop: "6px"
    },
    "& .leaflet-tooltip-top": {
      marginTop: "-6px"
    },
    "& .leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before": {
      left: "50%",
      marginLeft: "-6px"
    },
    "& .leaflet-tooltip-top:before ": {
      bottom: "0",
      marginBottom: "-12px",
      borderTopColor: theme.palette.background.paper
    },
    "& .leaflet-tooltip-bottom:before": {
      top: "0",
      marginTop: "-12px",
      marginLeft: "-6px",
      borderBottomColor: theme.palette.background.paper
    },
    "& .leaflet-tooltip-left": {
      marginLeft: "-7px"
    },
    "& .leaflet-tooltip-right": {
      marginLeft: "7px"
    },
    "& .leaflet-tooltip-left:before, .leaflet-tooltip-right:before": {
      top: "50%",
      marginTop: "-6px"
    },
    "& .leaflet-tooltip-left:before": {
      right: "0",
      marginRight: "-12px",
      borderLeftColor: theme.palette.background.paper
    },
    "& .leaflet-tooltip-right:before": {
      left: "0",
      marginLeft: "-12px",
      borderRightColor: theme.palette.background.paper
    }
  }
});
