import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import {
  FormWrapper,
  RenderTextField,
  RenderFormSection,
  RenderHiddenField,
  SendButton,
  history
} from "@austere-monorepo/components";
import { Field } from "redux-form";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import cuid from "cuid";

const createUser = (user) => async (dispatch) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const uid = await cuid();
  const email = user.email;
  const displayName = user.displayName;
  try {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Creating new user",
        options: {
          variant: "info"
        }
      }
    });

    let createNewUser = await firebase.functions().httpsCallable("createUser");

    await createNewUser({ email, displayName, uid });
    // create a new profile in firestore
    let newUser = {
      displayName: displayName,
      role: 1,
      email: email,
      id: uid,
      created_by: uid,
      created_at: Date.now()
    };
    await firestore.set(`users/${uid}`, { ...newUser });
    // console.log("User Created", newUser);
    await dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "New user created",
        options: {
          variant: "success"
        }
      }
    });
    await history.goBack();
  } catch (error) {
    console.log(error);
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Registration failed: " + error,
        options: {
          variant: "error"
        }
      }
    });
  }
};

function AddUser(props) {
  const { createUser, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit(createUser)} id={props.form}>
      <FormWrapper
        {...props}
        title={"Add New User"}
        actionItems={
          <Fragment>
            <SendButton {...props} />
          </Fragment>
        }
      >
        <RenderFormSection
          heading="New User Details"
          subHeading="The user will be sent an email with their login details. Double check email before sending. Users have a default role of 1. This can be changed after the user is created."
        >
          <Field
            required
            name="displayName"
            label="First name and last name"
            type="text"
            placeholder="First name and last name."
            component={RenderTextField}
            md={6}
          />
          <Field
            required
            name="email"
            label="Email"
            type="email"
            placeholder="Email Address"
            component={RenderTextField}
            md={6}
          />
          <Field name="role" type="number" component={RenderHiddenField} />
        </RenderFormSection>
      </FormWrapper>
    </form>
  );
}

export default compose(
  connect(null, { createUser }),
  reduxForm({
    form: "addUserForm",
    enableReinitialize: true
  })
)(AddUser);
