import React from "react";

function ClientPortal() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        onload="this.width=screen.width;this.height=screen.height;"
        title="Portal"
        style={{ width: "100%", height: "100%" }}
        src="https://portal.austererisk.com"
        frameborder="0"
        allowfullscreen
      />
    </div>
  );
}

export default ClientPortal;
