import React, { Fragment } from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { RenderSelectCurrencyField } from "@austere-monorepo/components";
function FormProject({ md, ...props }) {
  const items = useSelector(
    (state) =>
      state.firestore.ordered.projects && state.firestore.ordered.projects
  );
  const currentItems = useSelector(
    (state) =>
      state.firestore.ordered.projects &&
      state.firestore.ordered.projects.filter(
        (project) =>
          (project.currency && project.status === "Current") ||
          (project.currency && project.status === "Responding") ||
          (project.currency && project.status === "Monitoring")
      )
  );
  const nonCurrentItems = useSelector(
    (state) =>
      state.firestore.ordered.projects &&
      state.firestore.ordered.projects.filter(
        (project) =>
          (project.currency && project.status !== "Current") ||
          (project.currency && project.status !== "Responding") ||
          (project.currency && project.status !== "Monitoring")
      )
  );
  // const formName = props.form;
  // console.log(props);
  return (
    <Fragment>
      <Field
        required
        name="currency"
        label="Currency"
        type="text"
        component={RenderSelectCurrencyField}
        // items={items}
        currentItems={currentItems}
        nonCurrentItems={nonCurrentItems}
        // onChange={(e, newVal) => {
        //   // console.log(e);
        //   // console.log(newVal);
        //   const project = items.find((project) => newVal === project.id);
        //   return dispatch(
        //     change(
        //       formName,
        //       "currency",
        //       project.currency
        //     )
        //   );
        // }}
        md={md ? md : 6}
      />
    </Fragment>
  );
}

export default FormProject;
