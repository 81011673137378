import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, OutlinedInput } from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";

const useStyles = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.background.paper
  }
}));

export function RenderTextField({
  input,
  label,
  name,
  type,
  step,
  helper,
  md,
  xs,
  actions,
  multiline,
  // onChange,
  required,
  rows,
  meta: { touched, error },
  ...rest
}) {
  const classes = useStyles();
  return (
    <FieldWrapper
      label={label}
      required={required}
      name={name}
      helper={helper}
      md={md}
      xs={xs}
      actions={actions}
    >
      <OutlinedInput
        className={classes.field}
        required={required}
        fullWidth
        {...input}
        minRows={rows}
        id={name}
        type={type}
        // step={step}
        {...rest}
        multiline={multiline}
        inputProps={{
          // maxLength: 13,
          step: step
        }}
      />
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
